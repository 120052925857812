import React from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';

import { Button, Icon } from '@jvs-group/jvs-mairistem-composants';
import { useHistory, useParams } from 'react-router';
import { Fiche } from '@jvs-group/jvs-mairistem-fiche';
import { ListeTable } from '@jvs-group/jvs-mairistem-liste';

import { buildUri, useAuthRequest } from '../../utils';
import { RequestContext } from '../Context';
import ReportsRow from './ReportsRow';
import { getReportsList } from '../../routes';
import { tableSort } from '../Functions/Functions';
import { Sidebar } from '../Sidebar';

const ReportList = () => {
  const params = useParams();
  const history = useHistory();
  const { prepareRequest } = React.useContext(RequestContext);
  const [reports, setReports] = React.useState();
  const [loading, setLoading] = React.useState(true);

  const request = useAuthRequest();

  /** Identifiant du client */
  const idKnote = params.identifiant;

  const formPage = React.useCallback(
    () => {
      // Création de l'URL vers le formulaire rempli par le client
      const nextPath = buildUri('/formulaire/client', {
        ...params,
      });
      history.push({
        pathname: nextPath,
      });
    },
    [idKnote],
  );

  /** Ensemble des états que peut prendre une cellule en-tête d'un tableau */
  const [state, dispatch] = React.useReducer(tableSort, {
    column: null,
    data: reports,
    direction: null,
  });

  // Décomposition de state
  const { column, data, direction } = state;

  /** Changement de la valeur data avec les nouvelles cartes NovaTime */
  React.useEffect(() => {
    dispatch({ type: 'CHANGE_CUSTOMER', data: reports });
  }, [reports]);

  React.useEffect(() => {
    // Appel api  récupération des données du tableau de signalement
    prepareRequest(() => request({
      method: 'POST',
      url: getReportsList(idKnote),
    }).then((response) => {
      // Traiter les données
      setLoading(false);
      setReports(response);
    })
      .catch((error) => {
        // eslint-disable-next-line
        toast.error("Erreur lors de la récupération des données du tableau de signalement " + error);
      }));
  }, [idKnote]);

  return (
    <Fiche
      // Clé unique de la fiche
      key="FicheCustomerConsumer"
      // Informations dans la barre en haut de la page
      header={`Liste des signalements pour le client ${idKnote}`}
      // Couleur des boutons qui seront potentiellement ajoutés
      color="blue"
      // Booléen pour le chargement des données
      loading={loading}
      // Barre sur le côté qui prendra CustomersListe
      sidebars={{
        left: (<Sidebar identifiant={idKnote} customer={idKnote} params={params} />),
      }}
    >
      {/* Contenu de la fiche */}
      <Fiche.Panel
        // Clé unique du Panel
        key="reports"
        // Identifiant unique du Panel
        id="reports"
        // Nom dans le tab haut-droit
        title="Signalements des collaborateurs"
        // Fermeture de l'onglet
        open="true"
      >
        {/* Création du Panel */}
        <div style={!_.isEmpty(data) ? { height: 300 } : { height: 300 }}>
          <ListeTable
            // Taille du tableau
            count={_.size(data)}
            // Informations du tableau
            items={data}
            color="blue"
          >
            {/* En-tête du tableau */}
            <ListeTable.Header>
              {/* Cellule "Nom du collab" */}
              <ListeTable.Column
                name="name"
                width={3}
                // Triage du tableau
                sorted={column === 'name' ? direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'name' })}
              >
                <Icon name="chevron right" />
                Nom du collaborateur
              </ListeTable.Column>
              {/* Cellule "Date du signalement" */}
              <ListeTable.Column
                name="date"
                // Triage du tableau
                sorted={column === 'date' ? direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'date' })}
                width={4}
              >
                <Icon name="home" />
                Date du signalement
              </ListeTable.Column>
              {/* Cellule "Action" */}
              <ListeTable.Column
                name="action"
                width={3}
                // Triage du tableau
                sorted={column === 'action' ? direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'action' })}
              >
                <Icon name="phone" />
                Action
              </ListeTable.Column>
            </ListeTable.Header>
            {/* Appel de CustomersRow pour chaque client */}
            {(item) => (
              <ReportsRow item={item} idKnote={idKnote} />
            )}
          </ListeTable>
        </div>
      </Fiche.Panel>

      <Fiche.Panel
        // Clé unique du Panel
        key="reportsAdd"
        // Identifiant unique du Panel
        id="reportsAdd"
        // Nom dans le tab haut-droit
        title="Signaler un problème"
        // Fermeture de l'onglet
        open="true"
      >
        <Button
          content="Continuer, je signale un problème"
          className={_.isUndefined(idKnote) ? 'disabled' : ''}
          onClick={formPage}
          target="_blank"
          primary
          fluid
        />
      </Fiche.Panel>
    </Fiche>

  );
};

export default ReportList;
