import _ from 'lodash';

/** Fonction qui va formater les données */
export const convertKeysToCamelCase = (obj) => {
  const newObj = {};

  // eslint-disable-next-line
  for (const key in obj) {
    let newKey = key.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
    newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);

    newObj[newKey] = _.isObject(obj[key]) && !_.isArray(obj[key])
      ? convertKeysToCamelCase(obj[key])
      : obj[key];
  }

  return newObj;
};
