/**
 * @brief Fichier pour formater une date sous le format dd/mm/yyyy hh:mm
 */
import React from 'react';

/**
 * @brief Reformate une date au format dd/mm/yyyy hh:mm
 * @param {string} dateString La date à convertir
 * @returns La date reformatée
 */
export function formatDate(dateString, hideHours = false) {
  /** Permet d'utiliser les fonctions de la classe Date */
  const date = new Date(dateString);

  /** Valeur du jour */
  const day = String(date.getDate()).padStart(2, '0');
  /** Valeur du mois */
  const month = String(date.getMonth() + 1).padStart(2, '0');
  /** Valeur de l'année */
  const year = date.getFullYear();

  /** Valeur de l'heure */
  const hours = String(date.getHours()).padStart(2, '0');
  /** Valeur des minutes */
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year}${hideHours ? '' : ` ${hours}:${minutes}`}`;
}

/**
 * @brief Retourne une élément avec la date formatée
 */
const FormattedDate = ({ dateString, hideHours = false }) => (
  <>
    {formatDate(dateString, hideHours)}
  </>
);

export default FormattedDate;
