/**
 * @brief Fichier regroupant toutes les fonctions utilisées par plusieurs fichiers
 */
import _ from 'lodash';

/**
 * Fonctions pour trier le tableau
 * @param {*} state les valeurs actuelles de la variable
 * @param {*} action paramètres de la fonction dispatch()
 * @returns Tableau trié en fonction de la colonne cliquée
 */
export function tableSort(state, action) {
  // Vérification du type de l'action
  switch (action.type) {
    // Triage du tableau
    case 'CHANGE_SORT':
      // Vérification si c'est la même colonne qui a été cliquée
      if (state.column === action.column) {
        return {
          // Décomposition
          ...state,
          // Inversement de l'ordre des valeurs du tableau
          data: state.data.slice().reverse(),
          // Changement de la direction
          direction: state.direction === 'ascending' ? 'descending' : 'ascending',
        };
      }

      // Autre colonne qui a été cliquée
      return {
        // Changement de la colonne qui a été cliquée
        column: action.column,
        // Modification de l'ordre du tableau
        data: _.sortBy(state.data, [action.column]),
        // Changement de direction
        direction: 'ascending',
      };
    // Nouveau client sélectionné
    case 'CHANGE_CUSTOMER':
      return {
        // Décomposition
        ...state,
        // Nouvelles données du client
        data: action.data,
      };
    // Erreur
    default:
      throw new Error();
  }
}

/**
 * Vérifie si les données sont correctement affichées
 * @param {*} data La donnée à vérifier
 * @returns Booléen si la valeur est correcte ou non
 */
export function handleErrorData(data) {
  // Cas où les données sont vides
  if ((_.isEmpty(data) && typeof data !== 'number') || _.isNil(data)) {
    return true;
  }

  // Condition pour des nombres
  if (typeof data === 'number' && !_.isNil(data)) {
    return false;
  }
  if (typeof data === 'number' && _.isNil(data)) {
    return true;
  }

  // /** Données décomposées dans un tableau */
  // const splitedData = data.split(' ');

  // // Parcours du tableau
  // for (let i = 0; i < splitedData.length; i += 1) {
  //   switch (splitedData[i]) {
  //     // Erreur de données
  //     case undefined:
  //     case -1:
  //     case '-1':
  //       return true;
  //     // Données correctes
  //     default:
  //       break;
  //   }
  // }

  // Aucune erreur dans les données
  return false;
}

/**
 * Transforme un numéro de téléphone au format XX.XX.XX.XX.XX
 * @param {string} phoneNumber Numéro de téléphone à formater
 * @returns Numéro de téléphone formaté
 */
export function phoneFormat(phoneNumber) {
  // Retire tout ce qui n'est pas un chiffre du numéro
  const digitsPhoneNumber = phoneNumber.replace(/\D/g, '');

  // Découpe le numéro en groupe de 2 chiffres
  const groups = digitsPhoneNumber.match(/(\d{2})/g);
  let res = '';
  if (!_.isNil(groups)) {
    res = groups.join('.');
  } else {
    res = '/-/';
  }

  // Ajoute les points

  return res;
}

export function sortById(objets) {
  return objets.sort((a, b) => {
    const identifiantA = a.identifiant;
    const identifiantB = b.identifiant;

    if (identifiantA < identifiantB) {
      return -1;
    } if (identifiantA > identifiantB) {
      return 1;
    }
    return 0;
  });
}
