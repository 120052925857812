/**
 * @brief Conteneur de la liste de tous les clients
 */
import React from 'react';
import _, { isEmpty } from 'lodash';

import { Segment, Grid } from '@jvs-group/jvs-mairistem-composants';

import {
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router';
import {
  CustomersPanel,
  HistoryList,
  SearchPanel,
} from './Panels';

import {
  getCustomers, getCustomersByFilters, setTrace,
} from '../routes';
import { convertKeysToCamelCase, buildUri, useAuthRequest } from '../utils';
import { LogContext, RequestContext } from './Context';

/** Valeur par défaut des états des champs */
const defaultQuery = {
  idKnote: '',
  denomination: '',
  postalCode: '',
};

const CustomersContainer = () => {
  /** Tableau de l'ebsemble des clients */
  const [customers, setCustomers] = React.useState([]);

  /** Booléen pour le chargement des données */
  const [loading, setLoading] = React.useState(false);
  /** Booléen pour le chargement de l'historique */
  const [loadingHistory, setLoadingHistory] = React.useState(false);

  /** State des paramètres de recherche. */
  const [search, setSearch] = React.useState(defaultQuery);

  /** Historique de consultations des clients */
  const [searchHistory, setSearchHistory] = React.useState([]);

  const { prepareRequest } = React.useContext(RequestContext);

  /** Récupération du nom de l'utilisateur */
  const { userPrincipalName } = React.useContext(LogContext);

  /** Identifiant */
  const params = useParams();

  /** Historique pour la récupérations des informations */
  const history = useHistory();

  const request = useAuthRequest();

  /** Route de la où on est */
  const { path } = useRouteMatch();

  const [sortedCustomers, setSortedCustomers] = React.useState([]);

  // Cette variable sert à changer le format des données à afficher
  const formattedCustomers = React.useMemo(() => _.map(customers, (customer) => ({
    identifiant: customer.id,
    ..._.omit(customer, 'id'),
  })), [customers]);

  React.useMemo(() => {
    // tri des clients
    setSortedCustomers(_.sortBy(formattedCustomers, ['identifiant']));
  }, [formattedCustomers]);

  /** Fonction lorsque la query est modifiée. */
  const handleQueryChange = React.useCallback((parameters) => {
    // Modification de l'état search avec les nouvelles données
    setSearch({ ...search, ...parameters, idKnote: parameters?.idKnote || '' });
  }, []);

  /** Fonction du bouton "Rechercher" lorsqu'il sera cliqué */
  const handleSearchClick = React.useCallback(() => {
    // Début du chargement
    setLoading(true);
    const externalId = search.idKnote.trim();
    const name = search.denomination.trim();
    const postalCode = search.postalCode.trim();
    // On récupère les données des clients
    prepareRequest(() => request({
      method: 'POST',
      url: getCustomersByFilters(),
      data: {
        externalId,
        denomination: name,
        postalCode,
      },
    }).then((response) => {
      setCustomers(_.values(convertKeysToCamelCase(response.customers)));
    }).finally(() => {
      // Fin du chargement
      setLoading(false);

      /* Création d'une trace pour la recherche */
      const datas = JSON.stringify(
        {
          data:
          {
            name: 'accueil.recherche',
            data: {
              recheche: search,
              username: userPrincipalName,
            },
            tagsTrace: ['recherche'],
          },

        },
      );
      request({
        method: 'POST',
        url: setTrace(),
        data: datas,
      }).catch((error) => {
        // eslint-disable-next-line
        console.error('erreur setTrace', error);
      });
    }));
  }, [search, userPrincipalName]);

  /** Fonction du bouton "Effacer la rechercher" lorsqu'il sera cliqué */
  const handleClearClick = React.useCallback(() => {
    // Remise à zéro de l'état search
    setSearch(defaultQuery);
    // Début du chargement
    setLoading(true);
    // Rappel d'api pour récupérer les clients
    prepareRequest(() => request({
      method: 'GET',
      url: getCustomers(),
    }).then((response) => {
      setCustomers(_.values(convertKeysToCamelCase(response.customers)));
    }).catch((error) => {
      // eslint-disable-next-line
      console.log('erreur getCustomers', error);
    }).finally(() => {
      // Fin du chargement
      setLoading(false);
    }));
  }, [defaultQuery]);

  /** Ajout d'un client consulté à l'historique */
  const changeSearchHistory = React.useCallback((customer) => {
    let found = false;

    // Vérification que le client à ajouter à l'historique n'y est pas déjà présent
    for (let i = 0; i < searchHistory.length; i += 1) {
      if (searchHistory[i].identifiant === customer.identifiant) {
        found = true;

        // Remise à la 1ère place de l'élément déjà dans l'historique
        setSearchHistory(searchHistory.splice(i, 1));
        setSearchHistory(searchHistory.splice(0, 0, customer));

        // TODO faire l'appel à l'API pour ajouter la liste à la base de données
      }
    }

    // Condition pour ajouter le client à l'historique si il n'y est pas présent
    if (!found) {
      // Ajout du client consulté à l'historique
      setSearchHistory(searchHistory.splice(0, 0, customer));
      // Suppression du surplus de client
      if (searchHistory.length > 5) {
        setSearchHistory(searchHistory.splice(5, 1));
      }
      // Transformation du state en JSON et stockage en local
      window.localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
    }
    // Transformation du state en JSON et stockage en local
    window.localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
  }, [searchHistory]);

  /** Redirige vers a fiche client à la sélection d'une ligne. */
  const handleSelect = React.useCallback((selectedItem) => {
    let id = selectedItem.idKnoteC;
    if (isEmpty(id)) {
      id = selectedItem.identifiant;
    }
    // Création de l'URL vers les informations du client
    const nextPath = buildUri(path, { ...params, ...selectedItem, identifiant: id });
    changeSearchHistory(selectedItem);

    // Vérification si le chemin est différent de celui actuel
    if (history.location.pathname !== nextPath) {
      // Envoie de l'URL avec des données dans state
      history.push({
        pathname: nextPath,
        state: { items: _.isNil(formattedCustomers) ? customers : formattedCustomers },
      });
      const datas = JSON.stringify(
        {
          data:
          {
            name: 'accueil.tableau',
            data: {
              client: {
                id: selectedItem.identifiant,
                denomination: selectedItem.name,
              },
              username: userPrincipalName,
            },
            tagsTrace: ['tableau'],
          },

        },
      );
      request({
        method: 'POST',
        url: setTrace(),
        data: datas,
      }).catch((error) => {
        // eslint-disable-next-line
        console.error('erreur setTrace', error);
      });
    }
  }, [path, history, params, formattedCustomers, customers]);

  /** Récupération des valeurs dans le stockage local (TODO: supprimer ?) */
  React.useEffect(() => {
    // Début du chargement
    setLoadingHistory(true);

    // Récupération du JSON dans le stockage local
    const localHistory = window.localStorage.getItem('searchHistory');
    // Affectation de la valeur si elle n'est pas null
    if (localHistory !== null) {
      setSearchHistory(JSON.parse(localHistory));
    }
    // Fin du chargement
    setLoadingHistory(false);
  }, []);

  // On récupère les clients
  React.useMemo(() => {
    // Vérification que le token n'est pas null
    // Début du chargement
    setLoading(true);
    // const current = new Date();

    prepareRequest(() => request({
      method: 'GET',
      url: getCustomers(),
    }).then((response) => {
      // Récupération de tous les clients
      setCustomers(_.values(convertKeysToCamelCase(response.customers)));
      const datas = JSON.stringify(
        {
          data:
          {

            name: 'accueil.visualisation',
            data: {
              visualisation: 'visualisation accueil',
              username: userPrincipalName,
            },
            tagsTrace: ['visualisation'],
          },
        },
      );
      request({
        method: 'POST',
        url: setTrace(),
        data: datas,
      }).catch((error) => {
        // eslint-disable-next-line
        console.error('erreur setTrace', error);
      });
    }).catch((error) => {
      // eslint-disable-next-line
      console.log('catch erreur getCustomers', error);
    }).finally(() => {
      // Fin du chargement
      setLoading(false);
    }));
  }, []);

  return (
    <Segment
      inverted
      attached
      style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column computer={8} tablet={16} mobile={16} stretched style={window.innerWidth < 1024 ? { paddingBottom: '14px', height: '225px' } : null}>
            <HistoryList
              searchHistory={searchHistory}
              loading={loadingHistory}
              handleSelect={handleSelect}
            />
          </Grid.Column>
          <Grid.Column computer={8} tablet={16} mobile={16}>
            <SearchPanel
              search={search}
              searching={loading}
              onSearchClick={handleSearchClick}
              onQueryChange={handleQueryChange}
              onClearClick={handleClearClick}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <CustomersPanel
        customers={sortedCustomers}
        loading={loading}
        handleSelect={handleSelect}
      />
    </Segment>
  );
};

export default CustomersContainer;
