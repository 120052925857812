/**
 * @brief Ligne d'un contact d'un client
 */
import React from 'react';

import {
  Icon,
  Modal,
  Button,
  Grid,
  Popup,
} from '@jvs-group/jvs-mairistem-composants';

import PropTypes from 'prop-types';

import { ListeTable } from '@jvs-group/jvs-mairistem-liste';

import { handleErrorData, phoneFormat } from '../../Functions/Functions';

const ConctactsRow = (props) => {
  // Décomposition des props
  const {
    item,
  } = props;

  // Décomposition de item
  const {
    // L'identifiant du contact
    identifiant,
    // Booléen si le contact est inactif
    //  contactInactifC,
    // Le nom du contact
    name,
    // La fonction du contact
    fonctionC,
    // Le service du contact
    serviceC,
    // Le téléphones professionnel du contact
    phoneWork,
    // Le portable professionnel du contact
    phoneMobile,
    // L'email professionnel du contact
    email1,
  } = item;

  /** Etat de la Modal */
  const [open, setOpen] = React.useState(false);

  /** Fonction du contact */
  const fonctionContact = !handleErrorData(fonctionC) ? (fonctionC.charAt(0).toUpperCase() + fonctionC.slice(1)).replace(/_/g, ' ') : 'Non renseignée';
  /** Service du contact */
  const serviceContact = !handleErrorData(serviceC) ? (serviceC.charAt(0).toUpperCase() + serviceC.slice(1)).replace(/_/g, ' ') : 'Non renseigné';

  return (
    // Ligne du tableau
    <ListeTable.Row key={identifiant}>
      {/* Valeur pour "Compte inactif" */}
      {/* <ListeTable.Cell
        name="contactInactifC"
        style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold', textOverflow: 'ellipsis' }}
        textAlign="center"
      >
        {contactInactifC ? <Icon name="ban" /> : null}
      </ListeTable.Cell> */}
      {/* Valeur pour "Nom Complet" */}
      <ListeTable.Cell
        name="name"
        style={handleErrorData(name) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {!handleErrorData(name) ? name : 'Non renseigné'}
        {/* Apparition de la popup si la taille de la fenêtre est <= à 825px */}
        {window.innerWidth <= 825 ? (
          <Modal
            trigger={<Icon name="address card" style={{ float: 'right' }} />}
            dimmer="blurring"
            colorHeader="blue"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
          >
            {/* Nom du contact avec son activité */}
            <Modal.Header>
              <Icon name="angle right" size="small" />
              {!handleErrorData(name) ? name : 'Non renseigné'}
              {/* {contactInactifC ? ' (Inactif)' : null} */}
            </Modal.Header>
            <Modal.Content>
              <Grid style={{ padding: '0 20px' }}>
                {/* Fonction du contact */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="certificate" />
                  <p style={{ paddingRight: '3px' }}>
                    Fonction :
                  </p>
                  <b>
                    {fonctionContact === '-1' ? 'Non renseigné' : fonctionContact}
                  </b>
                </Grid.Row>
                {/* Service du contact */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="archive" />
                  <p style={{ paddingRight: '3px' }}>
                    Service :
                  </p>
                  <b>
                    {serviceContact === '-1' ? 'Non renseigné' : serviceContact}

                  </b>
                </Grid.Row>
                {/* Téléphone professionnel du contact */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="phone" />
                  <p style={{ paddingRight: '3px' }}>
                    Fixe :
                  </p>
                  <b>
                    {!handleErrorData(phoneWork) ? (
                      // Lien pour appeler
                      <a href={`tel:${phoneFormat(phoneWork)}`}>
                        {phoneFormat(phoneWork)}
                      </a>
                    ) : 'Non renseigné'}
                  </b>
                </Grid.Row>
                {/* Portable professionnel du contact */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="phone" />
                  <p style={{ paddingRight: '3px' }}>
                    Portable :
                  </p>
                  <b>
                    {!handleErrorData(phoneMobile) ? (
                      // Lien pour appeler
                      <a href={`tel:${phoneFormat(phoneMobile)}`}>
                        {phoneFormat(phoneMobile)}
                      </a>
                    ) : 'Non renseigné'}
                  </b>
                </Grid.Row>
                {/* Email professionnel du contact */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="mail" />
                  <p style={{ paddingRight: '3px' }}>
                    Email :
                  </p>
                  <b>
                    {!handleErrorData(email1) ? (
                      // Lien pour envoyer le mail
                      <a
                        href={`mailto:${email1}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {email1}
                      </a>
                    ) : 'Non renseigné'}
                  </b>
                </Grid.Row>
              </Grid>
            </Modal.Content>
            {/* Bouton pour quitter la Modal */}
            <Modal.Actions>
              <Button
                positive
                content="Fermer"
                icon="close"
                size="tiny"
                onClick={() => setOpen(false)}
              />
            </Modal.Actions>
          </Modal>
        ) : null}
      </ListeTable.Cell>
      {/* Valeur pour "Fonction" */}
      <ListeTable.Cell
        name="fonctionC"
        style={handleErrorData(fonctionC) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {fonctionContact === '-1' ? 'Non renseigné' : fonctionContact}
      </ListeTable.Cell>
      {/* Valeur pour "Service" */}
      <ListeTable.Cell
        name="serviceC"
        style={handleErrorData(serviceC) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {serviceContact === '-1' ? 'Non renseigné' : serviceContact}
      </ListeTable.Cell>
      {/* Valeur pour "Téléphone professionnel" */}
      <ListeTable.Cell
        name="phoneWork"
        style={handleErrorData(phoneWork) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {!handleErrorData(phoneWork) ? (
          // Lien pour appeler
          <a href={`tel:${phoneFormat(phoneWork)}`}>
            {phoneFormat(phoneWork)}
          </a>
        ) : (
          'Non renseigné'
        )}
      </ListeTable.Cell>
      {/* Valeur pour "Portable professionnel" */}
      <ListeTable.Cell
        name="phoneMobile"
        style={handleErrorData(phoneMobile) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {!handleErrorData(phoneMobile) ? (
          // Lien pour appeler
          <a href={`tel:${phoneFormat(phoneMobile)}`}>
            {phoneFormat(phoneMobile)}
          </a>
        ) : (
          'Non renseigné'
        )}
      </ListeTable.Cell>
      {/* Valeur pour "Email professionnel" */}
      <ListeTable.Cell
        name="email1"
        style={handleErrorData(email1) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {!handleErrorData(email1) ? (
          <Popup
            content={(
              <a
                href={`mailto:${email1}`}
                target="_blank"
                rel="noreferrer"
              >
                {email1}
              </a>
            )}
            trigger={(
              // Lien pour envoyer un mail
              <a
                href={`mailto:${email1}`}
                target="_blank"
                rel="noreferrer"
              >
                {email1}
              </a>
            )}
            position="top center"
            hoverable
            style={{ fontWeight: 'bold' }}
          />
        ) : (
          'Non renseigné'
        )}
      </ListeTable.Cell>
    </ListeTable.Row>
  );
};

/** Props venant de ConctactsList */
ConctactsRow.propTypes = {
  // Informations d'un contact
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
};

/** Types par défault des props */
ConctactsRow.defaultProps = {
  item: {},
};

export default ConctactsRow;
