import React from 'react';
import _ from 'lodash';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from '@jvs-group/jvs-mairistem-composants';
import { buildUri, convertKeysToCamelCase, useAuthRequest } from '../utils';
import { LogContext, RequestContext } from './Context';
import { getCustomerByIdKnote, getCustomers, setTrace } from '../routes';
import { HistoryList } from './Panels';
import CustomersListe from './Liste/CustomersListe';

export const Sidebar = (props) => {
  /** Historique de consultations des clients */
  const [searchHistory, setSearchHistory] = React.useState([]);

  /** Tableau de l'ebsemble des clients */
  const [customers, setCustomers] = React.useState([]);

  /** Booléen pour le chargement de l'historique */
  const [loadingHistory, setLoadingHistory] = React.useState(false);

  /** Booléen pour le chargement des customers */
  const [loading, setLoading] = React.useState(true);

  /** Identifiant */
  const { identifiant, params } = props;

  const [customer, setCustomer] = React.useState();

  /** Historique pour la récupérations des informations */
  const history = useHistory();

  const request = useAuthRequest();

  /** Route de la où on est */
  const { path } = useRouteMatch();

  /** Récupération du prepareRequest */
  const { prepareRequest } = React.useContext(RequestContext);

  /** Récupération du userPrincipalName */
  const { userPrincipalName } = React.useContext(LogContext);

  React.useEffect(() => {
    // Appel api pour avoir les données Sugar
    prepareRequest(() => request({
      method: 'POST',
      url: getCustomerByIdKnote(),
      data: { externalId: identifiant },
    }).then((response) => {
      setCustomer(convertKeysToCamelCase(response.customer));
    }).catch((error) => {
      // eslint-disable-next-line
      console.log('catch erreur getCustomer', error);
    }));
  }, [identifiant]);

  // Cette variable sert à changer le format des données à afficher
  const formattedCustomers = React.useMemo(() => _.map(customers, (customerParam) => ({
    identifiant: customerParam.id,
    ..._.omit(customerParam, 'id'),
  })), [customers]);

  /** Ajout d'un client consulté à l'historique */
  const changeSearchHistory = React.useCallback((customerParam) => {
    let found = false;

    // Vérification que le client à ajouter à l'historique n'y est pas déjà présent
    for (let i = 0; i < searchHistory.length; i += 1) {
      if (searchHistory[i].identifiant === customerParam.identifiant) {
        found = true;

        // Remise à la 1ère place de l'élément déjà dans l'historique
        setSearchHistory(searchHistory.splice(i, 1));
        setSearchHistory(searchHistory.splice(0, 0, customerParam));
      }
    }

    // Condition pour ajouter le client à l'historique si il n'y est pas présent
    if (!found) {
      // Ajout du client consulté à l'historique
      setSearchHistory(searchHistory.splice(0, 0, customerParam));
      // Suppression du surplus de client
      if (searchHistory.length > 5) {
        setSearchHistory(searchHistory.splice(5, 1));
      }
    }

    // Transformation du state en JSON et stockage en local
    window.localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
  }, [searchHistory]);

  /** Redirige vers la fiche client à la sélection d'une ligne. */
  const handleSelect = React.useCallback((selectedItem) => {
    // Création de l'URL vers les informations du client
    const nextPath = buildUri(path, { ...params, ...selectedItem });
    // Vérification si le chemin est différent de celui actuel
    if (history.location.pathname !== nextPath) {
      // Ajoute l'élément cliqué à l'historique
      changeSearchHistory(selectedItem);

      const datas = JSON.stringify(
        {
          data:
          {

            name: 'ficheclient.historique',
            data: {
              client: {
                id: selectedItem.identifiant,
                denomination: selectedItem.name,
              },
              username: userPrincipalName,
            },
            tagsTrace: ['ficheclient.historique'],
          },

        },
      );

      request({
        method: 'POST',
        url: setTrace(),
        data: datas,
      }).catch((error) => {
        // eslint-disable-next-line
        console.error('erreur setTrace', error);
      });

      // Envoie de l'URL avec des données dans state
      history.push({
        pathname: `/clients/${selectedItem.identifiant}`,
        // state: { items: _.isNil(formattedCustomers) ? customers : formattedCustomers },
      });
    }
  }, [path, history, params, formattedCustomers, customers]);

  /** Récupération des valeurs dans le stockage local (TODO: supprimer ?) */
  React.useEffect(() => {
    // Début du chargement
    setLoadingHistory(true);

    // Récupération du JSON dans le stockage local
    const localHistory = window.localStorage.getItem('searchHistory');
    // Affectation de la valeur si elle n'est pas null
    if (localHistory !== null) {
      setSearchHistory(JSON.parse(localHistory));
    }
    // Fin du chargement
    setLoadingHistory(false);
  }, [customer]);

  /** Récupération des tous les clients */
  React.useEffect(() => {
    // Vérification que le token n'est pas null
    prepareRequest(() => request({
      method: 'GET',
      url: getCustomers(),
    }).then((response) => {
      // Récupération de tous les clients
      setCustomers(_.values(convertKeysToCamelCase(response.customers)));
      setLoading(false);
    }).catch((error) => {
      // eslint-disable-next-line
      console.log('catch erreur getCustomers', error);
    }));
  }, [customer]);

  /** Fonction pour rediriger vers l'accueil */
  const handleBack = React.useCallback(() => {
    // Création de l'URL vers la liste des clients
    const nextPath = buildUri(path);

    // Vérification si le chemin est différent de celui actuel
    if (history.location.pathname !== nextPath) {
      // Envoie de l'URL
      history.push('/');
    }
  }, [path, history]);

  return (
    <>
      {/* Bouton pour revenir à l'accueil */}
      <Button
        // Texte dans le bouton
        content="Retour à l'accueil"
        // Icon dans le bouton
        icon="angle left"
        // Style
        compact
        basic
        style={{ margin: '15px 15px 0 15px', width: 200 }}
        floated="left"
        // Fonction lors du clic
        onClick={handleBack}
      />
      {/* Historique personnel */}
      <HistoryList
        searchHistory={searchHistory}
        loading={loadingHistory}
        handleSelect={handleSelect}
        inSidebar
        identifiant={identifiant}
      />
      {/* Liste de tous les clients */}
      <CustomersListe
        loading={loading}
        items={_.isNil(formattedCustomers) ? customers : formattedCustomers}
        expands
        handleSelect={handleSelect}
      />
    </>
  );
};
