/**
 * @brief Ligne d'un ticket zendesk
 */
import React from 'react';

import PropTypes from 'prop-types';
import { ListeTable } from '@jvs-group/jvs-mairistem-liste';
import { Icon, Popup } from '@jvs-group/jvs-mairistem-composants';
import { handleErrorData } from '../../Functions/Functions';
import { FormattedDate } from '../../Functions';

const CasesRow = (props) => {
  const { item } = props;

  const {
    identifiant,
    name,
    dateEntered,
    dateModificationC,
    ticketDemandeurC,
    description,
  } = item;

  return (
  // Ligne du tableau
    <ListeTable.Row key={identifiant}>
      {/* Valeur pour "Nom" */}
      <ListeTable.Cell
        name="Name"
        style={handleErrorData(name) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {!handleErrorData(name)
          ? (
            <a href={`https://groupe-jvs.zendesk.com/agent/tickets/${identifiant}`} target="_blank" rel="noreferrer">
              {name}
            </a>
          )
          : 'Non renseigné'}
      </ListeTable.Cell>
      {/* Valeur pour "date d'ouverture" */}
      <ListeTable.Cell
        name="DateEntered"
        style={handleErrorData(dateEntered) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {!handleErrorData(dateEntered) ? <FormattedDate dateString={dateEntered} /> : 'Non renseigné'}
      </ListeTable.Cell>
      {/* Valeur pour "date de modification" */}
      <ListeTable.Cell
        name="DateModificationC"
        style={handleErrorData(dateModificationC) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {!handleErrorData(ticketDemandeurC) ? <FormattedDate dateString={dateModificationC} /> : 'Non renseigné'}
      </ListeTable.Cell>
      {/* Valeur pour "demandeur" */}
      <ListeTable.Cell
        name="TicketDemandeurC"
        style={handleErrorData(ticketDemandeurC) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {!handleErrorData(ticketDemandeurC) ? ticketDemandeurC : 'Non renseigné'}
      </ListeTable.Cell>
      {/* Valeur pour "description" */}
      <ListeTable.Cell
        name="Description"
        style={
                    handleErrorData(description) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold', textAlign: 'center' }
}
      >
        {!handleErrorData(description)
          ? <Popup content={description} trigger={<Icon name="info" color="blue" />} /> : 'Non renseigné'}
      </ListeTable.Cell>
    </ListeTable.Row>
  );
};

/** Props venant de CasesList */
CasesRow.propTypes = {
  // Informations du ticket zendesk
  // eslint-disable-next-line
    item: PropTypes.object,
};

/** Types par défault des props */
CasesRow.defaultProps = {
  item: {},
};

export default CasesRow;
