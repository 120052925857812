import React from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@jvs-group/jvs-mairistem-composants';

const TableSerializatorRow = (props) => {
  const { product } = props;

  // S'assurer d'au moins une ligne si aucun module
  const moduleCount = product.tModules.length || 1;

  return (
    <>
      {/* Avoir les lignes du tableau avec toutes les informations */}
      <TableRow key={product.cCode}>
        {/* rowSpan sert à s'étendre sur le nombre de
                lignes nécessaires pour l'entièreté du produit */}
        <TableCell rowSpan={moduleCount}>{product.cLibelle}</TableCell>
        {/* <TableCell rowSpan={moduleCount}>{product.cCode}</TableCell>
        <TableCell rowSpan={moduleCount}>{product.cGroupe}</TableCell>
        <TableCell rowSpan={moduleCount}>{product.cVersion || 'Non spécifiée'}</TableCell> */}
        {/* Gestion d'erreur au cas où moduleCount est vide */}
        {product.tModules.length > 0 ? (
          <>
            {/* <TableCell>{product.tModules[0].cCode}</TableCell> */}
            <TableCell>{product.tModules[0].cLibelle}</TableCell>
          </>
        ) : (
          <>
            <TableCell>-</TableCell>
            {/* <TableCell>N/A</TableCell> */}
          </>
        )}
      </TableRow>
      {/* Avoir les lignes du tableau restantes, sans les informations du produit */}
      {_.map(product.tModules.slice(1), (module) => (
        <TableRow key={module.cCode}>
          {/* Les colonnes qui s'affichent à droite */}
          {/* <TableCell>{module.cCode}</TableCell> */}
          <TableCell>{module.cLibelle}</TableCell>
        </TableRow>
      ))}
    </>
  );
};

TableSerializatorRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object,
};

TableSerializatorRow.defaultProps = {
  product: {},
};

export default TableSerializatorRow;
