/**
 * @brief Liste des devis d'un client
 */
import React from 'react';
import _ from 'lodash';

import {
  Icon,
} from '@jvs-group/jvs-mairistem-composants';

import PropTypes from 'prop-types';

import { ListeTable } from '@jvs-group/jvs-mairistem-liste';

import EstimatesRow from './EstimatesRow';

import { tableSort } from '../../Functions/Functions';

const EstimatesList = (props) => {
  // Décomposition des props
  const {
    estimates,
  } = props;

  /** Ensemble des états que peut prendre une cellule en-tête d'un tableau */
  const [state, dispatch] = React.useReducer(tableSort, {
    column: 'name',
    data: estimates,
    direction: 'ascending',
  });

  // Décomposition de state
  const { column, data, direction } = state;

  /** Changement de la valeur data avec les nouveaux contacts */
  React.useEffect(() => {
    dispatch({ type: 'CHANGE_CUSTOMER', data: estimates });
  }, [estimates]);

  return (
    // TODO: Modifier pour que ce ne soit pas en brut
    <div style={!_.isEmpty(data) ? { height: `${data.length * 58 + 48}px`, maxHeight: '800px' } : { height: '300px', maxHeight: '800px' }}>
      {/* Tableau des devis du client */}
      <ListeTable
        count={_.size(data)}
        // Données directement dans le tableau
        items={data}
        color="blue"
        selection="click"
      >
        {/* En-tête du tableau */}
        <ListeTable.Header>
          {/* Case "Modification" */}
          <ListeTable.Column
            name="dateModified"
            width={2}
            // Triage du tableau
            sorted={column === 'dateModified' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'dateModified' })}
            // Responsive
            only={[
              'l',
              'xl',
            ]}
          >
            <Icon name="calendar" />
            Modification
          </ListeTable.Column>
          {/* Case "Relance" */}
          <ListeTable.Column
            name="devisDatePrevRelanceC"
            width={3}
            // Triage du tableau
            sorted={column === 'devisDatePrevRelanceC' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'devisDatePrevRelanceC' })}
            // Responsive
            only={[
              'l',
              'xl',
            ]}
          >
            <Icon name="calendar" />
            Relance
          </ListeTable.Column>
          {/* Case "Nom complet" */}
          <ListeTable.Column
            name="name"
            width={3}
            // Triage du tableau
            sorted={column === 'name' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'name' })}
          >
            <Icon name="angle right" />
            Nom complet
          </ListeTable.Column>
          {/* Case "Total avec option" */}
          <ListeTable.Column
            name="devisTotalAvecOptionC"
            width={2}
            // Triage du tableau
            sorted={column === 'devisTotalAvecOptionC' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'devisTotalAvecOptionC' })}
            // Responsive
            only={[
              'm',
              'l',
              'xl',
            ]}
          >
            <Icon name="euro sign" />
            Total avec option
          </ListeTable.Column>
          {/* Case "Affaire" */}
          <ListeTable.Column
            name="devisEtatC"
            width={2}
            // Triage du tableau
            sorted={column === 'devisEtatC' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'devisEtatC' })}
            // Responsive
            only={[
              'm',
              'l',
              'xl',
            ]}
          >
            <Icon name="write" />
            Affaire
          </ListeTable.Column>
          {/* Case "Commercial.e" */}
          <ListeTable.Column
            name="devisCommercialC"
            width={2}
            // Triage du tableau
            sorted={column === 'devisCommercialC' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'devisCommercialC' })}
            // Responsive
            only={[
              'l',
              'xl',
            ]}
          >
            <Icon name="male" />
            Commercial.e
          </ListeTable.Column>
          {/* Case "Devis type" */}
          <ListeTable.Column
            name="devisTypeC"
            width={3}
            // Triage du tableau
            sorted={column === 'devisTypeC' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'devisTypeC' })}
            // Responsive
            only={[
              'm',
              'l',
              'xl',
            ]}
          >
            <Icon name="target" />
            Devis type
          </ListeTable.Column>
          {/* Case "PDF du devis" */}
          <ListeTable.Column
            name="devisPdfUrlC"
            width={1}
            // Responsive
            only={[
              'm',
              'l',
              'xl',
            ]}
          >
            <Icon name="file pdf" />
            Devis
          </ListeTable.Column>
        </ListeTable.Header>
        {/* Contenu du tableau */}
        {(item) => (
          <EstimatesRow item={item} />
        )}
      </ListeTable>
    </div>
  );
};

/** Props venant de FicheCustomerConsumer */
EstimatesList.propTypes = {
  // Ensemble des devis du client
  // eslint-disable-next-line
  devis: PropTypes.arrayOf(PropTypes.object),
};

/** Types par défault des props */
EstimatesList.defaultProps = {
  devis: [],
};

export default EstimatesList;
