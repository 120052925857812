/**
 * @brief Liste des cartes NovaTime d'un client
 */
import React from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { ListeTable } from '@jvs-group/jvs-mairistem-liste';
import { Icon } from '@jvs-group/jvs-mairistem-composants';
import SuivisRow from './SuivisRow';
import { tableSort } from '../../Functions/Functions';

const SuivisLists = (props) => {
  // Décomposition des props
  const {
    suivis,
  } = props;

  /** Ensemble des états que peut prendre une cellule en-tête d'un tableau */
  const [state, dispatch] = React.useReducer(tableSort, {
    column: null,
    data: suivis,
    direction: null,
  });

  // Décomposition de state
  const { column, data, direction } = state;

  /** Changement de la valeur data avec les suivi du client */
  React.useEffect(() => {
    dispatch({ type: 'CHANGE_CUSTOMER', data: suivis });
  }, [suivis]);

  return (
    // TODO: Modifier pour que ce ne soit pas en brut
    <div style={!_.isEmpty(data) ? { height: `${data.length * 58 + 48}px`, maxHeight: '800px' } : { height: '300px', maxHeight: '800px' }}>
      {/* Tableau des formulaires mic du client */}
      <ListeTable
        count={_.size(data)}
        // Données directement dans le tableau
        items={data}
        color="blue"
        selection="click"
      >
        {/* En-tête du tableau */}
        <ListeTable.Header>
          {/* Case "identifiant suivi" correspondant à l'idSuivi */}
          <ListeTable.Column
            name="idSuivi"
            width={2}
            // Triage du tableau
            sorted={column === 'idSuivi' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'idSuivi' })}
            // Responsive
            only={[
              'l',
              'xl',
            ]}
          >
            <Icon name="angle right" />
            Identfiant suivi
          </ListeTable.Column>
          {/* Case "Date du suivi" */}
          <ListeTable.Column
            name="date_SA"
            width={2}
            // Triage du tableau
            sorted={column === 'date_SA' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'date_SA' })}
            // Responsive
            only={[
              'l',
              'xl',
            ]}
          >
            <Icon name="angle right" />
            Date du suivi
          </ListeTable.Column>
          {/* Case "Formation" */}
          <ListeTable.Column
            name="formation"
            width={2}
            // Triage du tableau
            sorted={column === 'formation' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'formation' })}
            // Responsive
            only={[
              'l',
              'xl',
            ]}
          >
            <Icon name="angle right" />
            Formation
          </ListeTable.Column>
          {/* Case "GRC" */}
          <ListeTable.Column
            name="grc"
            width={2}
            // Triage du tableau
            sorted={column === 'grc' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'grc' })}
            // Responsive
            only={[
              'l',
              'xl',
            ]}
          >
            <Icon name="angle right" />
            GRC
          </ListeTable.Column>
          {/* Case "PanneauPocket" */}
          <ListeTable.Column
            name="panneauPocket"
            width={2}
            // Triage du tableau
            sorted={column === 'panneauPocket' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'panneauPocket' })}
            // Responsive
            only={[
              'l',
              'xl',
            ]}
          >
            <Icon name="angle right" />
            PanneauPocket
          </ListeTable.Column>
          {/* Case "Messagerie pro" */}
          <ListeTable.Column
            name="mail"
            width={2}
            // Triage du tableau
            sorted={column === 'mail' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'mail' })}
            // Responsive
            only={[
              'l',
              'xl',
            ]}
          >
            <Icon name="angle right" />
            Messagerie pro
          </ListeTable.Column>

        </ListeTable.Header>

        {/* Contenu du tableau */}
        {(item) => (
          <SuivisRow item={item} />
        )}
      </ListeTable>
    </div>
  );
};

/** Props venant de FicheCustomerConsumer */
SuivisLists.propTypes = {
  // Ensemble des formulaires du client
  // eslint-disable-next-line
  suivis: PropTypes.arrayOf(PropTypes.object),
};

/** Types par défault des props */
SuivisLists.defaultProps = {
  suivis: {},
};

export default SuivisLists;
