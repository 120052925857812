/**
 * @brief Page pour l'authentification à la fiche 360
 */
import React from 'react';
import _ from 'lodash';

import { useLocation, useHistory } from 'react-router';
import queryString from 'query-string';

import { Dimmer, Loader, Container } from '@jvs-group/jvs-mairistem-composants';

import { request } from '@jvs-group/jvs-mairistem-store';
import { LogContext } from './Context';
import { buildUri } from '../utils';

const Authentication = () => {
  /** Récupération de la fonction setToken dans le context  */
  const {
    setToken,
    setUserPrincipalName,
  } = React.useContext(LogContext);

  const [hasAccess, setHasAccess] = React.useState(true);

  const location = useLocation();

  const redirectUri = `${process.env.REACT_APP_APP_URL}${location.pathname}`;
  /** Historique pour la récupérations des informations */
  const history = useHistory();
  const nextPath = buildUri(location.pathname);

  // eslint-disable-next-line
  const getTokenSso = React.useCallback((code) => {
    request.get(`${process.env.REACT_APP_API_URL_AUTH}${code}`)
      .then((response) => {
        // Afficher une erreure si l'utilisateur n'a pas accès à la fiche360
        if (!_.isNil(response.error)) {
          return setHasAccess(false);
        }

        setToken(response.token);
        setUserPrincipalName(response.profile);

        return history.push({
          pathname: nextPath,
        });
      });
  }, [setToken, nextPath, redirectUri]);

  React.useEffect(() => {
    // Récupération du token
    const code = queryString.parse(location.search)?.code;
    // Pour avoir le redirect en SSO commenter le if jusquau else if
    if (process.env.REACT_APP_NODE_ENV === 'dev') {
      setToken('FAKE-TOKEN-DEV');
    } else if (_.isUndefined(code)) {
      // redirection vers SSO
      window.location = `${process.env.REACT_APP_SSO_URL}/oauth2.0/authorize?redirect_uri=${redirectUri}&client_id=${process.env.REACT_APP_SSO_CLIENT_ID}&response_type=code`;
    } else {
      getTokenSso(code);
    }
  }, [setToken, location, getTokenSso]);

  // un mettre loader ou une erreur d'accès
  return (
    <Container textAlign="center">
      { !hasAccess ? <h1>Vous n&apos;avez pas accès à cette application</h1>
        : (
          <Dimmer active>
            <Loader />
          </Dimmer>
        )}
    </Container>
  );
};

export default Authentication;
