/**
 * @brief Page qui appelera tous les composants
 */
import React from 'react';

import ReactDOM from 'react-dom/client';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'semantic-ui-less/semantic.less';

import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root'),
);

root.render(
  <App />,
);
