import React from 'react';

const context = React.createContext({
  token: '',
  setToken: () => {},
  tokenSugar: '',
  setTokenSugar: () => {},
  userPrincipalName: '',
  setUserPrincipalName: () => {},
  initializeLocalStorage: () => {},
  isAuthenticationValid: () => {},
  disconnect: () => {},
});

context.displayName = 'LogContext';

export default context;
