import React from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import {
  Table, TableBody, TableHeader, TableHeaderCell, TableRow,
} from '@jvs-group/jvs-mairistem-composants';
import TableSerializatorRow from './TableSerializatorRow';

const TableSerializatorList = (props) => {
  const { products } = props;

  return (
    <Table celled structured striped>
      <TableHeader>
        {/* Première ligne */}
        <TableRow>
          {/* rowSpan sert à s'étendre sur deux lignes */}
          <TableHeaderCell width={2}>Produit</TableHeaderCell>
          {/* <TableHeaderCell rowSpan="2">Code</TableHeaderCell>
                    <TableHeaderCell rowSpan="2">Groupe</TableHeaderCell>
                    <TableHeaderCell rowSpan="2">Version</TableHeaderCell> */}
          {/* colSpan sert à s'étendre sur deux colonnes */}
          <TableHeaderCell width={2}>Module</TableHeaderCell>
        </TableRow>
        {/* Les deux colonnes sur la deuxième ligne sous colspan module */}
        {/* <TableRow>
                <TableHeaderCell>Code</TableHeaderCell>
                <TableHeaderCell>Module</TableHeaderCell>
                </TableRow> */}
      </TableHeader>
      <TableBody>
        {/* Toutes les lignes du tableau */}
        {_.map(products, (e) => <TableSerializatorRow product={e} />)}
      </TableBody>
    </Table>
  );
};

TableSerializatorList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  products: PropTypes.arrayOf(PropTypes.object),
};

TableSerializatorList.defaultProps = {
  products: [],
};

export default TableSerializatorList;
