/**
 * @brief Panneau du résumé des informations d'un client
 */
/* eslint-disable */
import React from 'react';
import _ from 'lodash';
import { useParams } from 'react-router';
import { useHistory } from "react-router";

import {
  Icon,
  Table,
  List,
  Button,
  Popup,
  Grid,
} from '@jvs-group/jvs-mairistem-composants';

import PropTypes from 'prop-types';

import { handleErrorData, phoneFormat } from '../../Functions/Functions';
import { buildUri } from '../../../utils';

const PanelInformations = (props) => {
  // Décomposition du props
  const {
    customer,
    idKnote,
    customerName,
  } = props;

  const {
    // Type de collectivité
    typeDeCollectiviteC,
    // Chargé.e de clientèle
    jvsChargesClienteleAccountsName,
    // Horaires d'ouverture du bureau
    horairesOuvertureC,
    // Nombre d'habitants
    nombreHabitantsC,
    // Email du client
    email1,
    // Rue de l'adresse
    shippingAddressStreet,
    // Code postal
    shippingAddressPostalcode,
    // Région de l'adresse
    shippingAddressCity,
    // Ville de l'adresse
    shippingAddressState,
    // Pole commercial
    poleCommercialC,
    // Commercial.e principal.e
    assignedUserName,
    // Client de la structure
    clientGroupeC,
    // Téléphone bureau
    phoneOffice,
    // Numéro SIREN
    sirenC,
    // Conseiler VAD
    interlocuteurVadC,
    // Assistante commerciale
    assistanteCommercialeC,
    // Date d'ancienneté
    dateDAncienneteC,
    // Partenaire
    partenaireC,
  } = customer;

  /** Largeur des cellules dans la table en dessous. */
  const columnsWidths = [3, 3, 3, 3, 4];

  const params = useParams();
  const history = useHistory();

  /** Fonction pour convertir l'adresse en un URL Google Map */
  const addressFunction = (street, postalCode, city) => {
    /** ------- Partie GoogleMap -------- */

    // // L'URL vers Google Map
    // let url = 'https://www.google.com/maps?q=';

    // if (!_.isEmpty(street) && !_.isEmpty(postalCode) && !_.isEmpty(city)) {
    //   // Ajout de la rue du siège du client
    //   url += street.replace(/ /g, '+');
    //   url += '+';
    //   // Ajout du code postal du siège du client
    //   url += postalCode;
    //   url += '+';
    //   // Ajout de la ville du siège du client
    //   url += city.replace(/ /g, '+');
    // }

    /** ------- Partie OpenStreetMap -------- */

    // L'URL vers OpenStreetMap
    let url = 'https://www.openstreetmap.org/search?query=';

    if (!_.isEmpty(street) && !_.isEmpty(postalCode) && !_.isEmpty(city)) {
      // Ajout de la rue du siège du client
      url += street.replace(/ /g, '%20');
      url += '%20';
      // Ajout du code postal du siège du client
      url += postalCode;
      url += '%20';
      // Ajout de la ville du siège du client
      url += city.replace(/ /g, '%20');
    }

    return url;
  };
  /**
   * Transforme un tableau en chaîne de caractères avec les significations des codes
   * @param {Array.<String>} client Ensemble des structures s'occupant du client
   * @returns Chaîne de caractères avec valeur des libellé
   */
  const transformClient = (client) => {
    // Valeur à retourner
    let res = '';

    // Cas non donnée vide
    if (!_.isEmpty(client)) {
      // Parcours de tous les éléments du tableau
      for (let i = 0; i < client.length; i += 1) {
        // Différentes valeur de client[i]
        switch (client[i]) {
          case 'jvs_mairistem':
            res += 'JVS MAIRISTEM';
            break;
          case 'novadys':
            res += 'NOVADYS';
            break;
          case 'adisoft':
            res += 'ADISOFT';
            break;
          case 'better':
            res += 'BETTER';
            break;
          case 'beattractive':
            res += 'BE-ATTRACTIVE';
            break;
          case 'citopia':
            res += 'CITOPIA';
            break;
          case 'cwa':
            res += 'CWA';
            break;
          case 'sistec':
            res += 'SISTEC';
            break;
          case 'modularis':
            res += 'MODULARIS';
            break;
          case 'startabi':
            res += 'JVS START';
            break;
          default:
            res += client[i];
            break;
        }

        // Ajout de vigule pour la suite du contenu du tableau
        if (i + 1 < client.length) {
          res += ', ';
        }
      }
      // Cas où donnée vide
    } else {
      res = 'Non renseigné';
    }

    return res;
  };
  // Récupération de formPage
  const formPage = React.useCallback(
    () => {
      // Création de l'URL vers le formulaire rempli par le client
      const nextPath = buildUri('/signalement/client', {
        ...params,
      });
      history.push({
        pathname: nextPath,
      });
    },
    [idKnote],
  );

  return (
    // Tableau des informations du client
    <Table color="blue">
      {/* Contenu du tableau */}
      <Table.Body>
        <Table.Row>
          {/* Cellule "Type de collectivité" */}
          <Table.Cell
            icon="university"
            content=" Type de collectivité"
            width={columnsWidths[0]}
          />
          {/* Information sur le type de collectivité du client */}
          <Table.Cell
            style={handleErrorData(typeDeCollectiviteC) ? { backgroundColor: '#e3e3e3' } : { fontWeight: 'bold' }}
            content={!handleErrorData(typeDeCollectiviteC) ? typeDeCollectiviteC.charAt(0).toUpperCase() + typeDeCollectiviteC.slice(1) : 'Non renseigné'}
            width={columnsWidths[1]}
          />
          {/* Cellule "Chargé.e de clientèle" */}
          <Table.Cell
            icon="user"
            content=" Chargé.e de clientèle"
            width={columnsWidths[2]}
          />
          {/* Information sur le/la chargé.e de clientèle du client */}
          <Table.Cell
            style={handleErrorData(jvsChargesClienteleAccountsName) ? { backgroundColor: '#e3e3e3' } : { fontWeight: 'bold' }}
            content={!handleErrorData(jvsChargesClienteleAccountsName) ? jvsChargesClienteleAccountsName : 'Non renseigné.e'}
            width={columnsWidths[3]}
          />
          {/* Cellule "Horaires" */}
          <Table.Cell rowSpan={6} verticalAlign="top" width={columnsWidths[4]}>
            <List>
              {/* Nom de la cellule */}
              <List.Item>
                <Icon name="clock" />
                {' '}
                Horaires d&apos;ouverture :
              </List.Item>
              <br />
              {/* Informations de la cellule */}
              <List.Description style={{ fontWeight: 'bold' }}>
                {!handleErrorData(horairesOuvertureC) ? (
                  <pre>{horairesOuvertureC}</pre>
                ) : (
                  'Non renseignées'
                )}
              </List.Description>
            </List>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          {/* Cellule "Nombre d'habitants" */}
          <Table.Cell
            icon="users"
            content=" Nombre d'habitants"
            width={columnsWidths[0]}
          />
          {/* Information sur le nombre d'habitants */}
          <Table.Cell
            style={handleErrorData(nombreHabitantsC) ? { backgroundColor: '#e3e3e3' } : { fontWeight: 'bold' }}
            content={!handleErrorData(nombreHabitantsC) ? nombreHabitantsC : 'Non renseigné'}
            width={columnsWidths[1]}
          />
          {/* Cellule "Commercial.e principal.e" */}
          <Table.Cell
            icon="user"
            content=" Commercial.e principal.e"
            width={columnsWidths[2]}
          />
          {/* Information sur le/la commercial.e principal.e du client */}
          <Table.Cell
            content={!handleErrorData(assignedUserName) ? assignedUserName : 'Non renseigné.e'}
            style={handleErrorData(assignedUserName) ? { backgroundColor: '#e3e3e3' } : { fontWeight: 'bold' }}
            width={columnsWidths[3]}
          />
        </Table.Row>
        <Table.Row>
          {/* Cellule "Adresse du siège" */}
          <Table.Cell
            width={columnsWidths[0]}
            icon="building"
            content=" Adresse du siège"
            rowSpan={2}
          />
          {/* Information sur l'adresse du siège du client */}
          <Table.Cell
            style={handleErrorData(shippingAddressStreet) ? { backgroundColor: '#e3e3e3' } : { fontWeight: 'bold' }}
            width={columnsWidths[1]}
            rowSpan={2}
          >
            {!handleErrorData(shippingAddressStreet)
              && !handleErrorData(shippingAddressPostalcode)
              && !handleErrorData(shippingAddressCity) ? (
              <a
                href={addressFunction(
                  shippingAddressStreet,
                  shippingAddressPostalcode,
                  shippingAddressCity,
                )}
                target="_blank"
                rel="noreferrer"
              >
                {`${shippingAddressStreet}`}
              </a>
            ) : (
              shippingAddressStreet
            )}
          </Table.Cell>
          {/* Cellule "Assistante commerciale" */}
          <Table.Cell
            icon="user"
            content=" Assistante commerciale"
            width={columnsWidths[2]}
          />
          {/* Information sur l'assistante commerciale du client */}
          <Table.Cell
            content={!handleErrorData(assistanteCommercialeC) ? assistanteCommercialeC : 'Non renseigné.e'}
            style={handleErrorData(assistanteCommercialeC) ? { backgroundColor: '#e3e3e3' } : { fontWeight: 'bold' }}
            width={columnsWidths[3]}
          />
        </Table.Row>
        <Table.Row>
          {/* Cellule "Conseiller VAD" */}
          <Table.Cell
            icon="user"
            content=" Conseiller VAD"
            width={columnsWidths[2]}
          />
          {/* Information sur le conseiller VAD du client */}
          <Table.Cell
            content={!handleErrorData(interlocuteurVadC) ? interlocuteurVadC : 'Non renseigné.e'}
            style={handleErrorData(interlocuteurVadC) ? { backgroundColor: '#e3e3e3' } : { fontWeight: 'bold' }}
            width={columnsWidths[3]}
          />
        </Table.Row>
        <Table.Row>
          {/* Cellule "Département du siège" */}
          <Table.Cell
            icon="building"
            content=" Département du siège"
            width={columnsWidths[0]}
          />
          {/* Informations sur le département du siège du client */}
          <Table.Cell
            style={handleErrorData(shippingAddressPostalcode) && handleErrorData(shippingAddressCity) && handleErrorData(shippingAddressState) ? { backgroundColor: '#e3e3e3' } : { fontWeight: 'bold' }}
            width={columnsWidths[1]}
          >
            {!handleErrorData(shippingAddressPostalcode) ? `${shippingAddressPostalcode} ` : ''}
            {' '}
            {!handleErrorData(shippingAddressCity) ? `${shippingAddressCity}` : ''}
            {' '}
            {!handleErrorData(shippingAddressState) ? `${shippingAddressState} ` : ''}
          </Table.Cell>
          <Table.Cell
            content="Numéro SIREN"
            icon="ticket alternate"
            width={columnsWidths[2]}
          />
          <Table.Cell
            style={handleErrorData(sirenC) ? { backgroundColor: '#e3e3e3' } : { fontWeight: 'bold' }}
            content={!handleErrorData(sirenC) ? sirenC : 'Non renseigné'}
          />
        </Table.Row>
        <Table.Row>
          {/* Cellule "Téléphone" */}
          <Table.Cell
            icon="phone"
            content=" Téléphone"
            width={columnsWidths[0]}
          />
          {/* Information sur le téléphone du client */}
          <Table.Cell
            style={handleErrorData(phoneOffice) ? { backgroundColor: '#e3e3e3' } : { fontWeight: 'bold' }}
            width={columnsWidths[1]}
          >
            {!handleErrorData(phoneOffice) ? (
              // Lien pour appeler un client
              <a href={`tel:${phoneFormat(phoneOffice)}`}>
                {`${phoneFormat(phoneOffice)}`}
              </a>
            ) : (
              'Non renseigné'
            )}
          </Table.Cell>
          {/* Cellule "Structure cliente de" */}
          <Table.Cell
            icon="suitcase"
            content=" Structure cliente de"
            width={columnsWidths[2]}
          />
          {/* Information sur la structure client du client */}
          <Table.Cell
            style={_.isEmpty(clientGroupeC) ? { backgroundColor: '#e3e3e3' } : { fontWeight: 'bold' }}
            content={transformClient(clientGroupeC)}
            width={columnsWidths[3]}
          />
        </Table.Row>

        {/* Les nouvelles lignes à ajouter */}
        <Table.Row>
          {/* Cellule "Adresse(s) email" */}
          <Table.Cell
            icon="mail"
            content=" Adresse email"
            width={columnsWidths[2]}
          />
          {/* Information sur l'adresse mail du client */}
          <Table.Cell
            style={handleErrorData(email1) ? { backgroundColor: '#e3e3e3' } : { fontWeight: 'bold' }}
            width={columnsWidths[3]}
          >
            {!handleErrorData(email1) ? (
              // Lien pour envoyer un mail
              <a
                href={`mailto:${email1}`}
                target="_blank"
                rel="noreferrer"
              >
                {`${email1}`}
              </a>
            ) : (
              'Non renseigné'
            )}
          </Table.Cell>
          {/* Cellule "Pôle commercial" */}
          <Table.Cell
            icon="comment"
            content=" Pôle commercial"
            width={columnsWidths[2]}
          />
          {/* Information sur le pôle commercial du client */}
          <Table.Cell
            style={_.isEmpty(poleCommercialC) ? { backgroundColor: '#e3e3e3' } : { fontWeight: 'bold' }}
            // afficher tous les poles commerciaux
            content={!_.isEmpty(poleCommercialC) ? _.join(poleCommercialC, ', ') : 'Non renseigné'}
            width={columnsWidths[3]}
          />
          {/* Bouton pour signaler une anomalie */}
          {/* <Table.Cell
            content={(
              <Button
                content="Signaler une erreur dans les données affichées"
                className={_.isUndefined(idKnote) ? 'disabled' : ''}
                onClick={formPage}
                target="_blank"
                primary
                fluid
              />
            )}
            width={columnsWidths[2]}
          /> */}
        </Table.Row>
        <Table.Row>
          {/* Cellule "Date d'ancienneté" */}
          <Table.Cell
            icon="calendar"
            content=" Date d'ancienneté"
            width={columnsWidths[2]}
          />
          {/* Information sur la date d'ancienneté du client */}
          <Table.Cell
            style={handleErrorData(dateDAncienneteC) ? { backgroundColor: '#e3e3e3' } : { fontWeight: 'bold' }}
            content={!handleErrorData(dateDAncienneteC) ? dateDAncienneteC : 'Non renseigné'}
            width={columnsWidths[3]}
          />
          {/* Cellule "Partenaire" */}
          <Table.Cell
            icon="handshake"
            content=" Partenaire"
            width={columnsWidths[2]}
          />
          {/* Information sur le partenaire du client */}
          <Table.Cell
            style={handleErrorData(partenaireC) ? { backgroundColor: '#e3e3e3' } : { fontWeight: 'bold' }}
            content={!handleErrorData(partenaireC) ? partenaireC : 'Non renseigné'}
            width={columnsWidths[3]}
          />
          {/* Boutons pour sharepoint et signaler une anomalie */}
          <Table.Cell
            style={{ marginTop: '1em' }}
            content={(
              <Grid>
                <Grid.Row>
                  <Grid.Column computer={10} tablet={10} mobile={10}>
                    <Popup
                      content="Vous permets de déposer ou consulter les documents concernant ce client."
                      wide
                      position='top center'
                      trigger={
                        <Button
                          content="Consulter le dossier client"
                          className={_.isUndefined(customerName) ? 'disabled' : ''}
                          href={`https://jvsfr.sharepoint.com/sites/DOSSIERSCLIENTS/Shared%20Documents/General/${customerName}`}
                          target="_blank"
                          primary
                          fluid
                        />
                      }
                    />
                  </Grid.Column>
                  <Grid.Column computer={6} tablet={6} mobile={6}>
                    <Popup
                      content="Vous permets de signaler une erreur dans l’affichage des données du client dans la Fiche 360."
                      wide
                      position='top center'
                      trigger={
                        <Button
                          content="Signaler une erreur"
                          style={{ fontSize: '0.75rem' }}
                          className={_.isUndefined(idKnote) ? 'disabled' : ''}
                          onClick={formPage}
                          target="_blank"
                          color='red'
                          fluid
                        />
                      }
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
            width={columnsWidths[2]}
          />
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

/** Props venant de FicheCustomerConsumer */
PanelInformations.propTypes = {
  // Ensemble des informations du client
  customer: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

/** Types par défault des props */
PanelInformations.defaultProps = {
  customer: [],
};

export default PanelInformations;
