/**
 * @brief En-tête et pied de toutes les pages
 */
import React from 'react';

import { HeaderLayout, FooterLayout } from './Layouts';

const Layout = ({ children }) => (
  <>
    {/* Header */}
    <HeaderLayout />

    {/* Contenu de la page */}
    {children}

    {/* Footer */}
    <FooterLayout />
  </>
);

export default Layout;
