/**
 * @brief Liste des tickets zendesk d'un client
 */
import React from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { ListeTable } from '@jvs-group/jvs-mairistem-liste';
import { Icon } from '@jvs-group/jvs-mairistem-composants';
import CasesRow from './CasesRow';
import { tableSort } from '../../Functions/Functions';

const CasesList = (props) => {
  const { cases } = props;

  /** Ensemble des états que peut prendre une cellule en-tête d'un tableau */
  const [state, dispatch] = React.useReducer(tableSort, {
    column: null,
    data: cases,
    direction: null,
  });

  // Décomposition de state
  const { column, data, direction } = state;

  /** Changement de la valeur data avec les nouveaux composants */
  React.useEffect(() => {
    dispatch({ type: 'CHANGE_CUSTOMER', data: cases });
  }, [cases]);

  return (
    <div style={!_.isEmpty(data) ? { height: `${data.length * 45 + 47}px`, maxHeight: '800px' } : { height: '300px', maxHeight: '800px' }}>
      {/* Tableau des composants du client */}
      <ListeTable
        count={_.size(data)}
        // Données directement dans le tableau
        items={data}
        color="blue"
        selection="click"
        style={{ overflowY: 'auto' }}
      >
        {/* En-tête du tableau */}
        <ListeTable.Header>
          {/* Cellule "Composant" */}
          <ListeTable.Column
            name="Name"
            width={4}
            // Triage du tableau
            sorted={column === 'Name' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'Name' })}
          >
            <Icon name="angle right" />
            Nom
          </ListeTable.Column>
          {/* Cellule "date d'ouverture" */}
          <ListeTable.Column
            name="DateEntered"
            width={2}
            // Triage du tableau
            sorted={column === 'DateEntered' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'DateEntered' })}
          >
            <Icon name="angle right" />
            Ouverture
          </ListeTable.Column>
          {/* Cellule "Dernière modification" */}
          <ListeTable.Column
            name="DateModificationC"
            width={2}
            // Triage du tableau
            sorted={column === 'DateModificationC' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'DateModificationC' })}
          >
            <Icon name="angle right" />
            Modification
          </ListeTable.Column>
          {/* Cellule "Demandeur" */}
          <ListeTable.Column
            name="TicketDemandeurC"
            width={2}
            // Triage du tableau
            sorted={column === 'TicketDemandeurC' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'TicketDemandeurC' })}
          >
            <Icon name="angle right" />
            Demandeur
          </ListeTable.Column>
          {/* Cellule "Description" */}
          <ListeTable.Column
            name="Description"
            width={2}
            // Triage du tableau
            sorted={column === 'Description' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'Description' })}
          >
            <Icon name="angle right" />
            Description
          </ListeTable.Column>
        </ListeTable.Header>

        {/* Contenu du tableau */}
        {(item) => (
          <CasesRow item={item} />
        )}

      </ListeTable>

    </div>
  );
};

/** Props venant de CasesTabs */
CasesList.propTypes = {
  // Ensemble des tickets zendesk du client en fonction de leur état
  // eslint-disable-next-line
  cases: PropTypes.arrayOf(PropTypes.object),
};

/** Types par défault des props */
CasesList.defaultProps = {
  cases: [],
};

export default CasesList;
