/**
 * @brief Panneaux pour lister les tickets zendesk d'un client
 */
import React from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { Tab, TabPane } from '@jvs-group/jvs-mairistem-composants';
import CasesList from './CasesList';
import CasesGraphic from './CasesGraphic';

const tabName = (status) => {
  switch (status) {
    case 'Hold':
      return 'En pause';
    case 'Pending':
      return 'En attente';// TODO ajouter en cours
    case 'Closed':
      return 'Fermé';
    case 'Solved':
      return 'Résolu';
    case 'Open':
      return 'Ouvert';
    case 'New':
      return 'Nouveau';
    default:
      return status;
  }
};

const CasesTabs = (props) => {
  const { cases } = props;

  const [statusList, setStatusList] = React.useState([]);

  // Les différents onglets
  const panes = React.useMemo(() => ([
    // L'onglet du graphique
    {
      menuItem: 'Résumé',
      render: () => <TabPane attached={false}><CasesGraphic cases={cases} /></TabPane>,
    },
    // Les onglets de chaque status
    ..._.map(statusList, (status) => ({
      menuItem: tabName(status),
      render: () => (
        <TabPane key={status} attached={false}>
          <CasesList cases={_.filter(cases, { status })} />
        </TabPane>
      ),
    })),
  ]), [cases, statusList]);

  React.useEffect(() => {
    // Avoir chaque type de status
    setStatusList(_.uniq(_.map(cases, ({ status }) => status)));
  }, [cases]);

  return (
    <div>
      <Tab menu={{ pointing: true }} panes={panes} />
    </div>
  );
};

/** Props venant de FicheCustomerConsumer */
CasesTabs.propTypes = {
  // Ensemble des tickets zendesk du client
  // eslint-disable-next-line
  cases: PropTypes.arrayOf(PropTypes.object),
};

/** Types par défault des props */
CasesTabs.defaultProps = {
  cases: [],
};

export default CasesTabs;
