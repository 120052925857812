/**
 * @brief Haut de page avec nom de l'application
 */
import React from 'react';

import { useHistory } from 'react-router';

import {
  Segment,
  Header,
  Popup,
  Button,
  Image,
} from '@jvs-group/jvs-mairistem-composants';

import logo from '../../images/360-logo.png';
import logoBrave from '../../images/logo-brave.png';
import logoChrome from '../../images/logo-chrome.svg';
import logoEdge from '../../images/logo-edge.png';
import logoSafari from '../../images/logo-safari.png';
import { LogContext } from '../Context';
// import { LogContext } from '../Context';

const HeaderLayout = () => {
  /** Historique pour la récupérations des informations */
  const history = useHistory();

  const { disconnect } = React.useContext(LogContext);

  // const { userPrincipalName } = React.useContext(LogContext);
  /** Fonction pour rediriger vers l'accueil */
  const homePage = React.useCallback(() => {
    // Envoie de l'URL
    history.push({ pathname: '/clients/' });
  }, []);

  return (
    <Segment
      style={{
        margin: 0,
        alignItems: 'center',
        display: 'flex',
        height: '50px',
      }}
    >
      {/* Fiche 360 avec redirection vers la page principale */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          onClick={homePage}
          style={{
            background: 'none',
            border: 'none',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Header as="h2" color="blue">
            <Image src={logo} size="medium" />
            Fiche 360
          </Header>
        </Button>
        {/* <Header as="h2" color="blue" style={{ marginLeft: '10px' }}>
          <Header.Subheader>
            <span style={{ color: '#000000' }}>Bienvenue {userPrincipalName}</span>
          </Header.Subheader>
        </Header> */}
      </div>
      <div style={{ flex: 1 }} />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ margin: 0 }}>Navigateurs compatibles :</p>
        <Popup content="Brave" trigger={<Image src={logoBrave} size="mini" style={{ marginLeft: '5px', marginRight: '10px' }} />} />
        <Popup content="Google Chrome" trigger={<Image src={logoChrome} size="mini" style={{ marginLeft: '5px', marginRight: '10px' }} />} />
        <Popup content="Microsoft Edge" trigger={<Image src={logoEdge} size="mini" style={{ marginLeft: '5px', marginRight: '10px' }} />} />
        <Popup content="Safari" trigger={<Image src={logoSafari} size="mini" style={{ marginLeft: '5px', marginRight: '10px' }} />} />

      </div>

      {/* Panneau de compte */}
      {/* TODO: Faire pour que la déconnexion s'opère + afficher profil connecté */}
      {/* TODO: A enlever pour la préprod (non fonctionnel) */}
      <Popup
        content={<Button content="Déconnexion" color="red" onClick={disconnect} />}
        trigger={<Button icon="cog" basic circular />}
        on="click"
        hideOnScroll
      />
    </Segment>
  );
};

export default HeaderLayout;
