/**
 * @brief Fichier API TRAAS
 */
const url = `${process.env.REACT_APP_API_URL}/`;

// export const setTrace = (datas) => {
//   request.post(`${url}/api/trace`, datas);
// };
/** création d'une trace */
export const setTrace = () => `${url}api/trace`;

export const getTraces = (filters) => `${url}api/trace/${filters}`;
