/**
 * @brief Liste des contacts d'un client
 */
import React from 'react';
import _ from 'lodash';

import {
  Icon,
} from '@jvs-group/jvs-mairistem-composants';

import PropTypes from 'prop-types';

import { ListeTable } from '@jvs-group/jvs-mairistem-liste';

import ConctactsRow from './ConctactsRow';

import { tableSort } from '../../Functions/Functions';

const ConctactsList = (props) => {
  // Décomposition des props
  const {
    contacts,
  } = props;

  /** Ensemble des états que peut prendre une cellule en-tête d'un tableau */
  const [state, dispatch] = React.useReducer(tableSort, {
    column: null,
    data: contacts,
    direction: null,
  });

  // Décomposition de state
  const { column, data, direction } = state;

  /** Changement de la valeur data avec les nouveaux contacts */
  React.useEffect(() => {
    dispatch({ type: 'CHANGE_CUSTOMER', data: contacts });
  }, [contacts]);

  return (
  // TODO: Modifier pour que ce ne soit pas en brut
    <div style={!_.isEmpty(data) ? { height: `${data.length * 45 + 47}px`, maxHeight: '800px' } : { height: '300px', maxHeight: '800px' }}>
      {/* Tableau des contacts du client */}
      <ListeTable
        count={_.size(data)}
        // Données directement dans le tableau
        items={data}
        color="blue"
        selection="click"
        style={{ overflowY: 'auto' }}
      >
        {/* En-tête du tableau */}
        <ListeTable.Header>
          {/* Case "Contact inactif" */}
          {/* <ListeTable.Column
            name="contactInactifC"
            width={1}
            // Triage du tableau
            sorted={column === 'contactInactifC' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'contactInactifC' })}
            // Responsive
            only={[
              'm',
              'l',
              'xl',
            ]}
          >
            <Icon name="ban" />
            Inactif
          </ListeTable.Column> */}
          {/* Case "Nom complet" */}
          <ListeTable.Column
            name="name"
            width={4}
            // Triage du tableau
            sorted={column === 'name' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'name' })}
          >
            <Icon name="angle right" />
            Nom complet
          </ListeTable.Column>
          {/* Case "Fonction" */}
          <ListeTable.Column
            name="fonctionC"
            width={2}
            // Triage du tableau
            sorted={column === 'fonctionC' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'fonctionC' })}
            // Responsive
            only={[
              'xl',
            ]}
          >
            <Icon name="certificate" />
            Fonction
          </ListeTable.Column>
          {/* Case "Service" */}
          <ListeTable.Column
            name="serviceC"
            width={2}
            // Triage du tableau
            sorted={column === 'serviceC' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'serviceC' })}
            // Responsive
            only={[
              'xl',
            ]}
          >
            <Icon name="archive" />
            Service
          </ListeTable.Column>
          {/* Case "Téléphone pro." */}
          <ListeTable.Column
            name="phoneWork"
            width={2}
            // Triage du tableau
            sorted={column === 'phoneWork' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'phoneWork' })}
            // Responsive
            only={[
              'm',
              'l',
              'xl',
            ]}
          >
            <Icon name="phone" />
            Téléphone pro.
          </ListeTable.Column>
          {/* Case "Portable pro." */}
          <ListeTable.Column
            name="phoneMobile"
            width={2}
            // Triage du tableau
            sorted={column === 'phoneMobile' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'phoneMobile' })}
            only={[
              'm',
              'l',
              'xl',
            ]}
          >
            <Icon name="phone" />
            Portable pro.
          </ListeTable.Column>
          {/* Case "Email professionnel" */}
          <ListeTable.Column
            name="email1"
            width={3}
            // Triage du tableau
            sorted={column === 'email1' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'email1' })}
            // Responsive
            only={[
              'm',
              'l',
              'xl',
            ]}
          >
            <Icon name="mail" />
            Email professionnel
          </ListeTable.Column>
        </ListeTable.Header>
        {/* Contenu du tableau */}
        {(item) => (
          <ConctactsRow item={item} />
        )}
      </ListeTable>
    </div>
  );
};

/** Props venant de FicheCustomerConsumer */
ConctactsList.propTypes = {
  // Ensemble des contacts du client
  // eslint-disable-next-line
  contacts: PropTypes.arrayOf(PropTypes.object),
};

/** Types par défault des props */
ConctactsList.defaultProps = {
  contacts: [],
};

export default ConctactsList;
