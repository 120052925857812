/**
* Fonction pour nettoyer et convertir une chaîne de caractères en liste de mots-clés.
* @param {string} rawKeywords - La chaîne brute contenant les mots-clés.
* @returns {Array} - Une liste de mots-clés nettoyés.
*/
export const cleanAndSplitKeywords = (rawKeywords) => {
  if (!rawKeywords) return [];
  return rawKeywords.trim().split(/\r\n|\r|\n/).filter((keyword) => keyword !== '');
};

export default cleanAndSplitKeywords;
