/**
 * @brief Ligne d'une carte NovaTime d'un client
 */
import React from 'react';
import _ from 'lodash';

import {
  Button,
  Modal,
  Icon,
  Grid,
  Popup,
} from '@jvs-group/jvs-mairistem-composants';

import PropTypes from 'prop-types';

import { ListeTable } from '@jvs-group/jvs-mairistem-liste';
import { FormattedDate } from '../../Functions';
import { handleErrorData } from '../../Functions/Functions';
import { formatDate } from '../../Functions/FormattedDate';

const NovaTimeRow = (props) => {
  // Décomposition des props
  const {
    item,
    cardType,
  } = props;

  // Décomposition de item
  const {
    identifiant,
    typeOfWorks,
    label,
    detail,
    externalId,
    status,
    user,
    expectedStartDate,
    expectedEndDate,
  } = item;

  const typeOfWork = typeOfWorks.label;
  let prenom = '';
  let nom = '';
  if (!_.isEmpty(user)) {
    // Avoir le prénom de l'utilisateur
    prenom = user.firstName;
    // Avoir le nom de l'utilisateur
    nom = user.lastName;
  }

  const formateur = !_.isEmpty(user) ? `${prenom} ${nom}` : 'Non renseigné.e';

  const suivi = !_.isEmpty(externalId) ? externalId : 'Non renseigné';
  /** Etat de la Modal */
  const [open, setOpen] = React.useState(false);

  /** Suppression des balises <p> et de "Observations:" */
  const finalObservation = !handleErrorData(detail) ? detail.replace(/<\/?p>|<\/?br>/gi, '') : 'Non renseigné';
  return (
    // Ligne du tableau
    <ListeTable.Row key={identifiant}>
      {/* Valeur pour "Type de travail" */}
      <ListeTable.Cell
        name="typeOfWork"
        style={{ whiteSpace: 'normal', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        {!handleErrorData(typeOfWork) ? typeOfWork : 'Non renseigné'}

        {/* Si la taille de l'écran est <= à 825 */}
        {window.innerWidth <= 825 ? (
          // Modal pour toutes les informations
          <Modal
            trigger={<Icon name="info" style={{ float: 'right' }} />}
            dimmer="blurring"
            colorHeader="blue"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
          >
            {/* Nom de la carte */}
            <Modal.Header>
              {!handleErrorData(typeOfWork) ? typeOfWork : 'Non renseigné'}
            </Modal.Header>
            <Modal.Content>
              <Grid style={{ padding: '0 20px' }}>
                {/* libellé de la carte */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="tag" />
                  <p style={{ paddingRight: '3px' }}>
                    libellé :
                  </p>
                  <b>
                    {!handleErrorData(label) ? label : 'Non renseigné'}
                  </b>
                </Grid.Row>
                {/* Observation de la carte */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="eye" />
                  <p style={{ paddingRight: '3px' }}>
                    Observation :
                  </p>
                  <b>
                    {!handleErrorData(detail) ? finalObservation : 'Non renseignée'}
                  </b>
                </Grid.Row>
                {/* Suivi de la carte */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="circle" />
                  <p style={{ paddingRight: '3px' }}>
                    Suivi :
                  </p>
                  <b>
                    {!handleErrorData(suivi) ? suivi : 'Non renseigné'}
                  </b>
                </Grid.Row>
                {/* Etat de la carte */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="sticky note" />
                  <p style={{ paddingRight: '3px' }}>
                    Etat :
                  </p>
                  <b>
                    {!handleErrorData(status.label) ? status.label : 'Non renseigné'}
                  </b>
                </Grid.Row>
                {/* Formateur.trice de la carte */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="male" />
                  <p style={{ paddingRight: '3px' }}>
                    Formateur.trice :
                  </p>
                  <b>
                    {!handleErrorData(formateur) ? formateur : 'Non renseigné.e'}
                  </b>
                </Grid.Row>
                {/* Horaires de la carte */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="clock" />
                  <p style={{ paddingRight: '3px' }}>
                    Horaires :
                  </p>
                  <b>
                    {!handleErrorData(expectedStartDate) ? <FormattedDate dateString={expectedStartDate} /> : '/'}
                    {!handleErrorData(expectedEndDate) ? <FormattedDate dateString={expectedEndDate} /> : '/'}
                  </b>
                </Grid.Row>
                {/* Lien vers la carte */}
                <Grid.Row>
                  <Icon name="file text" />
                  <p style={{ paddingRight: '5px' }}>
                    Carte :
                  </p>
                  <Button
                    color="blue"
                    icon="file alternate outline"
                    href={`https://novatime.jvsonline.fr/projects/29/list/${identifiant}`}
                    target="_blank"
                    basic
                  />
                </Grid.Row>
              </Grid>
            </Modal.Content>
            {/* Bouton pour fermer la Modal */}
            <Modal.Actions>
              <Button
                positive
                content="Fermer"
                icon="close"
                size="tiny"
                onClick={() => setOpen(false)}
              />
            </Modal.Actions>
          </Modal>
        ) : null}
      </ListeTable.Cell>
      {/* Valeur pour "libellé" */}
      <ListeTable.Cell
        name="label"
        style={{ whiteSpace: 'normal', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        {!handleErrorData(label) ? label : 'Non renseigné'}
      </ListeTable.Cell>
      {/* Valeur pour "Observation" */}
      <ListeTable.Cell
        name="preconization"
      >
        {!handleErrorData(detail) ? (
          <Popup
            content={<pre>{finalObservation || 'Aucune description'}</pre>}
            trigger={(
              <Icon
                name="comment"
                style={{ float: 'center' }}
              />
            )}
            position="center"
          />
        ) : 'Non renseigné'}
      </ListeTable.Cell>
      {/* Valeur pour "Suivi" */}
      <ListeTable.Cell
        name="suivi"
        style={{ whiteSpace: 'normal', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        {!handleErrorData(suivi) ? suivi : 'Non renseigné'}
      </ListeTable.Cell>
      {/* Valeur pour "Etat" */}
      <ListeTable.Cell
        name="status"
        style={{ whiteSpace: 'normal', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        {!handleErrorData(status.label) ? status.label : 'Non renseigné'}
      </ListeTable.Cell>
      {/* Valeur pour "Formateur.trice" */}
      <ListeTable.Cell
        name="formateur"
        style={{ whiteSpace: 'normal', fontWeight: 'bold' }}
      >
        {!handleErrorData(formateur) ? formateur : 'Non renseigné.e'}
      </ListeTable.Cell>
      {/* Valeur pour "Horaires" */}
      <ListeTable.Cell
        name="expectedDate"
        style={{ whiteSpace: 'normal', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        {!handleErrorData(expectedStartDate) ? formatDate(expectedStartDate) : '/'}
        {' - '}
        {!handleErrorData(expectedEndDate) ? formatDate(expectedEndDate) : '/'}
      </ListeTable.Cell>
      {/* Valeur pour "Carte" */}
      <ListeTable.Cell
        name="cardUrl"
        style={{ whiteSpace: 'normal', fontWeight: 'bold' }}
        textAlign="center"
        verticalAlign="middle"
      >
        <Button
          color="blue"
          icon="file alternate outline"
          href={cardType === 'novaTimeTechnicalSupport'
            ? `https://novatime.jvsonline.fr/projects/14/list/${identifiant}`
            : `https://novatime.jvsonline.fr/projects/29/list/${identifiant}`}
          target="_blank"
          basic
        />
      </ListeTable.Cell>
    </ListeTable.Row>
  );
};

/** Props venant de FicheCustomerConsumer */
NovaTimeRow.propTypes = {
  // Ensemble des contacts du client
  // eslint-disable-next-line
  item: PropTypes.object,
};

/** Types par défault des props */
NovaTimeRow.defaultProps = {
  item: {},
};

export default NovaTimeRow;
