/**
 * @brief Conteneur de la liste des clients
 */
import React from 'react';

import PropTypes from 'prop-types';

import { Segment } from '@jvs-group/jvs-mairistem-composants';

import CustomersListe from '../Liste/CustomersListe';

const CustomersPanel = (props) => {
  // Décomposition des props
  const {
    customers,
    loading,
    handleSelect,
  } = props;

  return (
    // Ajout du style
    <Segment padded style={window.innerWidth < 767 ? { paddingBottom: 50, minHeight: '300px' } : { flex: 1, paddingBottom: 50, minHeight: '250px' }}>
      <CustomersListe
        items={customers}
        loading={loading}
        handleSelect={handleSelect}
      />
    </Segment>
  );
};

/** Props venant de CustomersContainer */
CustomersPanel.propTypes = {
  // Tableau de clients avec toutes leurs informations
  // eslint-disable-next-line react/forbid-prop-types
  customers: PropTypes.arrayOf(PropTypes.object),
  // Booléen pour le chargement des données
  loading: PropTypes.bool,
};

/** Types par défault des props */
CustomersPanel.defaultProps = {
  customers: [],
  loading: false,
};

export default CustomersPanel;
