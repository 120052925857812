import { generatePath } from 'react-router';

import { buildUrl } from '@jvs-group/jvs-mairistem-tools';

// L'identifiant peut être soit l'id sugar soit l'idKnoteC
const identifiant = ':identifiant';

// La fonction buildUri prend deux arguments :
// une URI et un objet de paramètres optionnel.
const buildUri = (uri, params = {}) => {
  try {
    // Utilisation de la fonction generatePath pour générer un chemin
    // BuilUrl est une fonction qui prend deux arguments : une URI et un identifiant (JVS mairistem)
    return generatePath(
      buildUrl(uri, !uri.includes(identifiant) && identifiant),
      params,
    );
  } catch (e) {
    // Si une erreur est levée, on retourne une nouvelle URI en enlevant l'identifiant
    return generatePath(
      buildUrl(uri, !uri.includes(identifiant) && identifiant)
      // eslint-disable-next-line no-useless-escape
        .replace(new RegExp(`\/?${identifiant}`), ''),
      params,
    );
  }
};

export default buildUri;
