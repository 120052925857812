/* eslint-disable consistent-return */
import React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';

import { LogContext } from '../components/Context';

export const useAuthRequest = () => {
  const { token, isAuthenticationValid } = React.useContext(LogContext);

  return React.useCallback((options) => (
    axios({
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      ...options,
    }).then((response) => {
      if (isAuthenticationValid(response)) {
        if (
          response.status
          && (response.status === 200
            || response.status === 201
            || response.status === 204)
        ) {
          return response.data;
        }
      }
    })
      .catch((error) => { throw error; })
  ));
};

export default useAuthRequest;
