/**
 * @brief Ligne d'un rendez-vous d'un client
 */
import React from 'react';
import _ from 'lodash';

import {
  Label,
  Icon,
  Modal,
  Grid,
  Button,
  Popup,
} from '@jvs-group/jvs-mairistem-composants';

import PropTypes from 'prop-types';

import { ListeTable } from '@jvs-group/jvs-mairistem-liste';

import { FormattedDate } from '../../Functions';
import { handleErrorData } from '../../Functions/Functions';

const MeetingsRow = (props) => {
  // Décomposition des props
  const {
    item,
  } = props;

  // Décomposition de item
  const {
    // L'identifiant du rendez-vous
    identifiant,
    // Le nom du rendez-vous
    name,
    // Le statut du rendez-vous
    status,
    // Le type de visite du rendez-vous
    typeVisiteC,
    // La date de début du rendez-vous
    dateStart,
    // La date de fin du rendez-vous
    dateEnd,
    // Le nom de la personne assignée au rendez-vous
    assignedUserName,
    // Le métier de cette personne
    metierC,
  } = item;

  /**
   * Permet l'affichage du statut en fonction de sa valeur
   * @param {string} statusLabel Statut du rendez-vous
   * @returns Un label ou du texte en fonction de status
   */
  const labelFunction = (statusLabel) => {
    /** La valeur qui sera retournée */
    let label;

    // Conditions pour le cas du statut
    if (!_.isEmpty(statusLabel)) {
      // Différentes valeurs de statusLabel
      switch (statusLabel) {
        case 'Held':
          label = (
            <Label
              style={{ width: '100%' }}
              content="Tenu"
              color="olive"
            />
          );
          break;
        case 'Planned':
          label = (
            <Label
              style={{ width: '100%' }}
              content="Planifié"
              color="green"
            />
          );
          break;
        case 'Not Held':
          label = (
            <Label
              style={{ width: '100%' }}
              content="Annulé"
              color="red"
            />
          );
          break;
        case 'Provisoire':
          label = (
            <Label
              style={{ width: '100%' }}
              content="Provisoire"
              color="black"
            />
          );
          break;
        default:
          label = (
            <Label
              style={{ width: '100%' }}
              content="Non reconnu"
            />
          );
          break;
      }
    } else {
      label = 'Non renseigné';
    }

    return label;
  };

  /** Etat de la Modal */
  const [open, setOpen] = React.useState(false);

  /** Type de visite du rendez-vous */
  const visiteType = !handleErrorData(typeVisiteC) ? (typeVisiteC.charAt(0).toUpperCase() + typeVisiteC.slice(1)).replace(/_/g, ' ') : 'Non renseigné';
  /** Métier du responsable du rendez-vous */
  const job = !_.isEmpty(metierC) ? (metierC[0].charAt(0).toUpperCase() + metierC[0].slice(1)).replace(/_/g, ' ') : 'Non renseigné';

  return (
    // Ligne du tableau
    <ListeTable.Row key={identifiant}>
      {/* Valeur pour "Sujet" */}
      <ListeTable.Cell
        name="name"
        style={handleErrorData(name) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        {/* {!handleErrorData(name) ? name : 'Non renseigné'} */}
        <a href={`https://crm.jvsonline.fr/#Meetings/${identifiant}`} target="_blank" rel="noopener noreferrer">
          {!handleErrorData(name) ? name : 'Non renseigné'}
        </a>
        {/* Apparition de la popup si la taille de la fenêtre est <= à 825px */}
        {window.innerWidth <= 825 ? (
          <Modal
            trigger={<Icon name="cog" style={{ float: 'right' }} />}
            dimmer="blurring"
            colorHeader="blue"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
          >
            {/* Nom du rendez-vous */}
            <Modal.Header>
              <Icon name="file alternate" size="small" />
              {!handleErrorData(name) ? name : 'Non renseigné'}
            </Modal.Header>
            <Modal.Content>
              <Grid style={{ padding: '0 20px' }}>
                {/* Statut du rendez-vous */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="cogs" />
                  <p style={{ padding: '2px 5px 0 0' }}>
                    Statut :
                  </p>
                  <p>
                    {labelFunction(status)}
                  </p>
                </Grid.Row>
                {/* Type de visite du rendez-vous */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="target" />
                  <p style={{ paddingRight: '3px' }}>
                    Type de visite :
                  </p>
                  <b>
                    {visiteType}
                  </b>
                </Grid.Row>
                {/* Date de début du rendez-vous */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="calendar plus" />
                  <p style={{ paddingRight: '3px' }}>
                    Début :
                  </p>
                  <b>
                    {!handleErrorData(dateStart) ? <FormattedDate dateString={dateStart} /> : 'Non renseigné'}
                  </b>
                </Grid.Row>
                {/* Date de fin */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="calendar minus" />
                  <p style={{ paddingRight: '3px' }}>
                    Fin :
                  </p>
                  <b>
                    {!handleErrorData(dateEnd) ? <FormattedDate dateString={dateEnd} /> : 'Non renseigné'}
                  </b>
                </Grid.Row>
                {/* Personne en charge du rendez-vous */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="user" />
                  <p style={{ paddingRight: '3px' }}>
                    Assigné à :
                  </p>
                  <b>
                    {!_.isEmpty(assignedUserName) ? assignedUserName : 'Non renseigné'}
                  </b>
                </Grid.Row>
                {/* Métier de la personne en charge du rendez-vous */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="briefcase" />
                  <p style={{ paddingRight: '3px' }}>
                    Métier :
                  </p>
                  <b>
                    {job}
                  </b>
                </Grid.Row>
              </Grid>
            </Modal.Content>
            {/* Bouton pour quitter la Modal */}
            <Modal.Actions>
              <Button
                positive
                content="Fermer"
                icon="close"
                size="tiny"
                onClick={() => setOpen(false)}
              />
            </Modal.Actions>
          </Modal>
        ) : ''}
      </ListeTable.Cell>
      {/* Valeur pour "Statut" */}
      <ListeTable.Cell
        name="status"
        style={handleErrorData(status) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
        textAlign="center"
      >
        <Popup
          content={(
            <>
              <p>
                Le rendez-vous est :
              </p>
              <p style={{ textAlign: 'center' }}>
                {labelFunction(status)}
              </p>
            </>
          )}
          trigger={labelFunction(status)}
          position="top center"
          hoverable
        />
      </ListeTable.Cell>
      {/* Valeur pour "Type de visite" */}
      <ListeTable.Cell
        name="typeVisiteC"
        style={handleErrorData(typeVisiteC) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {visiteType}
      </ListeTable.Cell>
      {/* Valeur pour "Date de début" */}
      <ListeTable.Cell
        name="dateStart"
        style={handleErrorData(dateStart) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {!handleErrorData(dateStart) ? <FormattedDate dateString={dateStart} /> : 'Non renseigné'}
      </ListeTable.Cell>
      {/* Valeur pour "Date de fin" */}
      <ListeTable.Cell
        name="dateEnd"
        style={handleErrorData(dateEnd) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {!handleErrorData(dateEnd) ? <FormattedDate dateString={dateEnd} /> : 'Non renseigné'}
      </ListeTable.Cell>
      {/* Valeur pour "Assigné à" */}
      <ListeTable.Cell
        name="assignedUserName"
        style={_.isEmpty(assignedUserName) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {!_.isEmpty(assignedUserName) ? assignedUserName : 'Non renseigné'}
      </ListeTable.Cell>
      {/* Valeur pour "Métier" */}
      <ListeTable.Cell
        name="metierC"
        style={_.isEmpty(metierC) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {!_.isEmpty(metierC) ? (metierC[0].charAt(0).toUpperCase() + metierC[0].slice(1)).replace(/_/g, ' ') : 'Non renseigné'}
      </ListeTable.Cell>
    </ListeTable.Row>
  );
};

/** Props venant de MeetingsList */
MeetingsRow.propTypes = {
  // Informations du client
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
};

/** Types par défault des props */
MeetingsRow.defaultProps = {
  item: {},
};

export default MeetingsRow;
