/**
 * @brief Ligne d'un composant d'un client
 */
import React from 'react';
import _ from 'lodash';

import { useParams } from 'react-router';

import PropTypes from 'prop-types';
import { ListeTable } from '@jvs-group/jvs-mairistem-liste';
import { Button, Modal } from '@jvs-group/jvs-mairistem-composants';
import { RequestContext } from '../../Context';
import { convertKeysToCamelCase, useAuthRequest } from '../../../utils';
import { getUsersFromComponent } from '../../../routes';
import ComponentsUsersList from './ComponentsUsersList';
import ComponentsCounter from './ComponentsCounter';

const ComponentsRow = (props) => {
  // Décomposition des props
  const {
    item,
    isOmega,
  } = props;

  // Décomposition de item
  const {
    name,
  } = item;

  /** Identifiant Sugar de la fiche. */
  const { identifiant } = useParams();

  const [allUsers, setAllUsers] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const { prepareRequest } = React.useContext(RequestContext);

  const request = useAuthRequest();

  // request item.identifiant + isomega

  React.useEffect(() => {
    prepareRequest(() => request({
      method: 'POST',
      url: getUsersFromComponent(),
      data: {
        externalId: identifiant,
        idComposant: item.identifiant,
        isOmega,
      },
    }).then((response) => {
      setAllUsers(_.values(convertKeysToCamelCase(response)));
    }).catch((error) => {
      // eslint-disable-next-line
      console.log('catch erreur componentRow', error);
    }));
  }, [item]);

  const formattedAllUsers = React.useMemo(() => _.map(
    allUsers,
    (allUser) => ({
      identifiant: allUser.id,
      ..._.omit(allUser, 'id'),
    }),
  ), [allUsers]);

  return (
    // Ligne du tableau
    <ListeTable.Row key={identifiant}>
      {/* Valeur pour "Composants" */}
      <ListeTable.Cell
        name="Name"
        style={_.isEmpty(name) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {!_.isEmpty(name) ? name : 'Non renseigné'}
      </ListeTable.Cell>
      {/* Valeur pour "Utilisateur final" */}
      <ListeTable.Cell
        name="FinalUser"
        style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
        textAlign="center"
      >
        {_.size(_.filter(formattedAllUsers, (user) => user?.userCustomer?.role === 'ROLE_USER'))}
      </ListeTable.Cell>
      {/* Valeur pour "Référent partenaire" */}
      <ListeTable.Cell
        name="PartnerUser"
        style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
        textAlign="center"
      >
        {_.size(_.filter(formattedAllUsers, (user) => user?.userCustomer?.role === 'ROLE_PARTNER'))}
      </ListeTable.Cell>
      {/* Valeur pour "Référent client" */}
      <ListeTable.Cell
        name="ClientUser"
        style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
        textAlign="center"
      >
        {_.size(_.filter(formattedAllUsers, (user) => user?.userCustomer?.role === 'ROLE_CUSTOMER'))}
      </ListeTable.Cell>
      {/* Valeur pour "Autres" */}
      <ListeTable.Cell
        name="OtherUser"
        style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
        textAlign="center"
      >
        {_.size(_.filter(formattedAllUsers, (user) => user?.userCustomer?.role !== 'ROLE_CUSTOMER' && user?.userCustomer?.role !== 'ROLE_PARTNER' && user?.userCustomer?.role !== 'ROLE_USER'))}
      </ListeTable.Cell>
      {/* Bouton pour la modal */}
      <ListeTable.Cell
        name="Info"
        onClick={() => setOpen(!open)}
        style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
        textAlign="center"
      >
        <Button icon="info" size="mini" onClick={() => setOpen(!open)} />
      </ListeTable.Cell>

      {/* Modale de visualisation des utilisateurs */}
      <Modal
        colorHeader="blue"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <Modal.Header>
          Composant :
          {' '}
          {name}
        </Modal.Header>
        <Modal.Content>
          <ComponentsCounter item={item} isOmega={isOmega} />
          <ComponentsUsersList users={formattedAllUsers} />
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            content="Fermer"
            icon="close"
            size="tiny"
            onClick={() => setOpen(false)}
          />
        </Modal.Actions>
      </Modal>
    </ListeTable.Row>
  );
};

/** Props venant de ComponentsList */
ComponentsRow.propTypes = {
  // Informations d'un composant
  // eslint-disable-next-line
  item: PropTypes.object,
  isOmega: PropTypes.bool,
};

/** Types par défault des props */
ComponentsRow.defaultProps = {
  item: {},
  isOmega: false,
};

export default ComponentsRow;
