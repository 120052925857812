/**
 * @brief Panneau des composants d'un client (évites la redondance de componentsList)
 */
import React from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';
import ComponentsList from './ComponentsList';

const ComponentsPanel = (props) => {
  const {
    componentsJvs, componentsOmega, nameComponentsJvs, nameComponentsOmega, oracleEnvironment,
  } = props;

  return (
    <div>
      {oracleEnvironment && (
        <h3>
          Environnement Oracle :
          {' '}
          {_.replace(oracleEnvironment, 'ENV', 'environnement ')}
        </h3>
      )}
      <h3>
        Composants JVS :
        {nameComponentsJvs}
      </h3>
      <ComponentsList components={componentsJvs} isOmega={false} />

      <h3>
        Composants Omega :
        {nameComponentsOmega}
      </h3>
      <ComponentsList components={componentsOmega} isOmega />
    </div>
  );
};

/** Props venant de FicheCustomerConsumer */
ComponentsPanel.propTypes = {
  // Ensemble des composants du client
  // eslint-disable-next-line
  componentsJvs: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line
  componentsOmega: PropTypes.arrayOf(PropTypes.object),
  nameComponentsJvs: PropTypes.string,
  nameComponentsOmega: PropTypes.string,
  oracleEnvironment: PropTypes.string,
};

/** Types par défault des props */
ComponentsPanel.defaultProps = {
  componentsJvs: [],
  componentsOmega: [],
  nameComponentsJvs: '',
  nameComponentsOmega: '',
  oracleEnvironment: '',
};

export default ComponentsPanel;
