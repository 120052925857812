/**
 * @brief Signalement d'un client
 */
import React from 'react';
import { Form } from '@jvs-group/jvs-mairistem-composants';
import { Fiche } from '@jvs-group/jvs-mairistem-fiche';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { RequestContext } from '../Context';
import { getReportDataByCustomerAndFormId } from '../../routes';
import { useAuthRequest } from '../../utils';

const CustomerReport = () => {
  const [loading, setLoading] = React.useState(true);
  const [formData, setFormData] = React.useState({});
  const { prepareRequest } = React.useContext(RequestContext);
  const params = useParams();
  const request = useAuthRequest();

  /** Identifiant du client */
  const idKnote = params.identifiant;
  const { collaborator } = params;
  const { formulaire } = params;

  React.useEffect(() => {
    // Appel api pour avoir les données signalées
    prepareRequest(() => request({
      method: 'POST',
      url: getReportDataByCustomerAndFormId(formulaire),
      data: { idKnote, collaborator },
    }).then((response) => {
      // Traiter les données
      setLoading(false);
      setFormData(response);
    })
      .catch((error) => {
        // eslint-disable-next-line
        console.log(
          'erreur lors de la récupération des données signalées',
          error,
        );
      }));
  }, [idKnote]);

  return (
    <Fiche
      // Clé unique de la fiche
      key="FormCustomer"
      // Informations dans la barre en haut de la page
      header="Nom du client ICI - IDENTIFIANT CLIENT"
      // Couleur des boutons qui seront potentiellement ajoutés
      color="blue"
      // Booléen pour le chargement des données
      loading={loading}
      // Barre sur le côté qui prendra CustomersListe
      sidebars={
        {
          // TODO faire un component sidebars avec toutes les fonctions nécessaires
          // left: (
          //   <>
          //     {/* Bouton pour revenir à l'accueil */}
          //     <Button
          //       // Texte dans le bouton
          //       content="Retour à l'accueil"
          //       // Icon dans le bouton
          //       icon="angle left"
          //       // Style
          //       compact
          //       basic
          //       style={{ margin: '15px 15px 0 15px', width: 200 }}
          //       floated="left"
          //       // Fonction lors du clic
          //       onClick={handleBack}
          //     />
          //     {/* Historique personnel */}
          //     <HistoryList
          //       searchHistory={searchHistory}
          //       loading={loadingHistory}
          //       handleSelect={handleSelect}
          //       inSidebar
          //       identifiant={identifiant}
          //     />
          //     {/* Liste de tous les clients */}
          //     <CustomersListe
          //       expands
          //       handleSelect={handleSelect}
          //     />
          //   </>
          // ),
        }
      }
    >
      {/* Contenu de la fiche */}
      <Fiche.Panel
        // Clé unique du Panel
        key="informations"
        // Identifiant unique du Panel
        id="informations"
        // Nom dans le tab haut-droit
        title="Informations"
        // Fermeture de l'onglet
        open="true"
      >
        <Form>
          {/* Contenu du Panel */}
          <Form.Group widths="equal">
            <Form.Input
              // Le name sert à trouver le bon champ
              // On peut s'en passer et remplacer les input par des textes
              name="Nom de la collectivité"
              // formData sert à récupérer les données de l'api
              // On met value et non defaultValue pour ne pas laisser l'utilisateur modifier
              value={
                formData['Nom de la collectivité']
                  ? formData['Nom de la collectivité'].value
                  : ''
              }
              fluid
              label="Nom de la collectivité"
              placeholder="Mairie de..."
            />
            <Form.Input
              name="Siret"
              value={formData.Siret ? formData.Siret.value : ''}
              fluid
              label="SIRET"
              placeholder="2457..."
            />
            <Form.Input
              name="Adresse de la collectivité"
              value={
                formData['Adresse de la collectivité']
                  ? formData['Adresse de la collectivité'].value
                  : ''
              }
              fluid
              label="Adresse de la collectivité"
              placeholder="9 rue de..."
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="Code postal"
              value={
                formData['Code postal'] ? formData['Code postal'].value : ''
              }
              fluid
              label="Code postal"
              placeholder="51100"
            />
            <Form.Input
              name="Ville"
              value={formData.Ville ? formData.Ville.value : ''}
              fluid
              label="Ville"
              placeholder="Fagnières"
            />
            <Form.Input
              name="Téléphone professionnel"
              value={
                formData['Téléphone professionnel']
                  ? formData['Téléphone professionnel'].value
                  : ''
              }
              fluid
              label="Téléphone professionnel"
              placeholder="03.26..."
            />
          </Form.Group>
        </Form>
      </Fiche.Panel>
      <Fiche.Panel
        // Clé unique du Panel
        key="contacts"
        // Identifiant unique du Panel
        id="contacts"
        // Nom dans le tab haut-droit
        title="Contacts"
        // Fermeture de l'onglet
        open={false}
      >
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              name="Prénom du maire"
              value={
                formData['Prénom du maire']
                  ? formData['Prénom du maire'].value
                  : ''
              }
              fluid
              label="Prénom du maire"
              placeholder="Jean"
            />
            <Form.Input
              name="Nom du maire"
              value={
                formData['Nom du maire'] ? formData['Nom du maire'].value : ''
              }
              fluid
              label="Nom du maire"
              placeholder="Martin"
            />
            <Form.Input
              name="Adresse mail du maire"
              value={
                formData['Adresse mail du maire']
                  ? formData['Adresse mail du maire'].value
                  : ''
              }
              fluid
              label="Adresse mail du maire"
              placeholder="exemple@outlook.fr"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="Prénom DGS/DGA"
              value={
                formData['Prénom DGS/DGA']
                  ? formData['Prénom DGS/DGA'].value
                  : ''
              }
              fluid
              label="Prénom DGS/DGA"
              placeholder="Jean"
            />
            <Form.Input
              name="Nom du DGS/DGA"
              value={
                formData['Nom du DGS/DGA']
                  ? formData['Nom du DGS/DGA'].value
                  : ''
              }
              fluid
              label="Nom du DGS/DGA"
              placeholder="Martin"
            />
            <Form.Input
              name="Adresse mail du DGS/DGA"
              value={
                formData['Adresse mail du DGS/DGA']
                  ? formData['Adresse mail du DGS/DGA'].value
                  : ''
              }
              fluid
              label="Adresse mail du DGS/DGA"
              placeholder="exemple@outlook.fr"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="Prénom du DSI"
              value={
                formData['Prénom du DSI'] ? formData['Prénom du DSI'].value : ''
              }
              fluid
              label="Prénom du DSI"
              placeholder="Jean"
            />
            <Form.Input
              name="Nom du DSI"
              value={formData['Nom du DSI'] ? formData['Nom du DSI'].value : ''}
              fluid
              label="Nom du DSI"
              placeholder="Martin"
            />
            <Form.Input
              name="Adresse mail du DSI"
              value={
                formData['Adresse mail du DSI']
                  ? formData['Adresse mail du DSI'].value
                  : ''
              }
              fluid
              label="Adresse mail du DSI"
              placeholder="exemple@outlook.fr"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="Prénom du chef de projet"
              value={
                formData['Prénom du chef de projet']
                  ? formData['Prénom du chef de projet'].value
                  : ''
              }
              fluid
              label="Prénom du chef de projet"
              placeholder="Jean"
            />
            <Form.Input
              name="Nom du chef de projet"
              value={
                formData['Nom du chef de projet']
                  ? formData['Nom du chef de projet'].value
                  : ''
              }
              fluid
              label="Nom du chef de projet"
              placeholder="Martin"
            />
            <Form.Input
              name="Adresse mail du chef de projet"
              value={
                formData['Adresse mail du chef de projet']
                  ? formData['Adresse mail du chef de projet'].value
                  : ''
              }
              fluid
              label="Adresse mail du chef de projet"
              placeholder="exemple@outlook.fr"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="Prénom du DPO"
              value={
                formData['Prénom du DPO'] ? formData['Prénom du DPO'].value : ''
              }
              fluid
              label="Prénom du DPO"
              placeholder="Jean"
            />
            <Form.Input
              name="Nom du DPO"
              value={formData['Nom du DPO'] ? formData['Nom du DPO'].value : ''}
              fluid
              label="Nom du DPO"
              placeholder="Martin"
            />
            <Form.Input
              name="Adresse mail du DPO"
              value={
                formData['Adresse mail du DPO']
                  ? formData['Adresse mail du DPO'].value
                  : ''
              }
              fluid
              label="Adresse mail du DPO"
              placeholder="exemple@outlook.fr"
            />
          </Form.Group>
        </Form>
      </Fiche.Panel>
      <Fiche.Panel
        // Clé unique du Panel
        key="Commentaire"
        // Identifiant unique du Panel
        id="commentaire"
        // Nom dans le tab haut-droit
        title="commentaire"
        // Fermeture de l'onglet
        open
      >
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              name="Commentaire"
              value={
                formData.Commentaire ? formData.Commentaire.value : ''
              }
              fluid
              label="Commentaire"
              placeholder="Commentaire ..."
            />
          </Form.Group>
        </Form>
      </Fiche.Panel>
    </Fiche>
  );
};

export default CustomerReport;
