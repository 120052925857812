/**
 * @brief Récupérer les données de l'api mic
 */

const urlBase = process.env.REACT_APP_API_URL;

/** Récupération des suivis du mic d'un client */
export const getMicSuivis = () => `${urlBase}/api/mic`;

/** Récupération des utilisateurs e-learning d'un client */
export const getMicElearningUsers = () => `${urlBase}/api/mic/elearning/users`;
