/**
 * @brief Graphique des tickets zendesk d'un client
 */
import React from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

const tabName = (status) => {
  switch (status) {
    case 'Hold':
      return 'En pause';
    case 'Pending':
      return 'En attente'; // TODO ajouter en cours
    case 'Closed':
      return 'Fermé';
    case 'Solved':
      return 'Résolu';
    case 'Open':
      return 'Ouvert';
    case 'New':
      return 'Nouveau';
    default:
      return status;
  }
};

const tabColors = (status) => {
  switch (status) {
    case 'Hold':
      return '#00c0ef';
    case 'Pending':
      return '#f39c12';
    case 'Closed':
      return '#00561b';
    case 'Solved':
      return '#00a65a';
    case 'Open':
      return '#ddab69';
    case 'New':
      return '#dd4b39';
    default:
      return '#dd4b39';
  }
};

const CasesGraphic = (props) => {
  const { cases } = props;

  ChartJS.register(ArcElement, Tooltip, Legend);

  const datas = React.useMemo(() => {
    const statusList = _.uniq(_.map(cases, ({ status }) => status));

    const statusDatas = _.map(statusList, (status) => _.size(_.filter(cases, { status })));

    return {
      labels: _.map(statusList, tabName),
      datasets: [{
        label: 'Résumé',
        data: statusDatas,
        backgroundColor: _.map(statusList, tabColors),
        hoverOffset: 5,
      }],
    };
  }, [cases]);

  return (
    <div style={{
      height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center',
    }}
    >
      {!_.isEmpty(datas) && <Doughnut data={datas} />}
    </div>
  );
};

/** Props venant de CasesTabs */
CasesGraphic.propTypes = {
  // Ensemble des tickets zendesk du client
  // eslint-disable-next-line
  cases: PropTypes.arrayOf(PropTypes.object),
};

/** Types par défault des props */
CasesGraphic.defaultProps = {
  cases: [],
};

export default CasesGraphic;
