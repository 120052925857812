/**
 * @brief Ligne d'un rendez-vous d'un client
 */
import React from 'react';
import _ from 'lodash';
import { Button, Form, InputMask } from '@jvs-group/jvs-mairistem-composants';
import { Fiche } from '@jvs-group/jvs-mairistem-fiche';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { toast } from 'react-toastify';
import { LogContext, RequestContext } from '../../Context';
import { getFormDataByCustomerId } from '../../../routes';
import { Sidebar } from '../../Sidebar';
import { useAuthRequest } from '../../../utils';

const FormCustomer = () => {
  const [loading, setLoading] = React.useState(true);
  const [formData, setFormData] = React.useState({});
  const { prepareRequest } = React.useContext(RequestContext);
  const { userPrincipalName } = React.useContext(LogContext);
  const [name, setName] = React.useState('');
  const params = useParams();
  const request = useAuthRequest();
  /** Identifiant du client */
  const idKnote = params.identifiant;

  React.useEffect(() => {
    // Appel api pour avoir les données Sugar
    prepareRequest(() => request({
      method: 'POST',
      url: getFormDataByCustomerId(),
      data: { idKnote },
    }).then((response) => {
      // Traiter les données
      setLoading(false);
      setFormData(response);
    })
      .catch((error) => {
        // eslint-disable-next-line
        console.log("erreur lors de la récupération des données Sugar", error);
      }));
  }, [idKnote]);

  React.useEffect(() => {
    if (!_.isNil(userPrincipalName)) {
      // Diviser sur le caractère '.'
      const [prenom, rest] = !_.isNil(userPrincipalName) ? userPrincipalName.split('.') : '';
      // Diviser le reste sur le caractère '@'
      const [nom] = rest.split('@');

      // Mettre la première lettre de 'nom' et 'prenom' en majuscule, et le reste en minuscule
      const formattedNom = nom.toUpperCase();
      const formattedPrenom = prenom.charAt(0).toUpperCase() + prenom.slice(1).toLowerCase();

      // Combiner 'prenom' et 'nom' pour obtenir "Prenom NOM"
      const result = `${formattedPrenom} ${formattedNom}`;

      setName(result);
    } else {
      setName('Prénom NOM');
    }
  }, [userPrincipalName]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Fonction à utiliser pour chaque formulaire : se charge de l'envoi automatique
    const form = e.target;
    const finalValues = [];
    // On est obligés d'instancier le tableau vide sinon y'a une erreure
    // eslint-disable-next-line
    const tempVal = [].forEach.call(
      // form.elements.forEach renvoi une erreur
      form.elements,
      (element) => {
        // On formate les valeurs
        const values = {
          name: element.name,
          value: element.value,
          report: 0,
        };

        // Si la valeur n'est pas vide on l'ajoute au tableau
        if (element.value !== '') {
          finalValues.push(values);
        }
      },
      {},
    );

    // Formater les données
    const datas = {
      idKnote,
      values: finalValues,
      // ! TODO Trouver comment envoyer le nom de l'utilisateur
      collaboratorName: name,
    };

    // Avoir l'url de l'api
    const uri = process.env.NODE_ENV !== 'development'
      ? 'https://api-si.jvsonline.fr/api/form/save/'
      : 'https://localhost:8000/form/save/';
    return request({
      method: 'POST',
      url: uri + form.name,
      data: datas,
    }).then((response) => {
      // eslint-disable-next-line
        console.log(response);
      toast.success('Données enregistrées avec succès !');
      return response;
    })
      .catch((error) => {
        // eslint-disable-next-line
        toast.error("erreur lors de l'enregistrement des données du formulaire", error);
      });
  };
  return (
    <Fiche
      // Clé unique de la fiche
      key="FormCustomer"
      // Informations dans la barre en haut de la page
      header={`Formulaire pour le client ${idKnote} ${formData['Nom de la collectivité'] ? `- ${formData['Nom de la collectivité'].value}` : ''}`}
      // Couleur des boutons qui seront potentiellement ajoutés
      color="blue"
      // Booléen pour le chargement des données
      loading={loading}
      // Barre sur le côté qui prendra CustomersListe
      sidebars={{
        left: (<Sidebar identifiant={idKnote} customer={formData} params={params} />),
      }}
    >

      {/* Contenu de la fiche */}
      <Fiche.Panel
        // Clé unique du Panel
        key="informations"
        // Identifiant unique du Panel
        id="informations"
        // Nom dans le tab haut-droit
        title="Informations générales"
        // Fermeture de l'onglet
        open="true"
      >
        <Form onSubmit={handleSubmit} name="1" key="form1" id="form1">
          {/* Contenu du Panel */}
          <Form.Group widths="equal">
            <Form.Input
              // Le name sert à trouver le bon champ
              // On peut s'en passer et remplacer les input par des textes
              name="Nom de la collectivité"
              // formData sert à récupérer les données de l'api
              // On met value et non defaultValue pour ne pas laisser l'utilisateur modifier
              defaultValue={
                formData['Nom de la collectivité']
                  ? formData['Nom de la collectivité'].value
                  : ''
              }
              fluid
              label="Nom de la collectivité"
              placeholder="Mairie de..."
            />
            <div className="field">
              {/* eslint-disable jsx-a11y/label-has-associated-control */}
              <label>SIREN</label>
              <InputMask
                name="Siren"
                value={formData.Siren ? formData.Siren.value : ''}
                fluid
                placeholder="123 456 789"
                mask="999 999 999"
              />
            </div>

            <Form.Input
              name="Adresse de la collectivité"
              defaultValue={
                formData['Adresse de la collectivité']
                  ? formData['Adresse de la collectivité'].value
                  : ''
              }
              fluid
              label="Adresse de la collectivité"
              placeholder="9 rue de..."
            />
          </Form.Group>
          <Form.Group widths="equal">

            <div className="field">
              {/* eslint-disable jsx-a11y/label-has-associated-control */}
              <label>Code postal</label>
              <InputMask
                name="Code postal"
                value={formData['Code postal'] ? formData['Code postal'].value : ''}
                fluid
                placeholder="51100"
                mask="99999"
              />
            </div>
            <Form.Input
              name="Ville"
              defaultValue={formData.Ville ? formData.Ville.value : ''}
              fluid
              label="Ville"
              placeholder="Fagnières"
            />

            <div className="field">
              {/* eslint-disable jsx-a11y/label-has-associated-control */}
              <label>Téléphone professionnel</label>
              <InputMask
                name="Téléphone professionnel"
                value={
                  formData['Téléphone professionnel']
                    ? formData['Téléphone professionnel'].value
                    : ''
                }
                fluid
                placeholder="03.26..."
                mask="99 99 99 99 99"
              />
            </div>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.TextArea
              name="Commentaire"
              defaultValue={
                formData.Commentaire
                  ? formData.Commentaire.value
                  : ''
              }
              fluid
              label="Commentaires complémentaires"
              placeholder="Commentaire ..."
            />
          </Form.Group>
          <Button type="submit" primary>
            Signaler un changement d&apos;informations générales
          </Button>
        </Form>
      </Fiche.Panel>
      <Fiche.Panel
        // Clé unique du Panel
        key="contacts"
        // Identifiant unique du Panel
        id="contacts"
        // Nom dans le tab haut-droit
        title="Contacts principaux"
        // Fermeture de l'onglet
        open="true"
      >
        <Form onSubmit={handleSubmit} name="1" key="form2" id="form2">
          <Form.Group widths="equal">
            <Form.Input
              name="Prénom du maire"
              defaultValue={
                formData['Prénom du maire']
                  ? formData['Prénom du maire'].value
                  : ''
              }
              fluid
              label="Prénom du maire"
              placeholder="Jean"
            />
            <Form.Input
              name="Nom du maire"
              defaultValue={
                formData['Nom du maire'] ? formData['Nom du maire'].value : ''
              }
              fluid
              label="Nom du maire"
              placeholder="Martin"
            />
            <Form.Input
              name="Adresse mail du maire"
              defaultValue={
                formData['Adresse mail du maire']
                  ? formData['Adresse mail du maire'].value
                  : ''
              }
              fluid
              label="Adresse mail du maire"
              placeholder="exemple@outlook.fr"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="Prénom DGS/DGA"
              defaultValue={
                formData['Prénom DGS/DGA']
                  ? formData['Prénom DGS/DGA'].value
                  : ''
              }
              fluid
              label="Prénom DGS/DGA"
              placeholder="Jean"
            />
            <Form.Input
              name="Nom du DGS/DGA"
              defaultValue={
                formData['Nom du DGS/DGA']
                  ? formData['Nom du DGS/DGA'].value
                  : ''
              }
              fluid
              label="Nom du DGS/DGA"
              placeholder="Martin"
            />
            <Form.Input
              name="Adresse mail du DGS/DGA"
              defaultValue={
                formData['Adresse mail du DGS/DGA']
                  ? formData['Adresse mail du DGS/DGA'].value
                  : ''
              }
              fluid
              label="Adresse mail du DGS/DGA"
              placeholder="exemple@outlook.fr"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="Prénom du DSI"
              defaultValue={
                formData['Prénom du DSI'] ? formData['Prénom du DSI'].value : ''
              }
              fluid
              label="Prénom du DSI"
              placeholder="Jean"
            />
            <Form.Input
              name="Nom du DSI"
              defaultValue={formData['Nom du DSI'] ? formData['Nom du DSI'].value : ''}
              fluid
              label="Nom du DSI"
              placeholder="Martin"
            />
            <Form.Input
              name="Adresse mail du DSI"
              defaultValue={
                formData['Adresse mail du DSI']
                  ? formData['Adresse mail du DSI'].value
                  : ''
              }
              fluid
              label="Adresse mail du DSI"
              placeholder="exemple@outlook.fr"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="Prénom du chef de projet"
              defaultValue={
                formData['Prénom du chef de projet']
                  ? formData['Prénom du chef de projet'].value
                  : ''
              }
              fluid
              label="Prénom du chef de projet"
              placeholder="Jean"
            />
            <Form.Input
              name="Nom du chef de projet"
              defaultValue={
                formData['Nom du chef de projet']
                  ? formData['Nom du chef de projet'].value
                  : ''
              }
              fluid
              label="Nom du chef de projet"
              placeholder="Martin"
            />
            <Form.Input
              name="Adresse mail du chef de projet"
              defaultValue={
                formData['Adresse mail du chef de projet']
                  ? formData['Adresse mail du chef de projet'].value
                  : ''
              }
              fluid
              label="Adresse mail du chef de projet"
              placeholder="exemple@outlook.fr"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="Prénom du DPO"
              defaultValue={
                formData['Prénom du DPO'] ? formData['Prénom du DPO'].value : ''
              }
              fluid
              label="Prénom du DPO"
              placeholder="Jean"
            />
            <Form.Input
              name="Nom du DPO"
              defaultValue={formData['Nom du DPO'] ? formData['Nom du DPO'].value : ''}
              fluid
              label="Nom du DPO"
              placeholder="Martin"
            />
            <Form.Input
              name="Adresse mail du DPO"
              defaultValue={
                formData['Adresse mail du DPO']
                  ? formData['Adresse mail du DPO'].value
                  : ''
              }
              fluid
              label="Adresse mail du DPO"
              placeholder="exemple@outlook.fr"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.TextArea
              name="Commentaires"
              defaultValue={
                formData.Commentaire
                  ? formData.Commentaire.value
                  : ''
              }
              fluid
              label="Commentaires complémentaires"
              placeholder="Commentaire ..."
            />
          </Form.Group>
          <Button type="submit" primary>
            Demander un ajout de contacts principaux
          </Button>
        </Form>
      </Fiche.Panel>
    </Fiche>
  );
};

/** Types par défault des props */
FormCustomer.defaultProps = {
  idKnote: '',
  item: {},
};

export default FormCustomer;
