/**
 * @brief Liste des leads d'un client
 */
import React from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';

import { ListeTable } from '@jvs-group/jvs-mairistem-liste';
import { Icon } from '@jvs-group/jvs-mairistem-composants';
import LeadsRow from './LeadsRow';
import { tableSort } from '../Functions/Functions';

const LeadsList = (props) => {
  // Décomposition des props
  const {
    leads,
  } = props;

  /** Ensemble des états que peut prendre une cellule en-tête d'un tableau */
  const [state, dispatch] = React.useReducer(tableSort, {
    column: null,
    data: leads,
    direction: null,
  });

  // Décomposition de state
  const { column, data, direction } = state;

  /** Changement de la valeur data avec les nouveaux leads */
  React.useEffect(() => {
    dispatch({ type: 'CHANGE_CUSTOMER', data: leads });
  }, [leads]);

  return (
    <div style={!_.isEmpty(data) ? { height: `${data.length * 45 + 47}px`, maxHeight: '800px' } : { height: '300px', maxHeight: '800px' }}>
      {/* Tableau des leads du client */}
      <ListeTable
        count={_.size(data)}
        // Données directement dans le tableau
        items={data}
        color="blue"
        selection="click"
        style={{ overflowY: 'auto' }}
      >
        {/* En-tête du tableau */}
        <ListeTable.Header>
          {/* Cellule "Nom" */}
          <ListeTable.Column
            name="fullName"
            width={4}
            // Triage du tableau
            sorted={column === 'fullName' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'fullName' })}
          >
            <Icon name="angle right" />
            Nom
          </ListeTable.Column>
          {/* Cellule "Métier" */}
          <ListeTable.Column
            name="metierC"
            width={2}
            // Triage du tableau
            sorted={column === 'metierC' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'metierC' })}
            // Responsive
            only={[
              'xl',
            ]}
          >
            <Icon name="clipboard" />
            Métier
          </ListeTable.Column>
          {/* Cellule "Produits et services" */}
          <ListeTable.Column
            name="logicielC"
            width={2}
            // Triage du tableau
            sorted={column === 'logicielC' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'logicielC' })}
            // Responsive
            only={[
              'xl',
            ]}
          >
            <Icon name="clipboard" />
            Produits et services
          </ListeTable.Column>
          {/* Cellule "Etape" */}
          <ListeTable.Column
            name="etapeC"
            width={2}
            // Triage du tableau
            sorted={column === 'etapeC' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'etapeC' })}
            // Responsive
            only={[
              'xl',
            ]}
          >
            <Icon name="clipboard" />
            Etape
          </ListeTable.Column>
          {/* Cellule "Date de création" */}
          <ListeTable.Column
            name="dateEntered"
            width={2}
            // Triage du tableau
            sorted={column === 'dateEntered' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'dateEntered' })}
            // Responsive
            only={[
              'xl',
            ]}
          >
            <Icon name="clipboard" />
            Date de création
          </ListeTable.Column>
          {/* Cellule "Assigné à" */}
          <ListeTable.Column
            name="assignedUserName"
            width={2}
            // Triage du tableau
            sorted={column === 'assignedUserName' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'assignedUserName' })}
            // Responsive
            only={[
              'xl',
            ]}
          >
            <Icon name="clipboard" />
            Assigné à
          </ListeTable.Column>
        </ListeTable.Header>

        {/* Contenu du tableau */}
        {(item) => (
          <LeadsRow item={item} />
        )}

      </ListeTable>

    </div>
  );
};

/** Props venant de FicheCustomerConsumer */
LeadsList.propTypes = {
  // Ensemble des contacts du client
  // eslint-disable-next-line
  leads: PropTypes.arrayOf(PropTypes.object),
};

/** Types par défault des props */
LeadsList.defaultProps = {
  leads: [],
};

export default LeadsList;
