/**
 * @brief Conteneur de l'historique d'un utilisateur
 */
import React from 'react';
import _ from 'lodash';

import {
  Segment,
  Header,
  Icon,
} from '@jvs-group/jvs-mairistem-composants';

import PropTypes from 'prop-types';

import { ListeTable } from '@jvs-group/jvs-mairistem-liste';

import HistoryRow from './HistoryRow';

const HistoryList = (props) => {
  // Décomposition des props
  const {
    searchHistory,
    loading,
    handleSelect,
    inSidebar,
  } = props;

  return (
    <>
      {/* Condition pour si l'historique est dans la sidebar ou non */}
      {inSidebar ? (
        <>
          {/* En-tête */}
          <Header>
            Mes dernières recherches
          </Header>
          {/* Historique de consultation */}
          <ListeTable
            count={_.size(searchHistory)}
            items={searchHistory}
            color="blue"
            loading={loading}
            // Lorsqu'une ligne est cliquée
            onSelect={handleSelect}
          >
            {/* En-tête du tableau */}
            <ListeTable.Header>
              {/* Cellule "Nom du client" */}
              <ListeTable.Column
                name="name"
                width={8}
              >
                <Icon name="angle right" />
                Nom du client
              </ListeTable.Column>
              {/* Cellule "Identifiant KNOTE" */}
              <ListeTable.Column
                name="idKnote"
                width={5}
              >
                <Icon name="lightbulb" />
                Id. KNOTE
              </ListeTable.Column>
            </ListeTable.Header>
            {/* Contenu du tableau */}
            {(item) => (
              <HistoryRow item={item} />
            )}
          </ListeTable>
        </>
      ) : (
        // Conteneur de l'en-tête et du tableau
        <Segment padded style={{ flex: 1, paddingBottom: 50 }}>
          {/* En-tête */}
          <Header>
            Mes dernières recherches
          </Header>
          {/* Historique de consultation */}
          <ListeTable
            count={_.size(searchHistory)}
            items={searchHistory}
            color="blue"
            loading={loading}
            // Lorsqu'une ligne est cliquée
            onSelect={handleSelect}
          >
            {/* En-tête du tableau */}
            <ListeTable.Header>
              {/* Cellule "Nom du client" */}
              <ListeTable.Column
                name="name"
                width={8}
              >
                <Icon name="angle right" />
                Nom du client
              </ListeTable.Column>
              {/* Cellule "Identifiant KNOTE" */}
              <ListeTable.Column
                name="idKnote"
                width={5}
              >
                <Icon name="lightbulb" />
                Id. KNOTE
              </ListeTable.Column>
            </ListeTable.Header>
            {/* Contenu du tableau */}
            {(item) => (
              <HistoryRow item={item} />
            )}
          </ListeTable>
        </Segment>
      )}
    </>
  );
};

/** Props venant de CustomersContainer */
HistoryList.propTypes = {
  // L'historique des clients consultés
  // eslint-disable-next-line react/forbid-prop-types
  searchHistory: PropTypes.arrayOf(PropTypes.object),
  // Booléen pour le chargement
  loading: PropTypes.bool,
  // Fonction pour envoyer sur la page avec les informations du client
  handleSelect: PropTypes.func,
  // Booléen pour savoir si l'historique se trouve dans la sidebar
  inSidebar: PropTypes.bool,
};

/** Types par défault des props */
HistoryList.defaultProps = {
  searchHistory: [],
  loading: false,
  handleSelect: null,
  inSidebar: false,
};

export default HistoryList;
