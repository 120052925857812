/**
 * @brief block pour un mot clé logiciels d'un client depuis sugar
 */
import React from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { Label } from '@jvs-group/jvs-mairistem-composants';

const KeywordsBlock = ({ title, keywords }) => (
  keywords.length === 0 ? (
    <h5>
      Pas de
      {' '}
      {title.toLowerCase()}
    </h5>
  ) : (
    <>
      <h5>
        {title}
        {' '}
        :
      </h5>
      {_.map(_.sortBy(keywords), (keyword, index) => (
        <Label circular style={{ margin: '5px' }} color="blue" key={index}>
          {keyword}
        </Label>
      ))}
    </>
  )
);

KeywordsBlock.propTypes = {
  title: PropTypes.string.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default KeywordsBlock;
