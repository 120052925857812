/**
 * @brief Fichier regroupant tous les appels d'api concernant novaTime
 */
// import _ from 'lodash';

// TODO: Retirer l'erreur CORS à l'aide du back
// Pour le lien du bouton carte novatime = https://novatime.jvsonline.fr/projects/{idProjetNovatime}/list/{idDeLaCarte} idprojetNovatime = 29 pour le moment (service déploiement)
// Pour la récupération de l'externalId : https://novatime.jvsonline.fr/api/v3/organisms/3/customers?externalId={id}
// Ajouter header :
// Authorization : Bearer {token}
// --> hydra:member.id
// --------
// Pour récupérer les cartes novaTime : (post) https://novatime.jvsonline.fr/api/projects/29/works/filtered
// Ajouter header :
// Authorization : Bearer {token}
// Ajouter body :
// {
//   "data": {
//     "filter": {
//       "client": [
//         {hydra:member.id}
//       ]
//     },
//     "page": 1,
//     "size": 10
//   }
// }
/** Récupération du token novaTime */
export const getTokenNovatime = () => 'https://api-si.jvsonline.fr/api/novatime/token';

/** Récupération de l'id novatime */
export const getCustomerAndCards = () => 'https://api-si.jvsonline.fr/api/novatime/customer';
