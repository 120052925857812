/**
 * @brief Ligne d'un devis d'un client
 */
import React from 'react';
import _ from 'lodash';

import {
  Button,
  Label,
  Icon,
  Grid,
  Modal,
  Popup,
} from '@jvs-group/jvs-mairistem-composants';

import PropTypes from 'prop-types';

import { ListeTable } from '@jvs-group/jvs-mairistem-liste';

import { FormattedDate } from '../../Functions';
import { handleErrorData } from '../../Functions/Functions';

const EstimatesRow = (props) => {
  const {
    item,
  } = props;

  // Décomposition de item
  const {
    // L'identifiant du devis
    identifiant,
    // La date de la dernière modification du devis
    dateModified,
    // Le nom du devis
    name,
    // Le prix total du devis
    devisTotalAvecOptionC,
    // L'état du devis
    devisEtatC,
    // Le/la commercial.e en charge du devis
    devisCommercialC,
    // Le type du devis
    devisTypeC,
    // L'url vers le PDF du devis
    devisPdfUrlC,
    // Derniere Relance du devis
    devisDatePrevRelanceC,
    // Dernier message de relance du devis
    devisDernMessageRelanceC,
  } = item;

  /**
   * Permet l'affichage de l'état en fonction de sa valeur
   * @param {string} state Etat du devis
   * @return Un label ou du texte en fonction de l'état
   */
  const labelFunctionState = (status) => {
    /** La valeur qui sera retournée */
    let label;

    // Condition pour le cas de l'état
    if (!handleErrorData(status)) {
      // Différentes valeurs de status
      switch (status) {
        // Label 'Alternative'
        case 'EA':
          label = (
            <Label
              style={{ width: '100%' }}
              content="Alternative"
              color="teal"
            />
          );
          break;
        // Label 'En cours'
        case 'EC':
          label = (
            <Label
              style={{ width: '100%' }}
              content="En cours"
              color="olive"
            />
          );
          break;
        // Label 'Conclue'
        case 'AC':
          label = (
            <Label
              style={{ width: '100%' }}
              content="Conclue"
              color="green"
            />
          );
          break;
        // Label 'Perdue'
        case 'AP':
          label = (
            <Label
              style={{ width: '100%' }}
              content="Perdue"
              color="red"
            />
          );
          break;
        // Label 'Reportée'
        case 'RE':
          label = (
            <Label
              style={{ width: '100%' }}
              content="Reportée"
              color="orange"
            />
          );
          break;
        // Label 'Annulée'
        case 'AN':
          label = (
            <Label
              style={{ width: '100%' }}
              content="Annulée"
              color="brown"
            />
          );
          break;
        // Label 'Abandonnée'
        case 'AB':
          label = (
            <Label
              style={{ width: '100%' }}
              content="Abandonnée"
              color="grey"
            />
          );
          break;
        // Label 'En création'
        case 'EP':
          label = (
            <Label
              style={{ width: '100%' }}
              content="En création"
              color="teal"
            />
          );
          break;
        default:
          label = (
            <Label
              style={{ width: '100%' }}
              content="Non reconnu"
            />
          );
          break;
      }
    } else {
      label = 'Non renseignée';
    }

    return label;
  };

  /** Nom du/de la commercial.e */
  let convertedName;
  if (!handleErrorData(devisCommercialC)) {
    /** Mise de la première lettre en majuscule */
    convertedName = devisCommercialC.split('.')[0].charAt(0).toUpperCase();
    /** Récupération du reste du prénom */
    convertedName += devisCommercialC.split('.')[0].slice(1);
    /** Ajout de l'espace */
    convertedName += ' ';
    /** Mise en majuscule du nom */
    convertedName += devisCommercialC.split('.')[1].toUpperCase();
  } else {
    convertedName = 'Non renseigné';
  }

  /** Etat de la Modal */
  const [open, setOpen] = React.useState(false);

  /** Prix avec option du devis */
  const totalEstimate = !handleErrorData(devisTotalAvecOptionC) ? `${devisTotalAvecOptionC.toLocaleString('fr-FR', { style: 'decimal', minimumFractionDigits: 2 })} €` : 'Non renseigné';

  return (
    // Ligne du tableau
    <ListeTable.Row key={identifiant}>
      {/* Valeur pour "Modification" */}
      <ListeTable.Cell
        name="dateModified"
        style={handleErrorData(dateModified) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        {!handleErrorData(dateModified) ? <FormattedDate dateString={dateModified} /> : 'Non renseignée'}
      </ListeTable.Cell>
      <ListeTable.Cell
        name="devisDatePrevRelanceC"
        style={handleErrorData(devisDatePrevRelanceC) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        <div>
          {!handleErrorData(devisDatePrevRelanceC) ? <FormattedDate dateString={devisDatePrevRelanceC} /> : 'Non renseignée'}
        </div>
        <hr />
        <div>
          {/* Ajout du texte en dessous de la daten, pour gagner de la place */}
          {!handleErrorData(devisDernMessageRelanceC) ? devisDernMessageRelanceC : 'Pas de commentaire'}
        </div>
      </ListeTable.Cell>
      {/* Valeur pour "Nom complet" */}
      <ListeTable.Cell
        name="name"
        style={handleErrorData(name) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {!handleErrorData(name) ? name : 'Non renseigné'}
        {/* Apparition de la Modal si la taille de la fenêtre est <= à 825px */}
        {window.innerWidth <= 825 ? (
          <Modal
            trigger={<Icon name="file" style={{ float: 'right' }} />}
            dimmer="blurring"
            colorHeader="blue"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
          >
            {/* Nom du devis */}
            <Modal.Header>
              <Icon name="angle right" size="small" />
              {!handleErrorData(name) ? name : 'Non renseigné'}
            </Modal.Header>
            <Modal.Content>
              <Grid style={{ padding: '0 20px' }}>
                {/* Dernière modification du devis */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="calendar" />
                  <p style={{ paddingRight: '3px' }}>
                    Modification :
                  </p>
                  <b>
                    {!handleErrorData(dateModified) ? (
                      <FormattedDate dateString={dateModified} />
                    ) : 'Non renseignée'}
                  </b>
                </Grid.Row>
                {/* Dernière relance du devis */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="calendar" />
                  <p style={{ paddingRight: '3px' }}>
                    Relance :
                  </p>
                  <div>
                    <b>
                      {!handleErrorData(devisDatePrevRelanceC) ? <FormattedDate dateString={devisDatePrevRelanceC} /> : 'Non renseignée'}
                    </b>
                  </div>
                </Grid.Row>
                {/* Dernier message de relance du devis */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="comment" />
                  <p style={{ paddingRight: '3px' }}>
                    Message Relance :
                  </p>
                  <div>
                    <b>
                      {!handleErrorData(devisDernMessageRelanceC) ? devisDernMessageRelanceC : 'Pas de commentaire'}
                    </b>
                  </div>
                </Grid.Row>
                {/* Prix total avec option du devis */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="euro sign" />
                  <p style={{ paddingRight: '3px' }}>
                    Total :
                  </p>
                  <b>
                    {totalEstimate}
                  </b>
                </Grid.Row>
                {/* Statut de l'affaire */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="write" />
                  <p style={{ padding: '2px 5px 0 0' }}>
                    {'Affaire : '}
                  </p>
                  <p>
                    {labelFunctionState(devisEtatC)}
                  </p>
                </Grid.Row>
                {/* Commercial.e responsable du devis */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="male" />
                  <p style={{ paddingRight: '3px' }}>
                    Commercial.e :
                  </p>
                  <b>
                    {convertedName}
                  </b>
                </Grid.Row>
                {/* Type du devis */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="target" />
                  <p style={{ paddingRight: '3px' }}>
                    Devis type :
                  </p>
                  <b>
                    {!handleErrorData(devisTypeC) ? devisTypeC : 'Non renseigné'}
                  </b>
                </Grid.Row>
                {/* PDF du devis */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="file pdf" />
                  <p style={{ paddingRight: '5px' }}>
                    Devis :
                  </p>
                  <Button
                    color={!_.isEmpty(devisPdfUrlC) ? 'blue' : 'red'}
                    icon="file alternate outline"
                    href={!_.isEmpty(devisPdfUrlC) ? devisPdfUrlC : null}
                    target="_blank"
                    basic
                  />
                </Grid.Row>
              </Grid>
            </Modal.Content>
            {/* Bouton pour quitter la Modal */}
            <Modal.Actions>
              <Button
                positive
                content="Fermer"
                icon="close"
                size="tiny"
                onClick={() => setOpen(false)}
              />
            </Modal.Actions>
          </Modal>
        ) : null}
      </ListeTable.Cell>
      {/* Valeur pour "Total avec Option" */}
      <ListeTable.Cell
        name="devisTotalAvecOptionC"
        style={handleErrorData(totalEstimate) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
        textAlign="right"
      >
        {totalEstimate}
      </ListeTable.Cell>
      {/* Valeur pour "Affaire" */}
      <ListeTable.Cell
        name="devisEtatC"
        style={handleErrorData(devisEtatC) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
        textAlign="center"
        verticalAlign="middle"
      >
        <Popup
          content={(
            <>
              <p>
                L&apos;affaire est :
              </p>
              <p style={{ textAlign: 'center' }}>
                {labelFunctionState(devisEtatC)}
              </p>
            </>
          )}
          trigger={labelFunctionState(devisEtatC)}
          position="top center"
        />
      </ListeTable.Cell>
      {/* Valeur pour "Commercial.e" */}
      <ListeTable.Cell
        name="devisCommercialC"
        style={handleErrorData(devisCommercialC) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {convertedName}
      </ListeTable.Cell>
      {/* Valeur pour "Devis type" */}
      <ListeTable.Cell
        name="devisTypeC"
        style={handleErrorData(devisTypeC) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold', wordWrap: 'break-word' }}
      >
        {!handleErrorData(devisTypeC) ? (
          <Popup
            content={devisTypeC}
            trigger={(
              <div>
                {devisTypeC}
              </div>
            )}
            position="top center"
          />
        ) : 'Non renseigné'}
      </ListeTable.Cell>
      {/* Valeur pour "PDF du devis" */}
      <ListeTable.Cell
        name="devisPdfUrlC"
        style={_.isEmpty(devisCommercialC) ? { backgroundColor: '#e3e3e3' } : null}
        textAlign="center"
        verticalAlign="middle"
      >
        <Button
          color={!_.isEmpty(devisPdfUrlC) ? 'blue' : 'red'}
          icon="file alternate outline"
          href={!_.isEmpty(devisPdfUrlC) ? devisPdfUrlC : null}
          target="_blank"
          basic
        />
      </ListeTable.Cell>
    </ListeTable.Row>
  );
};

/** Props venant de EstimatesList */
EstimatesRow.propTypes = {
  // Informations d'un devis
  // eslint-disable-next-line
  item: PropTypes.object,
};

/** Types par défault des props */
EstimatesRow.defaultProps = {
  item: {},
};

export default EstimatesRow;
