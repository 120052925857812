/**
 * @brief Récupérer les données de l'api form 360
 */

const uri = `${process.env.REACT_APP_API_URL}`;

/** Route vers les données du formulaire d'un client */
export const getFormDataByCustomerId = () => `${uri}/api/form/sugar`;

export const getReportDataByCustomerAndFormId = (form) => `${uri}/api/form/get/${form}`;

export const getReportsList = (idKnote) => `${uri}/api/reports/list/${idKnote}`;
