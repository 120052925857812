/**
 * @brief Conteneur des informations d'un client
*/
import _ from 'lodash';
import React from 'react';

import {
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router';

import { Fiche } from '@jvs-group/jvs-mairistem-fiche';
import { Label, Button } from '@jvs-group/jvs-mairistem-composants';

import {
  PanelInformations,
  ConctactsList,
  MeetingsList,
  EstimatesList,
  // PanelSharePoint,
  NovaTimeList,
  ComponentsPanel,
  KeywordsList,
  TableSerializator,
  CasesTabs,
  SuivisLists,
  ElearningUsersList,
} from '../Panels/Customer';

import { LogContext, RequestContext } from '../Context';

import CustomersListe from '../Liste/CustomersListe';
import {
  getContacts,
  getMeetings,
  getEstimates,
  getCustomers,
  getCustomerAndCards,
  getCustomerName,
  getLeads,
  setTrace,
  getComponents,
  getKeywords,
  getSerializerData,
  getCustomerByIdKnote,
  getCustomerByIdSugar,
  getCases,
  getMicSuivis,
  getMicElearningUsers,
} from '../../routes';
import { convertKeysToCamelCase, buildUri, useAuthRequest } from '../../utils';
import { HistoryList } from '../Panels';
// import PanelFormCustomer from '../Panels/Customer/PanelFormCustomer';
import LeadsList from '../Liste/LeadsList';

const FicheCustomerConsumer = () => {
  /** Token Sugar */
  // TODO: Etat pour le token novaTime
  /** Token novaTime */
  // const [tokenNovatime, setTokenNovatime] = React.useState();
  /** Booléen pour le chargement des données */
  const [loading, setLoading] = React.useState(true);
  /** Nombre total de chargement fini pour afficher la page */
  // const [totalLoading, setTotalLoading] = React.useState(0);
  /** Informations d'un client */
  const [customer, setCustomer] = React.useState();
  /** Contacts d'un client */
  const [contacts, setContacts] = React.useState([]);
  /** Rendez-vous d'un client */
  const [meetings, setMeetings] = React.useState([]);
  /** Devis d'un client */
  const [estimates, setEstimates] = React.useState([]);
  /** Cartes NovaTime d'un client */
  const [novaTimeCards, setNovaTimeCards] = React.useState([]);
  /** Cartes novaTime réalisé ou terminé */
  const [novaTimeCardsDone, setNovaTimeCardsDone] = React.useState([]);
  /** Cartes novaTime d'intervention technique */
  const [novaTimeCardsTechnicalWorks, setNovaTimeCardsTechnicalWorks] = React.useState([]);
  /** Leads sugar */
  const [leads, setLeads] = React.useState([]);
  /** Composants jvs */
  const [componentsJvs, setComponentsJvs] = React.useState([]);
  /** Composants omega */
  const [componentsOmega, setComponentsOmega] = React.useState([]);
  /** Environnement bdd oracle */
  const [oracleEnvironment, setOracleEnvironment] = React.useState('');
  /* Nom composants jvs */
  const [nameComponentsJvs, setNameComponentsJvs] = React.useState('');
  /* Nom composants omega */
  const [nameComponentsOmega, setNameComponentsOmega] = React.useState('');
  /** Mots clés */
  const [keywords, setKeywords] = React.useState([]);
  /** Données du sérialisateur */
  const [serializerData, setSerializerData] = React.useState({});
  /** Tickets zendesk */
  const [cases, setCases] = React.useState([]);
  /** formulaires mic du client */
  const [suivis, setSuivis] = React.useState([]);
  /** Utilisateurs E-learning */
  const [elearningUsers, setElearningUsers] = React.useState([]);

  /** Check de l'authentification du sso */
  const { prepareRequest } = React.useContext(RequestContext);

  const { userPrincipalName } = React.useContext(LogContext);

  /** Identifiant */
  const params = useParams();

  const request = useAuthRequest();

  /** Identifiant du client depuis l'url. */
  const { identifiant } = useParams();

  const [idKnoteC, setIdKnoteC] = React.useState(identifiant);

  // Identifiant Sugar
  const [idSugar, setIdSugar] = React.useState();

  // Récupération de l'idSugar
  React.useEffect(() => {
    prepareRequest(() => request({
      method: 'POST',
      url: getCustomerByIdKnote(),
      data: {
        externalId: identifiant,
      },
    }).then((res) => {
      // si on ne récupère pas le client avec l'id knote on appel avec l'id sugar
      if (res.customer === null) {
        prepareRequest(() => request({
          method: 'POST',
          url: getCustomerByIdSugar(),
          data: {
            externalId: identifiant,
          },
        }).then((secRes) => {
          // Affectation des identifiants idKnoteC et idSugar
          setIdKnoteC(secRes.customer.id_knote_c);
          setIdSugar(identifiant);
          // Récupération du client
          setCustomer((convertKeysToCamelCase(secRes.customer)));
        }));
      } else {
        // Affectation des identifiants idKnoteC et idSugar
        setIdSugar(res.customer.id);
        // Récupération du client
        setCustomer((convertKeysToCamelCase(res.customer)));
      }
    }));
  }, [identifiant]);

  /** Décompositions des informations du client */
  const {
    name,
    statutCompteC,
  } = customer || {};

  /** Nombre total d'éléments à charger */
  // const totalElements = 13;

  // TODO: -------------------- Début historique ------------------------
  // Toutes les variables/fonctions/états etc... présents dans ce bloc
  // sont pour l'utilisation de la barre latérale
  // Ces données seront sûrement à supprimer lors de la mise en place de l'historique dans le back
  // Toutes ces fonctions sont également des copies provenant de CustomersContainer principalement

  /** Historique de consultations des clients */
  const [searchHistory, setSearchHistory] = React.useState([]);

  /** Tableau de l'ebsemble des clients */
  const [customers, setCustomers] = React.useState([]);

  /** Booléen pour le chargement de l'historique */
  const [loadingHistory, setLoadingHistory] = React.useState(false);

  /** Nom sharepoint du client */
  const [sharepointCustomerName, setSharepointCustomerName] = React.useState();

  /** Historique pour la récupérations des informations */
  const history = useHistory();
  /** Route de la où on est */
  const { path } = useRouteMatch();
  // Cette variable sert à changer le format des données à afficher
  const formattedCustomers = React.useMemo(() => _.map(customers, (customerParam) => ({
    identifiant: customerParam.id,
    ..._.omit(customerParam, 'id'),
  })), [customers]);

  /** Ajout d'un client consulté à l'historique */
  const changeSearchHistory = React.useCallback((customerParam) => {
    let found = false;

    // Vérification que le client à ajouter à l'historique n'y est pas déjà présent
    for (let i = 0; i < searchHistory.length; i += 1) {
      if (searchHistory[i].identifiant === customerParam.identifiant) {
        found = true;

        // Remise à la 1ère place de l'élément déjà dans l'historique
        setSearchHistory(searchHistory.splice(i, 1));
        setSearchHistory(searchHistory.splice(0, 0, customerParam));
      }
    }

    // Condition pour ajouter le client à l'historique si il n'y est pas présent
    if (!found) {
      // Ajout du client consulté à l'historique
      setSearchHistory(searchHistory.splice(0, 0, customerParam));
      // Suppression du surplus de client
      if (searchHistory.length > 5) {
        setSearchHistory(searchHistory.splice(5, 1));
      }
    }

    // Transformation du state en JSON et stockage en local
    window.localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
  }, [searchHistory]);

  /** Redirige vers a fiche client à la sélection d'une ligne. */
  const handleSelect = React.useCallback((selectedItem) => {
    // Création de l'URL vers les informations du client
    const nextPath = buildUri(path, { ...params, ...selectedItem });

    // Vérification si le chemin est différent de celui actuel
    if (history.location.pathname !== nextPath) {
      // Ajoute l'élément cliqué à l'historique
      changeSearchHistory(selectedItem);

      const datas = JSON.stringify(
        {
          data:
          {

            name: 'ficheclient.historique',
            data: {
              client: {
                id: selectedItem.identifiant,
                denomination: selectedItem.name,
              },
              username: userPrincipalName,
            },
            tagsTrace: ['ficheclient.historique'],
          },

        },
      );
      request({
        method: 'POST',
        url: setTrace(),
        data: datas,
      }).catch((error) => {
        // eslint-disable-next-line
        console.error('erreur setTrace', error);
      });

      // Envoie de l'URL avec des données dans state
      history.push({
        pathname: nextPath,
        state: { items: _.isNil(formattedCustomers) ? customers : formattedCustomers },
      });
    }
  }, [path, history, params, formattedCustomers, customers]);

  /** Récupération des valeurs dans le stockage local (TODO: supprimer ?) */
  React.useEffect(() => {
    // Début du chargement
    setLoadingHistory(true);

    // Récupération du JSON dans le stockage local
    const localHistory = window.localStorage.getItem('searchHistory');
    // Affectation de la valeur si elle n'est pas null
    if (localHistory !== null) {
      setSearchHistory(JSON.parse(localHistory));
    }
    // Fin du chargement
    setLoadingHistory(false);
  }, [customer]);

  /** Récupération des tous les clients */
  React.useEffect(() => {
    prepareRequest(() => request({
      method: 'GET',
      url: getCustomers(),
    }).then((response) => {
      // Récupération de tous les clients
      setCustomers(_.values(convertKeysToCamelCase(response.customers)));
    }).catch((error) => {
      // eslint-disable-next-line
      console.log('catch erreur getCustomers', error);
    }));
  }, [customer]);

  // TODO: ------------------ Fin Historique ------------------------

  /** Fonction pour rediriger vers l'accueil */
  const handleBack = React.useCallback(() => {
    // Création de l'URL vers la liste des clients
    const nextPath = buildUri(path);

    // Vérification si le chemin est différent de celui actuel
    if (history.location.pathname !== nextPath) {
      // Envoie de l'URL
      history.push({ pathname: nextPath });
    }
  }, [path, history]);

  //! Récupération des cartes novaTime
  React.useEffect(() => {
    // Requête
    // Récupération des cartes NovaTime du client qui ne SONT PAS FINIES avec son identifiant knote
    if (!_.isEmpty(idKnoteC)) {
      setLoading(true);

      prepareRequest(() => request({
        method: 'POST',
        url: getCustomerAndCards(),
        data: { externalId: idKnoteC, done: false, technicalWork: false },
      }).then((response) => {
        setNovaTimeCards(_.values(convertKeysToCamelCase(response.cards)));
        // Ajout des cartes d'intervention techniques dans novaTime
        setNovaTimeCardsTechnicalWorks(
          _.values(convertKeysToCamelCase(response.technicalWorks.data)),
        );
      }).catch((error) => {
        // eslint-disable-next-line
        console.log('catch erreur getCards', error);
      }).finally(() => {
        // Fin du chargement
        // setTotalLoading(totalLoading + 1);
        setLoading(false);
      }));
    }
  }, [idKnoteC]);

  //! Récupération des cartes novaTime réalisé ou terminé

  React.useEffect(() => {
    // Requête
    // Récupération des cartes novaTime terminées du client avec son identifiant knote
    setLoading(true);

    prepareRequest(() => request({
      method: 'POST',
      url: getCustomerAndCards(),
      data: { externalId: idKnoteC, done: true, technicalWork: false },
    }).then((response) => {
      setNovaTimeCardsDone(_.values(convertKeysToCamelCase(response.cards)));
    }).catch((error) => {
      // eslint-disable-next-line
      console.log('catch erreur getCards', error);
    }).finally(() => {
      // Fin du chargement
      // setTotalLoading(totalLoading + 1);
      setLoading(false);
    }));
  }, [idKnoteC]);

  React.useEffect(() => {
    //! Appel api Sharepoint

    prepareRequest(() => request({
      method: 'POST',
      url: getCustomerName(),
      data: { idKnote: idKnoteC },
    }).then((response) => {
      setSharepointCustomerName(response.name);
    }).catch((error) => {
      // eslint-disable-next-line
      console.log('erreur getSharePoint', error);
    }).finally(() => {
      // Fin du chargement
      // setTotalLoading(totalLoading + 1);
      setLoading(false);
    }));
  }, [idKnoteC]);

  // Récupération du client
  React.useEffect(() => {
    if (!_.isEmpty(idKnoteC)) {
      // Mise en chargement
      setLoading(true);
      // Récupération des composants JVS du client
      prepareRequest(() => request({
        method: 'POST',
        url: getComponents(),
        data: { externalId: idKnoteC, isOmega: false },
      }).then((response) => {
        setComponentsJvs(_.values(convertKeysToCamelCase(response.datas)));
        setNameComponentsJvs(response.name);
        if (!_.isEmpty(response.environment)) {
          setOracleEnvironment(response.environment);
        }
      }).catch((error) => {
        // eslint-disable-next-line
        console.log('erreur getComponents JVS', error);
      }).finally(() => {
        // Fin du chargement
        setLoading(false);
      }));

      // Récupération des composants Omega du client
      prepareRequest(() => request({
        method: 'POST',
        url: getComponents(),
        data: { externalId: idKnoteC, isOmega: true },
      }).then((response) => {
        setComponentsOmega(_.values(convertKeysToCamelCase(response.datas)));
        setNameComponentsOmega(response.name);
        if (!_.isEmpty(response.environment)) {
          setOracleEnvironment(response.environment);
        }
      }).catch((error) => {
        // eslint-disable-next-line
        console.log('erreur getComponents Omega', error);
      }).finally(() => {
        // Fin du chargement
        setLoading(false);
      }));

      // Récupération des données du sérialisateur
      prepareRequest(() => request({
        method: 'POST',
        url: getSerializerData(),
        data: { idKnote: idKnoteC },
      }).then((response) => {
        setSerializerData(_.values(convertKeysToCamelCase([response])));
      }).catch((error) => {
        // eslint-disable-next-line
        console.log('erreur getSerializerData', error);
      }).finally(() => {
        // Fin du chargement
        setLoading(false);
      }));

      // Récupération des formulaires du mic
      prepareRequest(() => request({
        method: 'POST',
        url: getMicSuivis(),
        data: { externalId: idKnoteC },
      }).then((response) => {
        setSuivis(_.values(convertKeysToCamelCase(response)));
      }).catch((error) => {
        // eslint-disable-next-line
        console.log('erreur getMicSuivis', error);
      }).finally(() => {
        setLoading(false);
      }));

      // Récupération des utilisateurs e-learning
      prepareRequest(() => request({
        method: 'POST',
        url: getMicElearningUsers(),
        data: { externalId: idKnoteC },
      }).then((response) => {
        setElearningUsers(_.values(convertKeysToCamelCase(response)));
      }).catch((error) => {
        // eslint-disable-next-line
        console.log('erreur getMicElearningUsers', error);
      }).finally(() => {
        setLoading(false);
      }));
    }
  }, [idKnoteC]);

  // Récupération des informations du client depuis Sugar
  React.useEffect(() => {
    // Mise en chargement
    setLoading(true);
    // setTotalLoading(0);

    // Récupération des contacts du client avec son identifiant Sugar
    prepareRequest(() => request({
      method: 'POST',
      url: getContacts(),
      data: { externalId: idSugar },
    }).then((response) => {
      setContacts(_.values(convertKeysToCamelCase(response.contacts)));
    }).catch((error) => {
      // eslint-disable-next-line
      console.log('erreur getContacts', error);
    }).finally(() => {
      // Fin du chargement
      // setTotalLoading(totalLoading + 1);
      setLoading(false);
    }));

    // Récupération des rendez-vous du client avec son identifiant Sugar
    prepareRequest(() => request({
      method: 'POST',
      url: getMeetings(),
      data: { externalId: idSugar },
    }).then((response) => {
      setMeetings(_.values(convertKeysToCamelCase(response.meetings)));
    }).catch((error) => {
      // eslint-disable-next-line
      console.log('erreur getMeetings', error);
    }).finally(() => {
      // Fin du chargement
      // setTotalLoading(totalLoading + 1);
      setLoading(false);
    }));

    // Récupération des devis du client avec son identifiant Sugar
    prepareRequest(() => request({
      method: 'POST',
      url: getEstimates(),
      data: { externalId: idSugar },
    }).then((response) => {
      setEstimates(_.values(convertKeysToCamelCase(response.leads)));
    }).catch((error) => {
      // eslint-disable-next-line
      console.log('erreur getEstimates', error);
    }).finally(() => {
      // Fin du chargement
      // setTotalLoading(totalLoading + 1);
      setLoading(false);
    }));

    // Récupération des leads du client avec son identifiant Sugar
    prepareRequest(() => request({
      method: 'POST',
      url: getLeads(),
      data: { externalId: idSugar },
    }).then((response) => {
      setLeads(_.values(convertKeysToCamelCase(response.leads)));
    }).catch((error) => {
      // eslint-disable-next-line
      console.log('erreur getLeads', error);
    }).finally(() => {
      // Fin du chargement
      // setTotalLoading(totalLoading + 1);
      setLoading(false);
    }));

    // Récupération des mots clés sugar du client
    prepareRequest(() => request({
      method: 'POST',
      url: getKeywords(),
      data: { externalId: idSugar },
    }).then((response) => {
      setKeywords(_.values(convertKeysToCamelCase(response)));
    }).catch((error) => {
      // eslint-disable-next-line
      console.log('erreur getKeywords', error);
    }).finally(() => {
      // Fin du chargement
      // setTotalLoading(totalLoading + 1);
      setLoading(false);
    }));

    // Récupération des tickets zendesk
    prepareRequest(() => request({
      method: 'POST',
      url: getCases(),
      data: { externalId: idSugar },
    }).then((response) => {
      setCases(_.values(convertKeysToCamelCase(response)));
    }).catch((error) => {
      // eslint-disable-next-line
      console.log('erreur getCases', error);
    }).finally(() => {
      // Fin du chargement
      // setTotalLoading(totalLoading + 1);
    }));
  }, [idSugar]);

  // Changement de l'affichage des contacts
  const formattedContacts = React.useMemo(() => _.map(contacts, (contact) => ({
    identifiant: contact.id,
    ..._.omit(contact, 'id'),
  })), [contacts]);

  // Changement de l'affichage des rendez-vous
  const formattedMeetings = React.useMemo(() => _.map(meetings, (meet) => ({
    identifiant: meet.id,
    ..._.omit(meet, 'id'),
  })), [meetings]);

  // Changement de l'affichage des devis
  const formattedEstimates = React.useMemo(() => _.map(estimates, (estimate) => ({
    identifiant: estimate.id,
    ..._.omit(estimate, 'id'),
  })), [estimates]);

  const formattedNovatimeCards = React.useMemo(() => _.map(novaTimeCards, (card) => ({
    identifiant: card.id,
    ..._.omit(card, 'id'),
  })), [novaTimeCards]);

  const formattedNovatimeCardsDone = React.useMemo(() => _.map(novaTimeCardsDone, (card) => ({
    identifiant: card.id,
    ..._.omit(card, 'id'),
  })), [novaTimeCardsDone]);

  const formattedNovatimeCardsTechnicalWorks = React.useMemo(() => _.map(
    novaTimeCardsTechnicalWorks,
    (card) => ({
      identifiant: card.id,
      ..._.omit(card, 'id'),
    }),
  ), [novaTimeCardsTechnicalWorks]);

  const formattedLeads = React.useMemo(() => _.map(
    leads,
    (lead) => ({
      identifiant: lead.id,
      ..._.omit(lead, 'id'),
    }),
  ), [leads]);

  const formattedComponentsJvs = React.useMemo(() => _.map(
    componentsJvs,
    (componentJvs) => ({
      identifiant: componentJvs.id,
      ..._.omit(componentJvs, 'id'),
    }),
  ), [componentsJvs]);

  const formattedComponentsOmega = React.useMemo(() => _.map(
    componentsOmega,
    (componentOmega) => ({
      identifiant: componentOmega.id,
      ..._.omit(componentOmega, 'id'),
    }),
  ), [componentsOmega]);

  const formattedSerializerData = React.useMemo(() => _.map(
    serializerData,
    (data) => ({
      identifiant: data.id,
      ..._.omit(data, 'id'),
    }),
  ), [serializerData]);

  const formattedCases = React.useMemo(() => _.map(
    cases,
    (caseData) => ({
      identifiant: caseData.id,
      ..._.omit(caseData, 'id'),
    }),
  ), [cases]);

  const formattedSuivis = React.useMemo(() => _.map(
    suivis,
    (suivi) => ({
      identifiant: suivi?.id_SA,
      ..._.omit(suivi, 'id_SA'),
    }),
  ), [suivis]);

  const formattedElearningUsers = React.useMemo(() => _.map(
    elearningUsers,
    (user) => ({
      identifiant: user.id,
      ..._.omit(user, 'id'),
    }),
  ), [elearningUsers]);

  // Résumé des informations du client
  const informations = React.useMemo(() => (
    <Fiche.Panel
      // Clé unique du Panel
      key="informations"
      // Identifiant unique du Panel
      id="informations"
      // Nom dans le tab haut-droit
      title="Informations"
      // Fermeture de l'onglet
      open="true"
    >
      {/* Création du Panel avec toutes les informations qu'il utilisera */}
      <PanelInformations
        customer={customer}
        idKnote={idKnoteC}
        customerName={sharepointCustomerName}
      />
    </Fiche.Panel>
  ), [customer]);

  // Partie "Contacts"
  const clientContacts = React.useMemo(() => (
    <Fiche.Panel
      // Clé unique du Panel
      key="contacts"
      // Identifiant unique du Panel
      id="contacts"
      // Nom dans le tab haut-droit
      title={contacts.length === 1 || contacts.length === 0 ? `Contact (${contacts.length})` : `Contacts (${contacts.length})`}
      // Fermeture de l'onglet
      open={false}
    >
      {/* Création du Panel avec toutes les informations qu'il utilisera */}
      <ConctactsList contacts={formattedContacts} />
    </Fiche.Panel>
  ), [contacts]);

  // Partie "Rendez-vous"
  const clientMeetings = React.useMemo(() => (
    <Fiche.Panel
      // Clé unique du Panel
      key="meetings"
      // Identifiant unique du Panel
      id="meetings"
      // Nom dans le tab haut-droit
      title={`RDV commercial (${meetings.length})`}
      // Fermeture de l'onglet
      open={false}
    >
      <MeetingsList meetings={formattedMeetings} />
    </Fiche.Panel>
  ), [meetings]);

  // Partie "Devis"
  const clientEstimates = React.useMemo(() => (
    <Fiche.Panel
      // Clé unique du Panel
      key="estimates"
      // Identifiant unique du Panel
      id="estimates"
      // Nom dans le tab haud-droit
      title={`Devis (${estimates.length})`}
      // Fermeture de l'onglet
      open={false}
    >
      <EstimatesList estimates={formattedEstimates} />
    </Fiche.Panel>
  ), [estimates]);

  // Partie "NovaTime"
  const novaTime = React.useMemo(() => (
    <Fiche.Panel
      key="novatime"
      id="novaTime"
      title={`Formations en cours (${novaTimeCards.length}) `}
      open={false}
    >

      <NovaTimeList novaTimeCards={formattedNovatimeCards} cardType="novaTime" />
    </Fiche.Panel>
  ), [novaTimeCards]);
  // Partie novaTimeOnGoingAndRealisedList
  const novaTimeCardsDonePanel = React.useMemo(() => (
    <Fiche.Panel
      key="novaTimeCardsDone"
      id="novaTimeCardsDone"
      title={`Formations réalisées (${novaTimeCardsDone.length}) `}
      open={false}
    >
      <NovaTimeList novaTimeCards={formattedNovatimeCardsDone} cardType="novaTimeCardsDone" />
    </Fiche.Panel>
  ), [novaTimeCardsDone]);

  // Partie interventions techniques novaTime
  const novaTimeTechnicalSupport = React.useMemo(() => (
    <Fiche.Panel
      key="novaTimeTechnicalSupport"
      id="novaTimeTechnicalSupport"
      title={`Interventions techniques (${formattedNovatimeCardsTechnicalWorks.length}) `}
      open={false}
    >

      <NovaTimeList novaTimeCards={formattedNovatimeCardsTechnicalWorks} cardType="novaTimeTechnicalSupport" />
    </Fiche.Panel>
  ), [novaTimeCards]);

  // Partie "SharePoint"
  // const sharePoint = React.useMemo(() => (
  //   <Fiche.Panel
  //     // Clé unique du Panel
  //     key="sharepoint"
  //     // Identifiant unique du Panel
  //     id="sharePoint"
  //     // Nom dans le tab haut-droit
  //     title="Documents"
  //     open={false}
  //   >
  //     <PanelSharePoint idKnote={idKnoteC} customerName={sharepointCustomerName} />
  //   </Fiche.Panel>
  // ), [sharepointCustomerName]);

  // const formCustomer = React.useMemo(() => (
  //   <Fiche.Panel
  //     // Clé unique du Panel
  //     key="formCustomer"
  //     // Identifiant unique du Panel
  //     id="formCustomer"
  //     // Nom dans le tab haut-droit
  //     title="Anomalies"
  //     open={false}
  //   >
  //     <PanelFormCustomer idKnote={idKnoteC} />
  //   </Fiche.Panel>
  // ), [idKnoteC]);

  const clientComponents = React.useMemo(() => (
    <Fiche.Panel
      // Clé unique du Panel
      key="components"
      // Identifiant unique du Panel
      id="components"
      // Nom dans le tab haut-droit
      title={`CIM (${formattedComponentsJvs.length + formattedComponentsOmega.length})`}
      open={false}
    >
      <ComponentsPanel
        nameComponentsJvs={nameComponentsJvs}
        nameComponentsOmega={nameComponentsOmega}
        componentsJvs={formattedComponentsJvs}
        componentOmega={formattedComponentsOmega}
        oracleEnvironment={oracleEnvironment}
      />
    </Fiche.Panel>
  ), [identifiant, formattedComponentsJvs, formattedComponentsOmega]);

  // Partie en haut de la page des informations du client
  const header = React.useMemo(() => {
    // Nom du client
    let res = name;

    // Ajout de l'IdKNOTE si il existe
    if (!_.isEmpty(idKnoteC)) {
      res += ` - ${idKnoteC}`;
    } else {
      res += ` - ${idSugar}`;
    }

    // Ajout des informations dans la partie haute de la page
    return (
      <>
        {/* Nom + IdKNOTE */}
        {res}
        {/* Statut du compte (client ou prospect) si il existe */}
        {statutCompteC && _.lowerCase(statutCompteC) === 'client' && (
          <Label horizontal style={{ marginLeft: 10 }} color="green" content={statutCompteC.toUpperCase()} />
        )}
        {statutCompteC && _.lowerCase(statutCompteC) === 'prospect' && (
          <Label horizontal style={{ marginLeft: 10 }} color="blue" content={statutCompteC.toUpperCase()} />
        )}
      </>
    );
  }, [name, idKnoteC, statutCompteC]);

  const clientLeads = React.useMemo(() => (
    <Fiche.Panel
      // Clé unique du Panel
      key="leads"
      // Identifiant unique du Panel
      id="leads"
      // Nom dans le tab haut-droit
      title={`Leads (${formattedLeads.length})`}
      open={false}
    >
      <LeadsList leads={formattedLeads} />
      <Button
        content="Ajouter un lead"
        icon="plus"
        style={{ marginTop: '1rem' }}
        className={_.isUndefined(formattedLeads) ? 'disabled' : ''}
        href={`https://webtolead.jvsonline.fr/interne?idknote=${idKnoteC}`}
        target="_blank"
        primary
        fluid
      />
    </Fiche.Panel>
  ), [formattedLeads]);

  const clientKeywords = React.useMemo(() => (
    <Fiche.Panel
      // Clé unique du Panel
      key="keywords"
      // Identifiant unique du Panel
      id="keywords"
      // Nom dans le tab haut-droit
      title="Mots-clés"
      open={false}
    >
      <KeywordsList keywords={keywords} />
    </Fiche.Panel>
  ), [keywords]);

  const serializer = React.useMemo(() => (
    <Fiche.Panel
      // Clé unique du Panel
      key="serializer"
      // Identifiant unique du Panel
      id="serializer"
      // Nom dans le tab haut-droit
      title="Sérialisateur"
      open={false}
    >
      <TableSerializator data={formattedSerializerData[0]} />
    </Fiche.Panel>
  ), [formattedSerializerData]);

  const clientCases = React.useMemo(() => (
    <Fiche.Panel
      // Clé unique du Panel
      key="cases"
      // Identifiant unique du Panel
      id="cases"
      // Nom dans le tab haut-droit
      title={`Zendesk (${formattedCases.length})`}
      open={false}
    >
      <CasesTabs cases={formattedCases} />
    </Fiche.Panel>
  ));

  const clientSuivis = React.useMemo(() => (
    <Fiche.Panel
      // Clé unique du Panel
      key="suivis"
      // Identifiant unique du Panel
      id="suivis"
      // Nom dans le tab haut-droit
      title="Formulaires"
      open={false}
    >
      <SuivisLists suivis={formattedSuivis} />
    </Fiche.Panel>
  ), [formattedSuivis]);

  const clientElearningUsers = React.useMemo(() => (
    <Fiche.Panel
      // Clé unique du Panel
      key="elearningUsers"
      // Identifiant unique du Panel
      id="elearningUsers"
      // Nom dans le tab haut-droit
      title={`Accès E-Learning (${formattedElearningUsers.length})`}
      open={false}
    >
      <ElearningUsersList users={formattedElearningUsers} />
    </Fiche.Panel>
  ), [formattedElearningUsers]);

  return (
    <Fiche
      // Clé unique de la fiche
      key="FicheCustomerConsumer"
      // Informations dans la barre en haut de la page
      header={header}
      // Couleur des boutons qui seront potentiellement ajoutés
      color="blue"
      // Booléen pour le chargement des données
      loading={loading}
      // Barre sur le côté qui prendra CustomersListe
      sidebars={{
        left: (
          <>
            {/* Bouton pour revenir à l'accueil */}
            <Button
              // Texte dans le bouton
              content="Retour à l'accueil"
              // Icon dans le bouton
              icon="angle left"
              // Style
              compact
              basic
              style={{ margin: '15px 15px 0 15px', width: 200 }}
              floated="left"
              // Fonction lors du clic
              onClick={handleBack}
            />
            {/* Historique personnel */}
            <HistoryList
              searchHistory={searchHistory}
              loading={loadingHistory}
              handleSelect={handleSelect}
              inSidebar
              identifiant={identifiant}
            />
            {/* Liste de tous les clients */}
            <CustomersListe
              expands
              handleSelect={handleSelect}
            />
          </>
        ),
      }}
    >
      {/* Résumé des informations du client */}
      {informations}
      {/* Contacts du client */}
      {clientContacts}
      {/* Rendez-vous du client */}
      {clientMeetings}
      {/* Affichage des Leads */}
      {clientLeads}
      {/* Devis du client */}
      {clientEstimates}
      {/* Bouton vers SharePoint */}
      {/* TODO: A enlever pour la prépod (non fonctionnel) */}
      {/* {!_.isEmpty(idKnoteC) && sharePoint} */}
      {/* Affichage des projets du client */}
      {formattedSuivis.length > 0 && clientSuivis}
      {/* Affichage des composants du client */}
      {!_.isEmpty(idKnoteC) && clientComponents}
      {/* Affichage des mots clés du client */}
      {clientKeywords}
      {/* Affichage du sérialisateur */}
      {!_.isEmpty(idKnoteC) && serializer}
      {/* Affichage des utilisateurs e-learning du client */}
      {clientElearningUsers}
      {/* Cartes NovaTime du client */}
      {!_.isEmpty(idKnoteC) && novaTime}
      {/* Cartes NovaTime finies/terminées du client */}
      {!_.isEmpty(idKnoteC) && novaTimeCardsDonePanel}
      {/* Cartes novaTime des interventions techniques */}
      {!_.isEmpty(idKnoteC) && novaTimeTechnicalSupport}
      {/* Affichage des tickets zendesk */}
      {clientCases}
      {/* Panel des formulaires d'anomalies */}
      {/* {formCustomer} */}
    </Fiche>
  );
};

export default FicheCustomerConsumer;
