/**
 * @brief Liste de tous les clients
 */
import _ from 'lodash';
import React from 'react';

import {
  useLocation,
} from 'react-router';
import PropTypes from 'prop-types';

import {
  Header,
  Icon,
  Ref,
} from '@jvs-group/jvs-mairistem-composants';
import { ListeTable } from '@jvs-group/jvs-mairistem-liste';

import CustomersRow from './CustomersRow';

const CustomersListe = (props) => {
  // Décomposition des props
  const {
    items,
    loading,
    handleSelect,
  } = props;

  /** Largeur de la liste. */
  const [widthListe, setWidthListe] = React.useState(null);
  /** Tableau de tous les clients */
  const [customers, setCustomers] = React.useState([]);

  /** Location pour la récupération des états */
  const location = useLocation();

  /** TODO: Commentaires */
  const handleRef = (node) => {
    // TODO: Commentaires
    const resizeObserver = new ResizeObserver((entries) => {
      // Parcours de
      _.map(entries, (entry) => {
        // Vérification pour pouvoir récupérer la largeur
        if (entry.contentRect) {
          // Affectation de la largeur du tableau
          setWidthListe(entry.contentRect.width);
        }
      });
    });

    // TODO: Commentaires
    if (!_.isNil(node)) {
      resizeObserver.observe(node);
    }
  };

  // Prend les items qui se trouvent dans location
  // S'ils existent c'est qu'on vient d'une liste filtrée
  // Sinon prends la props items.
  React.useEffect(() => {
    if (!_.isNil(location?.state?.items)) {
      setCustomers(location?.state?.items);
    } else {
      setCustomers(items);
    }
  }, [items, location?.state?.items]);

  return (
    <>
      {/* TODO: Commentaires */}
      <Ref innerRef={handleRef}>
        {/* En-tête du cadre */}
        <Header>
          Résultat de la recherche
        </Header>
      </Ref>
      {/* Tableau contenant tous les clients */}
      <ListeTable
        // Taille du tableau
        count={_.size(customers)}
        // Informations du tableau
        items={customers}
        color="blue"
        // Affichage du chargement
        loading={loading}
        // Lorsqu'une ligne est cliquée
        onSelect={handleSelect}
      >
        {/* En-tête du tableau */}
        <ListeTable.Header>
          {/* Cellule "Nom du client" */}
          <ListeTable.Column name="name" width={3}>
            <Icon name="chevron right" />
            Nom du client
          </ListeTable.Column>
          {/* Cellule "IdKNOTE" */}
          <ListeTable.Column name="idKnote" width={2} sortable sort>
            <Icon name="lightbulb" />
            {`${widthListe < 400 ? 'Id.' : 'Identifiant'} KNOTE`}
          </ListeTable.Column>
          <ListeTable.Column
            name="siren"
            width={1}
            only={[
              'l',
              'xl',
            ]}
          >
            <Icon name="ticket" />
            SIREN
          </ListeTable.Column>
          {/* Cellule "Adresse professionnelle" */}
          <ListeTable.Column
            name="adresse"
            width={4}
            only={[
              'm',
              'l',
              'xl',
            ]}
          >
            <Icon name="home" />
            Adresse professionnelle
          </ListeTable.Column>
          {/* Cellule "Coordonnées professionnelles" */}
          <ListeTable.Column
            name="tel"
            width={3}
            only={[
              'l',
              'xl',
            ]}
          >
            <Icon name="phone" />
            Coordonnées professionnelles
          </ListeTable.Column>
          {/* Cellule "Chargé.e de clientèle" */}
          <ListeTable.Column
            name="trainer"
            width={3}
            only={[
              'xl',
              'l',
            ]}
          >
            <Icon name="book" />
            Commercial.e
          </ListeTable.Column>
        </ListeTable.Header>
        {/* Appel de CustomersRow pour chaque client */}
        {(item) => (
          <CustomersRow item={item} />
        )}
      </ListeTable>
    </>
  );
};

/** Props venant de FicheCustomerConsumer ou CustomersPanel */
CustomersListe.propTypes = {
  // Ensemble des clients de la recherche
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.arrayOf(PropTypes.object),
  // Booléen pour le chargement
  loading: PropTypes.bool,
};

/** Types par défault des props */
CustomersListe.defaultProps = {
  items: null,
  loading: false,
};

export default CustomersListe;
