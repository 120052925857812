/**
 * @brief Fichier regroupant tous les appels à l'API
 */

const url = `${process.env.REACT_APP_API_URL}/`;

/** Récupération d'un client avec son identifiant SUGAR */
export const getCustomerByIdSugar = () => `${url}api/sugar/customer/idsugar`;

/** Récupération d'un client avec son identifiant KNOTE */
export const getCustomerByIdKnote = () => `${url}api/sugar/customer/idknote`;

/** Récupération de tous les clients */
export const getCustomers = () => `${url}api/sugar/customers`;

/** Récupération de tous les clients qui entre dans les précisions de l'utilisateur */
export const getCustomersByFilters = () => `${url}api/sugar/customers/filters`;

/** Récupération des contacts d'un client par son identifiant Sugar */
export const getContacts = () => `${url}api/sugar/contacts`;

/** Récupération des rendez-vous d'un client par son identifiant Sugar */
export const getMeetings = () => `${url}api/sugar/meetings`;

/** Récupération des devis d'un client par son identifiant Sugar */
export const getEstimates = () => `${url}api/sugar/leads`;

/** Récupérer les leads d'un client */
export const getLeads = () => `${url}api/sugar/leadssugar`;

/** Récupérer les mots clés d'un client */
export const getKeywords = () => `${url}api/sugar/keywords`;

/** Récupérer les tickets zendesk d'un client */
export const getCases = () => `${url}api/sugar/cases`;
