/**
 * @brief Liste des composants d'un client
 */
import React from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { ListeTable } from '@jvs-group/jvs-mairistem-liste';
import { Icon } from '@jvs-group/jvs-mairistem-composants';
import ComponentsRow from './ComponentsRow';

import { tableSort } from '../../Functions/Functions';

const ComponentsList = (props) => {
  const { components, isOmega } = props;

  /** Ensemble des états que peut prendre une cellule en-tête d'un tableau */
  const [state, dispatch] = React.useReducer(tableSort, {
    column: null,
    data: components,
    direction: null,
  });

  // Décomposition de state
  const { column, data, direction } = state;

  /** Changement de la valeur data avec les nouveaux composants */
  React.useEffect(() => {
    dispatch({ type: 'CHANGE_CUSTOMER', data: components });
  }, [components]);

  return (
    <div style={!_.isEmpty(data) ? { height: `${data.length * 45 + 47}px`, maxHeight: '800px' } : { height: '300px', maxHeight: '800px' }}>
      {/* Tableau des composants du client */}
      <ListeTable
        count={_.size(data)}
        // Données directement dans le tableau
        items={data}
        color="blue"
        selection="click"
        style={{ overflowY: 'auto' }}
      >
        {/* En-tête du tableau */}
        <ListeTable.Header>
          {/* Cellule "Composant" */}
          <ListeTable.Column
            name="Name"
            width={4}
            // Triage du tableau
            sorted={column === 'Name' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'Name' })}
          >
            <Icon name="angle right" />
            Composant
          </ListeTable.Column>
          {/* Cellule "Utilisateur final" */}
          <ListeTable.Column
            name="FinalUser"
            width={2}
            // Triage du tableau
            sorted={column === 'FinalUser' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'FinalUser' })}
          >
            <Icon name="angle right" />
            Utilisateur final
          </ListeTable.Column>
          {/* Cellule "Référent partenaire" */}
          <ListeTable.Column
            name="PartnerUser"
            width={2}
            // Triage du tableau
            sorted={column === 'PartnerUser' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'PartnerUser' })}
          >
            <Icon name="angle right" />
            Référent partenaire
          </ListeTable.Column>
          {/* Cellule "Référent client" */}
          <ListeTable.Column
            name="ClientUser"
            width={2}
            // Triage du tableau
            sorted={column === 'ClientUser' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'ClientUser' })}
          >
            <Icon name="angle right" />
            Référent client
          </ListeTable.Column>
          {/* Cellule "Autres" */}
          <ListeTable.Column
            name="OtherUser"
            width={2}
            // Triage du tableau
            sorted={column === 'OtherUser' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'OtherUser' })}
          >
            <Icon name="angle right" />
            Autres
          </ListeTable.Column>
          {/* Cellule "informations" pour la modale */}
          <ListeTable.Column
            name="Info"
            width={2}
          >
            Informations
          </ListeTable.Column>
        </ListeTable.Header>

        {/* Contenu du tableau */}
        {(item) => (
          <ComponentsRow item={item} isOmega={isOmega} />
        )}

      </ListeTable>

    </div>
  );
};

/** Props venant de FicheCustomerConsumer */
ComponentsList.propTypes = {
  // Ensemble des composants du client
  // eslint-disable-next-line
  components: PropTypes.arrayOf(PropTypes.object),
  isOmega: PropTypes.bool,
};

/** Types par défault des props */
ComponentsList.defaultProps = {
  components: [],
  isOmega: false,
};

export default ComponentsList;
