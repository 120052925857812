/**
 * @brief ligne d'un utilisateur e-learning d'un client
 */
import React from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { ListeTable } from '@jvs-group/jvs-mairistem-liste';
import {
  Button, Grid, GridColumn, List, ListItem, ListList, Modal,
} from '@jvs-group/jvs-mairistem-composants';
import { handleErrorData } from '../../Functions/Functions';
import { FormattedDate } from '../../Functions';

const ElearningUsersRow = (props) => {
  // Décomposition des props
  const {
    item,
  } = props;

  // Décomposition de item
  const {
    // Identifiant de la ligne du tableau
    identifiant,
    // Nom de famille de l'utilisateur
    lastName,
    // Prénom de l'utilisateur
    firstName,
    // E-mail de l'utilisateur
    mail,
    // Dernière date de connexion de l'utilisateur
    lastLoginAt,
    // Groupe de l'utilisateur
    groups,
    // Champs personnalisés
    customFields,
  } = item || {};

  const [open, setOpen] = React.useState(false);

  return (
  // Ligne du tableau
    <ListeTable.Row key={identifiant}>
      {/* Valeur "Nom" correspond a lastName */}
      <ListeTable.Cell
        name="lastName"
        style={handleErrorData(lastName) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        <a href={`https://jvsmairistem.360learning.com/profile/${identifiant}`} target="_blank" rel="noopener noreferrer">
          {lastName}
        </a>
      </ListeTable.Cell>
      {/* Valeur "Prénom" correspond a firstName */}
      <ListeTable.Cell
        name="firstName"
        style={handleErrorData(firstName) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        <a href={`https://jvsmairistem.360learning.com/profile/${identifiant}`} target="_blank" rel="noopener noreferrer">
          {firstName}
        </a>
        {/* {!_.isNil(firstName) ? {firstName} : 'Non renseigné'} */}
      </ListeTable.Cell>
      {/* Valeur "E-mail" correspond a mail */}
      <ListeTable.Cell
        name="mail"
        style={handleErrorData(mail) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        {mail}
        {/* {!handleErrorData(mail) ? {mail} : 'Non renseigné'} */}
      </ListeTable.Cell>
      {/* Valeur "Dernière connexion" correspond a lastLoginAt */}
      <ListeTable.Cell
        name="lastLoginAt"
        style={handleErrorData(lastLoginAt) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        {lastLoginAt ? <FormattedDate dateString={lastLoginAt} /> : 'Non renseigné'}
      </ListeTable.Cell>
      {/* Valeur "Informations complémentaires" */}
      <ListeTable.Cell
        name="info"
        style={handleErrorData(identifiant) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold', textOverflow: 'ellipsis' }}
        textAlign="center"
      >
        <Button icon="info" size="mini" onClick={() => setOpen(!open)} />
      </ListeTable.Cell>

      {/* Modale de visualisation des utilisateurs */}
      <Modal
        colorHeader="blue"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <Modal.Header>
          {`Informations de ${firstName} ${lastName}`}
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <GridColumn width={8}>
              <p>
                Groupes :
              </p>
              {/* Informations sur les groupes de l'utilisateur */}
              <List bulleted>
                {/* Informations sur les groupes de l'utilisateur */}
                {_.map(groups, (group) => (
                  <ListItem key={group.name}>{group.name}</ListItem>
                ))}
              </List>
            </GridColumn>
            <GridColumn width={8}>
              <p>Champs personnalisés :</p>
              <List bulleted>
                {/* Informations sur les champs personnalisés de l'utilisateur */}
                {_.map(Object.groupBy(
                  _.sortBy(customFields, ({ name }) => name),
                  ({ name }) => name,
                ), (value, key) => (
                  <ListItem key={key}>
                    <span>
                      {/* Nom du champ personnalisé */}
                      {key}
                    </span>
                    <ListList>
                      {/* Valeurs du champ personnalisé */}
                      {_.map(value, (customFieldValue) => (
                        <ListItem key={customFieldValue.value}>
                          {!_.isNil(customFieldValue.value) && customFieldValue.value !== ' ' ? customFieldValue.value : '---'}
                        </ListItem>
                      ))}
                    </ListList>
                  </ListItem>
                ))}
              </List>
            </GridColumn>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            content="Fermer"
            icon="close"
            size="tiny"
            onClick={() => setOpen(false)}
          />
        </Modal.Actions>
      </Modal>

    </ListeTable.Row>
  );
};

/** Props venant de ElearningUsersList */
ElearningUsersRow.propTypes = {
  // Informations d'un utilisateur
  // eslint-disable-next-line
    item: PropTypes.object,
};

/** Types par défault des props */
ElearningUsersRow.defaultProps = {
  item: {},
};

export default ElearningUsersRow;
