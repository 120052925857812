import React from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow,
} from '@jvs-group/jvs-mairistem-composants';
import { FormattedDate } from '../../Functions';
import TableSerializatorList from './TableSerializatorList';

const TableSerializator = (props) => {
  const { data } = props;

  // La liste des différents groupes avec leur valeur
  // ! ne pas inverser les clés valeurs sinon le js va trier automatiquement
  const groups = {
    Finance: 1,
    Administré: 2,
    'Gestion du personnel': 3,
    CCAS: 4,
    'Eau Oméga': 5,
    'SAAS élec gp': 6,
    GRC: 7,
    enfance: 8,
    Autres: 0,
  };

  // Grouper les données
  const groupsData = React.useMemo(() => {
    // Ne pas exécuter si data est vide pour éviter les crashs
    if (!_.isNil(data) && !_.isEmpty(data?.tProduits)) {
      // Grouper les données par groupe (data.tProduits.cGroupe)
      return Object.groupBy(_.map(data?.tProduits, (produit) => ({
        ...produit,
        // Si le groupe est vide alors le remplacer par le groupe 0
        cGroupe: produit.cGroupe === '' ? 0 : produit.cGroupe,
      })), (produit) => produit.cGroupe);
    }
    // Retourner un objet vide pour éviter les erreurs
    return {};
  }, [data]);

  return (
    <>
      {/* Avoir un premier tableau structuré */}
      <Table celled>
        <TableHeader>
          <TableRow>
            <TableHeaderCell width={2}>Gamme du client</TableHeaderCell>
            {/* <TableHeaderCell>Gamme du client</TableHeaderCell>
            <TableHeaderCell>CC</TableHeaderCell>
            <TableHeaderCell>Hebergement</TableHeaderCell>
            <TableHeaderCell>KC</TableHeaderCell> */}
            <TableHeaderCell width={2}>Date de sérialisation</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>{data.cLibelleGamme}</TableCell>
            {/* <TableCell>{data.cGamme}</TableCell>
            <TableCell>{data.bCC ? 'Oui' : 'Non'}</TableCell>
            <TableCell>{data.bHeberge ? 'Oui' : 'Non'}</TableCell>
            <TableCell>{data.bKc ? 'Oui' : 'Non'}</TableCell> */}
            <TableCell><FormattedDate dateString={data.dSerial} /></TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {/* Afficher les groupes */}
      {_.map(groups, (value, key) => {
        // Ne pas afficher les groupes vides
        if (_.size(groupsData[value]) > 0) {
          return (
            <>
              <h3>{`${key} :`}</h3>
              {/* Afficher les tableaux */}
              <TableSerializatorList products={groupsData[value]} key={value} />
            </>
          );
        }
        // Retourner null pour éviter les erreurs
        return null;
      })}

    </>
  );
};

TableSerializator.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
};

TableSerializator.defaultProps = {
  data: {},
};

export default TableSerializator;
