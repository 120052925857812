/**
 * @brief Récupérer les données de l'api cim
 */

const uri = `${process.env.REACT_APP_API_URL}`;

/** Récupérer les composants d'un client */
export const getComponents = () => `${uri}/api/cim/components`;

export const getUsersFromComponent = () => `${uri}/api/cim/users`;

export const getComponentCounters = () => `${uri}/api/cim/counters`;
