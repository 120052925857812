/**
 * @brief Compteurs d'un composant
 */
import React from 'react';
import PropTypes from 'prop-types';

import { useParams } from 'react-router';
import { Icon } from '@jvs-group/jvs-mairistem-composants';
import { getComponentCounters } from '../../../routes';
import { useAuthRequest } from '../../../utils';
import { RequestContext } from '../../Context';

const ComponentsCounter = (props) => {
  // Décomposition des props
  const {
    item,
    isOmega,
  } = props;

  /** Identifiant Sugar de la fiche. */
  const { identifiant } = useParams();

  const [totalCounter, setTotalCounter] = React.useState(0);
  const [consumedCounter, setConsumedCounter] = React.useState(0);
  const [availableCounter, setAvailableCounter] = React.useState(0);

  const { prepareRequest } = React.useContext(RequestContext);
  const request = useAuthRequest();

  React.useEffect(() => {
    prepareRequest(() => request({
      method: 'POST',
      url: getComponentCounters(),
      data: {
        externalId: identifiant,
        idComposant: item.identifiant,
        isOmega,
      },
    }).then((response) => {
      setTotalCounter(response.total);
      setConsumedCounter(response.used);
      setAvailableCounter(response.total - response.used);
    }).catch((error) => {
      // eslint-disable-next-line
      console.log('catch erreur getComponentCounters', error);
    }));
  }, [identifiant]);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <p>
        <Icon name="battery full" />
        {' '}
        Total :
        {' '}
        {totalCounter}
      </p>

      <p>
        <Icon name="battery half" />
        {' '}
        Consommé :
        {' '}
        {consumedCounter}
      </p>

      <p>
        <Icon name="battery empty" />
        {' '}
        Disponible :
        {' '}
        {availableCounter}
      </p>
    </div>
  );
};

/** Props venant de ComponentsRow */
ComponentsCounter.propTypes = {
  // Informations d'un composant
  // eslint-disable-next-line
  item: PropTypes.object,
  isOmega: PropTypes.bool,
};

/** Types par défault des props */
ComponentsCounter.defaultProps = {
  item: {},
  isOmega: false,
};

export default ComponentsCounter;
