/**
 * @brief Ligne avec informations du client du tableau des clients
 */
import React from 'react';

import PropTypes from 'prop-types';

import { ListeTable } from '@jvs-group/jvs-mairistem-liste';
import { Icon, List, Popup } from '@jvs-group/jvs-mairistem-composants';

import { handleErrorData, phoneFormat } from '../Functions/Functions';

const CustomersRow = (props) => {
  // Décomposition des props
  const {
    item,
  } = props;

  // Décomposition des données
  const {
    // Identifiant du client
    identifiant,
    // Nom du client
    name,
    // Numéro de téléphone du client
    phoneOffice,
    // Rue du siège du client
    shippingAddressStreet,
    // Ville du siège du client
    shippingAddressCity,
    // Code postal du siège du client
    shippingAddressPostalcode,
    // Chargé.e de clientèle
    assignedUserName,
    // IdKNOTE du client
    idKnoteC,
    // Email du client
    email1,
    // Horaires d'ouvertures
    horairesOuvertureC,
    // Numéro SIREN du client
    sirenC,
  } = item;

  return (
    // Ligne d'un client avec comme clé son identifiant
    <ListeTable.Row key={identifiant}>
      {/* Cellule pour le nom du client */}
      <ListeTable.Cell
        name="name"
        style={{ whiteSpace: 'normal', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        {name}
      </ListeTable.Cell>
      {/* Cellule de l'IdKNOTE du client */}
      <ListeTable.Cell
        name="idKnote"
        disabled={handleErrorData(idKnoteC)}
        verticalAlign={handleErrorData(idKnoteC) ? 'middle' : 'top'}
        style={{ whiteSpace: 'normal', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        {!handleErrorData(idKnoteC)
          ? idKnoteC
          : (
            <div style={{
              transform: 'rotate(-20deg)',
              textAlign: 'center',
              letterSpacing: 5,
            }}
            >
              <b>
                Prospect
              </b>
            </div>
          )}
      </ListeTable.Cell>
      <ListeTable.Cell
        name="siren"
        style={{ whiteSpace: 'normal', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        {sirenC}
      </ListeTable.Cell>
      {/* Cellule de l'adresse du client */}
      <ListeTable.Cell
        name="adresse"
        style={{ whiteSpace: 'normal', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        <List>
          <List.Description>
            {/* Affichage ou non du nom de la rue du client */}
            {(!handleErrorData(shippingAddressStreet)) && `${shippingAddressStreet}`}
          </List.Description>
          <List.Description>
            {/* Affichage ou non du code postal et de la ville du client */}
            {(!handleErrorData(shippingAddressPostalcode)) && `${shippingAddressPostalcode}`}
            {' '}
            {(!handleErrorData(shippingAddressCity)) && `${shippingAddressCity}`}
          </List.Description>
        </List>
      </ListeTable.Cell>
      {/* Cellule pour les coordonnées du client */}
      <ListeTable.Cell
        name="tel"
        style={{ whiteSpace: 'normal', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        <List>
          <List.Description>
            {/* Affichage ou non du numéro de téléphone du client */}
            {(!handleErrorData(phoneOffice)) && `${phoneFormat(phoneOffice)}`}
            {/* Popup pour les horaires du client */}
            <Popup
              content={<pre>{horairesOuvertureC || 'Aucun horaire renseigné'}</pre>}
              trigger={(
                <Icon
                  name="calendar alternate outline"
                  style={{ float: 'right' }}
                />
              )}
              position="left center"
            />
          </List.Description>
          {/* Affichage ou non de l'adresse mail du client */}
          <List.Description>
            {(!handleErrorData(email1)) && `${email1}`}
          </List.Description>
        </List>
      </ListeTable.Cell>
      {/* Cellule pour le chargé.e de clientèle */}
      <ListeTable.Cell
        name="trainer"
        style={{ whiteSpace: 'normal', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        {assignedUserName}
      </ListeTable.Cell>
    </ListeTable.Row>
  );
};

/** Props venant de CustomersListe */
CustomersRow.propTypes = {
  // Informations du client
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
};

/** Types par défault des props */
CustomersRow.defaultProps = {
  item: {},
};

export default CustomersRow;
