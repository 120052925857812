/**
 * @brief Liste des cartes NovaTime d'un client
 */
import React from 'react';
import _ from 'lodash';

import {
  Icon,
} from '@jvs-group/jvs-mairistem-composants';

import PropTypes from 'prop-types';

import { ListeTable } from '@jvs-group/jvs-mairistem-liste';
import NovaTimeRow from './NovaTimeRow';
import { tableSort } from '../../Functions/Functions';

const NovaTimeList = (props) => {
  // Décomposition des props
  const {
    novaTimeCards,
    cardType,
  } = props;

  const [sortedCards, setSortedCards] = React.useState([]);

  React.useMemo(() => {
    // eslint-disable-next-line no-param-reassign
    setSortedCards(_.sortBy(novaTimeCards, ['logUpdateDate']));
  }, [novaTimeCards]);

  /** Ensemble des états que peut prendre une cellule en-tête d'un tableau */
  const [state, dispatch] = React.useReducer(tableSort, {
    column: null,
    data: sortedCards,
    direction: null,
  });

  // Décomposition de state
  const { column, data, direction } = state;

  /** Changement de la valeur data avec les nouvelles cartes NovaTime */
  React.useEffect(() => {
    dispatch({ type: 'CHANGE_CUSTOMER', data: novaTimeCards });
  }, [novaTimeCards]);

  return (
    <div style={!_.isEmpty(data) ? { height: `${data.length * 83 + 47}px`, maxHeight: '800px' } : { height: '300px', maxHeight: '800px' }}>
      <ListeTable
        count={_.size(data)}
        // Données directement dans le tableau
        items={data}
        color="blue"
        selection="click"
        style={{ overFlowY: 'auto' }}
      >
        {/* En-tête du tableau */}
        <ListeTable.Header>
          {/* Case "Type de travail" */}
          <ListeTable.Column
            name="typeOfWorks"
            width={2}
            // Triage du tableau
            sorted={column === 'typeOfWorks' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'typeOfWorks' })}
          >
            <Icon name="clipboard" />
            Type de travail
          </ListeTable.Column>
          {/* Case "libellé" */}
          <ListeTable.Column
            name="label"
            width={3}
            // Triage du tableau
            sorted={column === 'label' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'label' })}
            // Responsive
            only={[
              'l',
              'xl',
            ]}
          >
            <Icon name="tag" />
            Libellé
          </ListeTable.Column>
          {/* Case "Observation" */}
          <ListeTable.Column
            name="preconization"
            width={2}
            // Triage du tableau
            sorted={column === 'preconization' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'preconization' })}
            // Responsive
            only={[
              'xl',
            ]}
          >
            <Icon name="eye" />
            Observation
          </ListeTable.Column>
          {/* Case "Suivi" */}
          <ListeTable.Column
            name="suivi"
            width={1}
            // Triage du tableau
            sorted={column === 'suivi' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'suivi' })}
            // Responsive
            only={[
              'm',
              'l',
              'xl',
            ]}
          >
            <Icon name="circle" />
            Suivi
          </ListeTable.Column>
          {/* Case "Etat" */}
          <ListeTable.Column
            name="status"
            width={2}
            // Triage du tableau
            sorted={column === 'status' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'status' })}
            // Responsive
            only={[
              'm',
              'l',
              'xl',
            ]}
          >
            <Icon name="sticky note" />
            Etat
          </ListeTable.Column>
          {/* Case "Formateur.trice" */}
          <ListeTable.Column
            name="formateur"
            width={2}
            // Triage du tableau
            sorted={column === 'formateur' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'formateur' })}
            // Responsive
            only={[
              'xl',
            ]}
          >
            <Icon name="male" />
            Formateur.trice
          </ListeTable.Column>
          {/* Case "Horaires" */}
          <ListeTable.Column
            name="expectedDate"
            width={3}
            // Triage du tableau
            sorted={column === 'expectedDate' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'expectedDate' })}
            // Responsive
            only={[
              'm',
              'l',
              'xl',
            ]}
          >
            <Icon name="clock" />
            Horaires
          </ListeTable.Column>
          {/* Case "Carte" */}
          <ListeTable.Column
            name="cardUrl"
            width={1}
            // Responsive
            only={[
              'm',
              'l',
              'xl',
            ]}
          >
            <Icon name="file text" />
            Carte
          </ListeTable.Column>
        </ListeTable.Header>
        {/* Contenu du tableau */}
        {(item) => (
          <NovaTimeRow item={item} cardType={cardType} />
        )}
      </ListeTable>
    </div>
  );
};

/** Props venant de FicheCustomerConsumer */
NovaTimeList.propTypes = {
  // Ensemble des contacts du client
  // eslint-disable-next-line
  novaTimeCards: PropTypes.arrayOf(PropTypes.object),
};

/** Types par défault des props */
NovaTimeList.defaultProps = {
  novaTimeCards: [],
};

export default NovaTimeList;
