/**
 * @brief Ligne avec informations du client du tableau des clients
 */
import React from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';

import { ListeTable } from '@jvs-group/jvs-mairistem-liste';
import { Button } from '@jvs-group/jvs-mairistem-composants';

import { useHistory, useParams } from 'react-router';
import { handleErrorData } from '../Functions/Functions';
import { buildUri } from '../../utils';

const ReportsRow = (props) => {
  const params = useParams();
  const history = useHistory();
  // Décomposition des props
  const {
    item,
    idKnote,
  } = props;

  // Décomposition des données
  const {
    // Identifiant du signalement
    identifiant,
    // Nom du collaborateur
    name,
    // Date du signalement
    date,
  } = item;

  const reportPage = React.useCallback(
    () => {
      // Création de l'URL vers le formulaire rempli par le client
      const nextPath = buildUri('/signalement/client/:identifiant/:formulaire/:collaborator', {
        ...params,
        formulaire: 1,
        // ! TODO trouver un autre moyen de passer le collaborateur
        collaborator: name,
      });
      history.push({
        pathname: nextPath,
      });
    },
    [idKnote],
  );

  return (
  // Ligne d'un client avec comme clé son identifiant
    <ListeTable.Row key={identifiant}>
      {/* Cellule pour le nom du client */}
      <ListeTable.Cell
        name="name"
        style={{ whiteSpace: 'normal', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        {name}
      </ListeTable.Cell>
      {/* Cellule de l'IdKNOTE du client */}
      <ListeTable.Cell
        name="date"
        disabled={handleErrorData(date)}
        verticalAlign={handleErrorData(date) ? 'middle' : 'top'}
        style={{ whiteSpace: 'normal', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        {date}
      </ListeTable.Cell>
      <ListeTable.Cell
        name="action"
        style={{ whiteSpace: 'normal', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        <Button
          content="Consulter"
          className={_.isUndefined(idKnote) ? 'disabled' : ''}
          onClick={reportPage}
          target="_blank"
          primary
          fluid
        />
      </ListeTable.Cell>
    </ListeTable.Row>
  );
};

/** Props venant de CustomersListe */
ReportsRow.propTypes = {
  // Informations du client
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
};

/** Types par défault des props */
ReportsRow.defaultProps = {
  item: {},
};

export default ReportsRow;
