/**
 * @brief Liste des mots clés logiciels d'un client depuis sugar
 */
import React from 'react';

import PropTypes from 'prop-types';
import cleanKeywords from '../../../utils/cleanKeywords';
import KeywordsBlock from './KeywordsBlock';

const KeywordsList = ({ keywords }) => {
  // Nettoyage et préparation des données pour chaque catégorie de mots-clés
  const motsClesLogicielsC = cleanKeywords(keywords[0]?.motsClesLogicielsC);
  const logicielSistecC = cleanKeywords(keywords[0]?.logicielSistecC);
  const motsClesAssistanceC = cleanKeywords(keywords[0]?.motsClesAssistanceC);

  return (
    <div>
      <KeywordsBlock title="Mots-clés logiciels" keywords={motsClesLogicielsC} />
      <KeywordsBlock title="Mots-clés SISTEC" keywords={logicielSistecC} />
      <KeywordsBlock title="Mots-clés assistance" keywords={motsClesAssistanceC} />
    </div>
  );
};

/** Props venant de FicheCustomerConsumer */
KeywordsList.propTypes = {
  // Ensemble des mots clés logiciels du client
  // eslint-disable-next-line
  keywords: PropTypes.arrayOf(PropTypes.object),
};

/** Types par défault des props */
KeywordsList.defaultProps = {
  keywords: [],
};

export default KeywordsList;
