/**
 * @brief Ligne d'un devis d'un client
 */
import React from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';

import { ListeTable } from '@jvs-group/jvs-mairistem-liste';

import { handleErrorData } from '../../Functions/Functions';
import { FormattedDate } from '../../Functions';

const SuivisRow = (props) => {
  // Décomposition des props
  const {
    item,
  } = props;

  // Décomposition de item
  const {
    // Identifiant de la ligne du tableau
    identifiant,
    // L'identifiant du suivi
    idSuivi,
    // Date du suivi
    // eslint-disable-next-line
    date_SA,
    // Etat du formulaire formation
    formation,
    // Etat du formulaire GRC
    grc,
    // Etat du formulaire panneaupocket
    panneauPocket,
    // Etat du formulaire de messagerie
    mail,
  } = item || {};

  const renderFormStatus = React.useCallback((f) => {
    const { form } = f || {};

    if (form?.active_step === 0 && _.isNil(form?.sent)) {
      // Non commencé
      return 'Non commencé';
    } if (form?.active_step !== 0 && _.isNil(form?.sent)) {
      // Incomplet
      return 'Incomplet';
    } if (
      !_.isNil(form?.sent)
      && _.isNil(form?.processing)
      && _.isNil(form?.done)
    ) {
      // Complété
      return 'Complété';
    } if (
      !_.isNil(form?.sent)
      && !_.isNil(form?.processing)
      && _.isNil(form?.done)
    ) {
      // En traitement par un collaborateur
      return 'En traitement par un collaborateur';
    }
    // Finalisé
    return 'Finalisé';
  }, []);

  return (
    // Ligne du tableau
    <ListeTable.Row key={identifiant}>
      {/* Valeur pour "identifiant suivi" */}
      <ListeTable.Cell
        name="idSuivi"
        style={handleErrorData(idSuivi) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        {identifiant}
      </ListeTable.Cell>
      {/* Valeur pour "Date du suivi" */}
      <ListeTable.Cell
        name="date_SA"
        // eslint-disable-next-line
        style={handleErrorData(date_SA) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        {/* eslint-disable-next-line */}
        {!handleErrorData(date_SA) ? <FormattedDate dateString={date_SA} hideHours={true} /> : 'Non renseignée'}
      </ListeTable.Cell>
      {/* Valeur pour "Formation" */}
      <ListeTable.Cell
        name="formation"
        style={handleErrorData(formation) ? { backgroundColor: '#e3e3e3', color: 'gray' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        {!handleErrorData(formation) ? renderFormStatus(formation) : '---'}
      </ListeTable.Cell>
      {/* Valeur pour "GRC" */}
      <ListeTable.Cell
        name="grc"
        style={handleErrorData(grc) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        {!handleErrorData(grc) ? renderFormStatus(grc) : '---'}
      </ListeTable.Cell>
      {/* Valeur pour "PanneauPocket" */}
      <ListeTable.Cell
        name="panneauPocket"
        style={handleErrorData(panneauPocket) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        {!handleErrorData(panneauPocket) ? renderFormStatus(panneauPocket) : '---'}
      </ListeTable.Cell>
      {/* Valeur pour "Messagerie pro" */}
      <ListeTable.Cell
        name="mail"
        style={handleErrorData(mail) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold', textOverflow: 'ellipsis' }}
      >
        {!handleErrorData(mail) ? renderFormStatus(mail) : '---'}
      </ListeTable.Cell>
    </ListeTable.Row>
  );
};

/** Props venant de SuivisLists */
SuivisRow.propTypes = {
  // Informations d'un suivi
  // eslint-disable-next-line
  item: PropTypes.object,
};

/** Types par défault des props */
SuivisRow.defaultProps = {
  item: {},
};

export default SuivisRow;
