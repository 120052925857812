/**
 * @brief Panneau de recherche de clients
 */
import React from 'react';
import _ from 'lodash';

import {
  Header,
  Segment,
  Grid,
  Form,
} from '@jvs-group/jvs-mairistem-composants';

import PropTypes from 'prop-types';

import { searchTypes } from '../../types/search';

const SearchPanel = (props) => {
  // Décomposition des props
  const {
    onClearClick,
    onSearchClick,
    onQueryChange,
    search,
    searching,
  } = props;

  // Décomposition de search
  const {
    idKnote,
    denomination,
    postalCode,
  } = search || {};

  /** Booléen pour si l'utilisateur est sur mobile ou non */
  const isMobile = window.innerWidth < 767;

  /** Fonction de modification des paramètres de recherche. */
  const handleChange = React.useCallback((e, data) => {
    // Décomposition de data
    const { name, value } = data;

    // Appel de la fonction onQueryChange qui modifie l'état du champ modifié
    onQueryChange({
      ...search,
      [name]: value,
    });
  }, [onQueryChange, search]);

  /** Recherche lorsque la touche 'Entrée' est pressée */
  const handleKeyDown = React.useCallback((e) => {
    // Vérification si la touche 'Entrée' a bien été pressée
    if (e.key === 'Enter') {
      // Empêche d'appeler la fonction sur le premier bouton, ici "Effacer la recherche"
      e.preventDefault();
      // Appel de la fonction onSearchClick qui lancera la recherche de client
      onSearchClick();
    }
  }, [onSearchClick]);

  return (
    <Segment padded>
      {/* En-tête de la partie */}
      {window.innerWidth >= 1240 ? (
        <Header>
          Rechercher un client
        </Header>
      ) : null}
      {/* Grid qui prendra la forme d'un Form */}
      <Grid as={Form} stackable={!isMobile} fluid>
        <Grid.Row>
          <Grid.Column>
            {/* Champ IdKNOTE */}
            <Form.Input
              // Nom du label au-dessus du champ
              label="Nom du client"
              // Nom de l'imput
              name="denomination"
              placeholder="Mairie de Sauxemesnil"
              // Etat de l'input
              value={denomination}
              // Fonction lors de la modification de l'état du champ
              onChange={handleChange}
              // Fonction lorsqu'une touche est appuyée (fonction uniquement avec 'Entrée')
              onKeyDown={handleKeyDown}
              // Style
              icon="address card"
              iconPosition="left"
              // Id de test
              input={{
                'data-testid': 'searchpanel-input-denomination',
              }}
              // Bouton pour vider le champ
              {...!_.isEmpty(denomination) && {
                action: {
                  basic: true,
                  icon: 'cancel',
                  onClick: handleChange,
                  name: 'denomination',
                  value: '',
                },
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <Form.Input
              // Nom du label au-dessus du champ
              label="Code postal"
              // Nom de l'imput
              name="postalCode"
              placeholder="50700"
              // Etat de l'input
              value={postalCode}
              // Fonction lors de la modification de l'état du champ
              onChange={handleChange}
              // Fonction lorsqu'une touche est appuyée (fonction uniquement avec 'Entrée')
              onKeyDown={handleKeyDown}
              // Style
              icon="university"
              iconPosition="left"
              // Id de test
              input={{
                'data-testid': 'searchpanel-input-postalCode',
              }}
              // Bouton pour vider le champ
              {...!_.isEmpty(postalCode) && window.innerWidth >= 1150 && {
                action: {
                  basic: true,
                  icon: 'cancel',
                  onClick: handleChange,
                  name: 'postalCode',
                  value: '',
                },
              }}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            {/* Champ "IdKNOTE" */}
            <Form.Input
              // Nom du label au-dessus du champ
              label={isMobile ? 'Id. KNOTE' : 'Identifiant KNOTE'}
              // Nom de l'imput
              name="idKnote"
              placeholder="3946"
              // Etat de l'input
              value={idKnote}
              // Fonction lors de la modification de l'état du champ
              onChange={handleChange}
              // Fonction lorsqu'une touche est appuyée (fonction uniquement avec 'Entrée')
              onKeyDown={handleKeyDown}
              // Style
              icon="user"
              iconPosition="left"
              // Id de test
              input={{
                'data-testid': 'searchpanel-input-idKnote',
              }}
              // Bouton pour vider le champ
              {...!_.isEmpty(idKnote) && window.innerWidth >= 1150 && {
                action: {
                  basic: true,
                  icon: 'cancel',
                  onClick: handleChange,
                  name: 'idKnote',
                  value: '',
                },
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {/* Colonne avec des dimensions variables en fonction de la taille de la fenêtre */}
          <Grid.Column textAlign="center" width={8}>
            {/* Bouton de suppression des champs */}
            {onClearClick && (
              <Form.Button
                primary
                onClick={onClearClick}
                icon="trash"
                content={isMobile ? null : 'Effacer la recherche'}
                float="right"
                fluid={!isMobile}
                data-testid="searchpanel-button-erase"
              />
            )}
          </Grid.Column>
          {/* Colonne avec des dimensions variables en fonction de la taille de la fenêtre */}
          <Grid.Column textAlign="center" width={8}>
            {/* Bouton de recherche */}
            {onSearchClick && (
            <Form.Button
              primary
              onClick={onSearchClick}
              icon="search"
              content={isMobile ? null : 'Rechercher'}
              fluid={!isMobile}
              disabled={searching}
              loading={searching}
              data-testid="searchpanel-button-search"
            />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

/** Props venant de CustomersContainer */
SearchPanel.propTypes = {
  // Fonction pour la modification des paramètres de recherche
  onQueryChange: PropTypes.func,
  // Fonction pour le clic du bouton "Chercher"
  onSearchClick: PropTypes.func,
  // Fonction pour le clic du bouton "Effacer"
  onClearClick: PropTypes.func,
  // Paramètres de recherche.
  search: PropTypes.shape(searchTypes),
  // Le bouton de recherche peut être en chargement.
  searching: PropTypes.bool,
};

/** Types par défault des props */
SearchPanel.defaultProps = {
  onQueryChange: null,
  onSearchClick: null,
  onClearClick: null,
  search: null,
  searching: false,
};

export default SearchPanel;
