/**
 * @brief Pied de page avec liens vers autre applications JVS
 */
import React from 'react';
import _ from 'lodash';

import {
  Icon,
  Table,
  Popup,
  Segment,
  Modal,
  Image,
  Button,
  Dropdown,
  // Select,
} from '@jvs-group/jvs-mairistem-composants';

import intranet from '../../images/intranet_logo.png';
import novaTime from '../../images/novatime_logo.png';
import nibelis from '../../images/nibelis_logo.svg';
import yammer from '../../images/Viva_Engage_icon.png';
import sugarcrm from '../../images/sugarcrm_logo.png';
import office365 from '../../images/office365_logo.png';
import novaDrive from '../../images/novadrive_logo.svg';
import zendesk from '../../images/zendesk_logo.png';
import tableau from '../../images/tableau_logo.png';
import eurecia from '../../images/eurecia_logo.png';
import supervision from '../../images/supervision_logo.png';
import elearning from '../../images/elearning_logo.png';

import './style.css';

const FooterLayout = () => {
  /** Booléen pour savoir si l'utilisateur est sur portable ou non */
  const isMobile = window.innerWidth < 1023;

  /** Booléen pour l'état de la Modal */
  const [open, setOpen] = React.useState(false);

  const [isNewsAvailable, setIsNewsAvailable] = React.useState(true);

  const [selectedMonth, setSelectedMonth] = React.useState('Juillet 2024');

  // ! La date de dernière maj
  const updateDate = new Date('2024-07-19');

  /** Liste des mises à jours */
  const updates = {
    'Juillet 2024': `Mise en place des fonctionnalités\n
      . Ajout des informations sur les utilisateurs e-learning du client\n
      . Ajout des informations sur les formulaires MIC du client\n
      . Ajout d'icônes dans le pied de page\n`,

    'Mai 2024': `Mise en place des fonctionnalités\n
      . Ajout des tickets zendesk du client\n`,

    'Juillet 2023': `Mise en place des fonctionnalités\n
      . Modification et correction de certains affichages\n
      . Interface utilisateur améliorée pour une meilleure navigation web et mobile\n
      . Intégration avec les sites internes pour un aperçu complet de l'activité du client\n
      . Recherche de clients plus précise\n
      . Récupération en temps réel pour maintenir les informations à jour\n
      . Liste des clients renommé en Résultat de la recherche\n
      . Remplacement de "-1" par "Non renseigné"\n
      . Affichage séparé pour novatime réalisé/terminé et toujours en cours\n
      . Changement d'icone diverse et corrections de certains libellé \n
      . Ajout des interventions techniques novatime \n
      . Changment des affichages des informations clients \n
      . Ajout du lien GLPI pour faire un ticket d'assistance \n`,

    'Juin 2023': 'Mise en production de la Fiche 360 le 30/06/2023\n',
  };

  // Avoir l'entiéreté des mois disponibles automatiquement
  const monthsOptions = _.map(updates, (value, key) => ({
    key,
    value: key,
    text: key,
  }));

  // On créer un cookie pour écrire la date à laquelle on a consulté les nouveautés
  const createCookieNews = () => {
    const newDate = new Date();
    const cookieDate = new Date();
    // Mettre une date d'expiration du cookie à un an
    cookieDate.setTime(cookieDate.getTime() + (365 * 24 * 60 * 60 * 1000));
    // Creer le cookie
    document.cookie = `newsAvailable=${newDate.toUTCString()}; expires=${cookieDate.toUTCString()}`;
    // Arrêter de faire clignoter l'icone de nouveautés
    setIsNewsAvailable(false);
  };

  // Récupérer un cookie déjà enregistrer et renvoyer sa valeur
  const getCookie = (cname) => {
    const name = `${cname}=`;
    // Séparer s'il y a plus d'un cookie
    const ca = document.cookie.split('; ');
    // La valeur du cookie
    let cookieDate = null;
    // Trouver le cookie correspondant (ici newsAvailable)
    ca.forEach((e) => {
      if (e.indexOf(name) === 0) {
        cookieDate = e.substring(name.length, e.length);
      }
    });
    return cookieDate;
  };

  const isNewsRead = () => {
    // Avoir la valeur du cookie s'il existe
    const newsReadCookie = getCookie('newsAvailable');
    // Date de laquelle on a consulté les nouveautés
    const newsRead = new Date(newsReadCookie);

    // Si le cookie existe et qu'il n'y a pas de nouveauté depuis la dernière consultation
    if (!_.isNil(newsReadCookie) && newsRead > updateDate) {
      return true;
    }
    return false;
  };

  const moreLinks = (
    <>
      <Table.Row style={{ padding: '30px' }}>
        {/* Lien vers l'Intranet */}
        <Table.Cell>
          <a href="https://intranet.jvsonline.fr/pages/accueilJVS.aspx" target="_blank" rel="noreferrer">
            <Popup
              content="Intranet"
              trigger={<Image src={intranet} avatar />}
              inverted
              position="top center"
            />
          </a>
        </Table.Cell>
        <Table.Cell />
        <Table.Cell />
        {/* Lien vers novaTime */}
        <Table.Cell>
          <a href="https://novatime.jvsonline.fr/" target="_blank" rel="noreferrer">
            <Popup
              content="NovaTime"
              trigger={<Image src={novaTime} avatar />}
              inverted
              position="top center"
            />
          </a>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell />
        {/* Lien vers Nibelis */}
        <Table.Cell>
          <a href="https://client.nibelis.com/accueil/" target="_blank" rel="noreferrer">
            <Popup
              content="Nibelis"
              trigger={<Image src={nibelis} avatar />}
              inverted
              position="top center"
            />
          </a>
        </Table.Cell>
        <Table.Cell />
        {/* Lien vers Yammer de l'activité de l'entreprise */}
        <Table.Cell>
          <a href="https://web.yammer.com/main/feed" target="_blank" rel="noreferrer">
            <Popup
              content="Yammer"
              trigger={<Image src={yammer} avatar />}
              inverted
              position="top center"
            />
          </a>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        {/* Lien vers Office365 */}
        <Table.Cell>
          <a href="https://www.office.com/" target="_blank" rel="noreferrer">
            <Popup
              content="Office 365"
              trigger={<Image src={office365} avatar />}
              inverted
              position="top center"
            />
          </a>
        </Table.Cell>
        <Table.Cell />
        {/* Lien vers novaDrive */}
        <Table.Cell>
          <a href="https://novadrive.jvsonline.fr/index.php/login" target="_blank" rel="noreferrer">
            <Popup
              content="NovaDrive"
              trigger={<Image src={novaDrive} avatar />}
              inverted
              position="top center"
            />
          </a>
        </Table.Cell>
        <Table.Cell />
        {/* Lien vers l'API Sugar */}
        <Table.Cell>
          <a href="https://crm.jvsonline.fr/" target="_blank" rel="noreferrer">
            <Popup
              content="SugarCRM"
              trigger={<Image src={sugarcrm} avatar />}
              inverted
              position="top center"
            />
          </a>
        </Table.Cell>
        {/* Lien vers Eurecia */}
        <Table.Cell>
          <a href="https://eurecia.com/" target="_blank" rel="noreferrer">
            <Popup
              content="Eurecia"
              trigger={<Image src={eurecia} avatar />}
              inverted
              position="top center"
            />
          </a>
        </Table.Cell>

      </Table.Row>
      <Table.Row>
        <Table.Cell />
        {/* Lien vers Zendesk */}
        <Table.Cell>
          <a href="https://groupe-jvs.zendesk.com/agent/dashboard" target="_blank" rel="noreferrer">
            <Popup
              content="Zendesk"
              trigger={<Image src={zendesk} avatar />}
              inverted
              position="top center"
            />
          </a>
        </Table.Cell>
        <Table.Cell />
        {/* Lien vers Tableau */}
        <Table.Cell>
          <a href="https://tableau.jvsonline.fr/" target="_blank" rel="noreferrer">
            <Popup
              content="Tableau"
              trigger={<Image src={tableau} avatar />}
              inverted
              position="top center"
            />
          </a>
        </Table.Cell>
        {/* Lien vers Supervision */}
        <Table.Cell>
          <a href="https://admin.securitenumerique.cloud/" target="_blank" rel="noreferrer">
            <Popup
              content="Supervision"
              trigger={<Image src={supervision} avatar />}
              inverted
              position="top center"
            />
          </a>
        </Table.Cell>
        {/* Lien vers Elearning */}
        <Table.Cell>
          <a href="https://jvsmairistem.360learning.com/" target="_blank" rel="noreferrer">
            <Popup
              content="E-learning"
              trigger={<Image src={elearning} avatar />}
              inverted
              position="top center"
            />
          </a>
        </Table.Cell>
      </Table.Row>
    </>
  );

  React.useEffect(() => {
    // Récupération de la valeur
    const isNewsReadValue = isNewsRead();

    // Use the value as needed
    if (isNewsReadValue) {
      setIsNewsAvailable(false);
    }
  }, []);

  return (
    <Table color="blue" textAlign="center" unstackable style={{ margin: '0' }}>
      <Table.Body>
        <Table.Row>
          {/* Espace du début */}
          {window.innerWidth > 374 ? <Table.Cell /> : null}
          {!isMobile ? (
            <>
              {/* Espace du milieu */}
              <Table.Cell />
              {/* Lien vers l'Intranet */}
              <Table.Cell>
                <a href="https://intranet.jvsonline.fr/pages/accueilJVS.aspx" target="_blank" rel="noreferrer">
                  <Popup
                    content="Intranet"
                    trigger={<Image src={intranet} avatar />}
                    inverted
                    position="top center"
                  />
                </a>
              </Table.Cell>
              {/* Lien vers novaTime */}
              <Table.Cell>
                <a href="https://novatime.jvsonline.fr/" target="_blank" rel="noreferrer">
                  <Popup
                    content="NovaTime"
                    trigger={<Image src={novaTime} avatar />}
                    inverted
                    position="top center"
                  />
                </a>
              </Table.Cell>
              {/* Lien vers Nibelis */}
              <Table.Cell>
                <a href="https://client.nibelis.com/accueil/" target="_blank" rel="noreferrer">
                  <Popup
                    content="Nibelis"
                    trigger={<Image src={nibelis} avatar />}
                    inverted
                    position="top center"
                  />
                </a>
              </Table.Cell>
              {/* Lien vers Yammer de l'activité de l'entreprise */}
              <Table.Cell>
                <a href="https://web.yammer.com/main/feed" target="_blank" rel="noreferrer">
                  <Popup
                    content="Yammer"
                    trigger={<Image src={yammer} avatar />}
                    inverted
                    position="top center"
                  />
                </a>
              </Table.Cell>
              {/* Lien vers Office365 */}
              <Table.Cell>
                <a href="https://www.office.com/" target="_blank" rel="noreferrer">
                  <Popup
                    content="Office 365"
                    trigger={<Image src={office365} avatar />}
                    inverted
                    position="top center"
                  />
                </a>
              </Table.Cell>
              {/* Lien vers novaDrive */}
              <Table.Cell>
                <a href="https://novadrive.jvsonline.fr/index.php/login" target="_blank" rel="noreferrer">
                  <Popup
                    content="NovaDrive"
                    trigger={<Image src={novaDrive} avatar />}
                    inverted
                    position="top center"
                  />
                </a>
              </Table.Cell>
              {/* Lien vers l'API Sugar */}
              <Table.Cell>
                <a href="https://crm.jvsonline.fr/" target="_blank" rel="noreferrer">
                  <Popup
                    content="SugarCRM"
                    trigger={<Image src={sugarcrm} avatar />}
                    inverted
                    position="top center"
                  />
                </a>
              </Table.Cell>
              {/* Lien vers Eurecia */}
              <Table.Cell>
                <a href="https://eurecia.com/" target="_blank" rel="noreferrer">
                  <Popup
                    content="Eurecia"
                    trigger={<Image src={eurecia} avatar />}
                    inverted
                    position="top center"
                  />
                </a>
              </Table.Cell>
              {/* Lien vers Zendesk */}
              <Table.Cell>
                <a href="https://groupe-jvs.zendesk.com/agent/dashboard" target="_blank" rel="noreferrer">
                  <Popup
                    content="Zendesk"
                    trigger={<Image src={zendesk} avatar />}
                    inverted
                    position="top center"
                  />
                </a>
              </Table.Cell>
              {/* Lien vers Tableau */}
              <Table.Cell>
                <a href="https://tableau.jvsonline.fr/" target="_blank" rel="noreferrer">
                  <Popup
                    content="Tableau"
                    trigger={<Image src={tableau} avatar />}
                    inverted
                    position="top center"
                  />
                </a>
              </Table.Cell>
              {/* Lien vers Supervision */}
              <Table.Cell>
                <a href="https://admin.securitenumerique.cloud/" target="_blank" rel="noreferrer">
                  <Popup
                    content="Supervision"
                    trigger={<Image src={supervision} avatar />}
                    inverted
                    position="top center"
                  />
                </a>
              </Table.Cell>
              {/* Lien vers Elearning */}
              <Table.Cell>
                <a href="https://jvsmairistem.360learning.com/" target="_blank" rel="noreferrer">
                  <Popup
                    content="E-learning"
                    trigger={<Image src={elearning} avatar />}
                    inverted
                    position="top center"
                  />
                </a>
              </Table.Cell>
              <Table.Cell />
              {/* Lien vers l'assistance GLPI */}
              <Table.Cell>
                <a href="https://assistance-technique.jvsonline.fr/front/ticket.form.php" target="_blank" rel="noreferrer">
                  <Popup
                    content="Assistance GLPI"
                    trigger={(
                      <Segment basic style={{ padding: 0 }}>
                        {!isMobile ? 'Besoin d\'aide ? ' : null}
                        <Icon name="headphones" size="large" color="blue" />
                      </Segment>
                    )}
                    inverted
                    position="top center"
                  />
                </a>
              </Table.Cell>
              {/* Version & Noveautés */}
              <Table.Cell>
                <Modal
                  colorHeader="blue"
                  open={open}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                  trigger={(
                    <Segment basic style={{ padding: 0, cursor: 'pointer' }}>
                      {!isMobile ? 'Version & Nouveautés ' : null}
                      <Icon
                        name="question circle outline"
                        size="large"
                        color="blue"
                        // Faire clignoter l'icone de nouveautés
                        className={isNewsAvailable ? 'news-available' : null}
                        // Créer un cookie pour la date à laquelle on a consulté les nouveautés
                        onClick={() => createCookieNews()}
                      />
                    </Segment>
                  )}
                >
                  <Modal.Header>
                    Version & Nouveautés
                  </Modal.Header>
                  <Modal.Content>
                    {/* La sélection du mois */}
                    <Dropdown
                      fluid
                      search
                      selection
                      onChange={(e, { value }) => setSelectedMonth(value)}
                      value={selectedMonth}
                      options={monthsOptions}
                    />
                    {/* Les mises à jour du mois selectioné */}
                    {updates[selectedMonth] && <pre>{updates[selectedMonth]}</pre>}
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      positive
                      content="Fermer"
                      icon="close"
                      size="tiny"
                      onClick={() => setOpen(false)}
                    />
                  </Modal.Actions>
                </Modal>
              </Table.Cell>
              {/* Espace de fin */}
              <Table.Cell />
            </>
          ) : (
            <>
              {/* Lien vers l'assistance GLPI */}
              <Table.Cell>
                <a href="https://assistance-technique.jvsonline.fr/front/central.php" target="_blank" rel="noreferrer">
                  <Popup
                    content="Assistance GLPI"
                    trigger={(
                      <Segment basic style={{ padding: 0 }}>
                        {!isMobile ? 'Besoin d\'aide ? ' : null}
                        <Icon name="headphones" size="large" color="blue" />
                      </Segment>
                    )}
                    inverted
                    position="top center"
                  />
                </a>
              </Table.Cell>
              {window.innerWidth > 374 ? <Table.Cell /> : null}
              <Table.Cell>
                <Popup
                  content={moreLinks}
                  trigger={<Icon name="bars" size="large" onClick={() => setOpen(true)} />}
                  on="click"
                  hideOnScroll
                  position="top right"
                />
              </Table.Cell>
              <Modal
                colorHeader="blue"
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
              >
                <Modal.Header>
                  Version & Nouveautés
                </Modal.Header>
                <Modal.Content>
                  {/* La sélection du mois */}
                  <Dropdown
                    fluid
                    search
                    selection
                    onChange={(e, { value }) => setSelectedMonth(value)}
                    value={selectedMonth}
                    options={monthsOptions}
                  />
                  {/* Les mises à jour du mois selectioné */}
                  {updates[selectedMonth] && <pre>{updates[selectedMonth]}</pre>}
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    positive
                    content="Fermer"
                    icon="close"
                    size="tiny"
                    onClick={() => setOpen(false)}
                  />
                </Modal.Actions>
              </Modal>
            </>
          )}
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default FooterLayout;
