/**
 * @brief Liste des utilisateurs e-learning d'un client
 */
import React from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';

import { ListeTable } from '@jvs-group/jvs-mairistem-liste';

import { Icon } from '@jvs-group/jvs-mairistem-composants';
import { tableSort } from '../../Functions/Functions';
import ElearningUsersRow from './ElearningUsersRow';

const ElearningUsersList = (props) => {
  // Décomposition des props
  const { users } = props;

  /** Ensemble des états que peut prendre une cellule en-tête d'un tableau */
  const [state, dispatch] = React.useReducer(tableSort, {
    column: null,
    data: users,
    direction: null,
  });

  // Décomposition de state
  const { column, data, direction } = state;

  /** Changement de la valeur data avec les suivi du client */
  React.useEffect(() => {
    dispatch({ type: 'CHANGE_CUSTOMER', data: users });
  }, [users]);

  return (
    // TODO: Modifier pour que ce ne soit pas en brut
    <div style={!_.isEmpty(data) ? { height: `${data.length * 58 + 48}px`, maxHeight: '800px' } : { height: '300px', maxHeight: '800px' }}>
      {/* Tableau des utilisateurs e-learning du client */}
      <ListeTable
        count={_.size(data)}
        // Données directement dans le tableau
        items={data}
        color="blue"
        selection="click"
      >
        {/* En-tête du tableau */}
        <ListeTable.Header>
          {/* Case "Nom" correspond a lastName */}
          <ListeTable.Column
            name="lastName"
            width={2}
            // Triage du tableau
            sorted={column === 'lastName' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'lastName' })}
            // Responsive
            only={[
              'l',
              'xl',
            ]}
          >
            <Icon name="angle right" />
            Nom
          </ListeTable.Column>
          {/* Case "Prénom" correspond a firstName */}
          <ListeTable.Column
            name="firstName"
            width={2}
            // Triage du tableau
            sorted={column === 'firstName' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'firstName' })}
            // Responsive
            only={[
              'l',
              'xl',
            ]}
          >
            <Icon name="angle right" />
            Prénom
          </ListeTable.Column>
          {/* Case "E-mail" correspond a mail */}
          <ListeTable.Column
            name="mail"
            width={2}
            // Triage du tableau
            sorted={column === 'mail' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'mail' })}
            // Responsive
            only={[
              'l',
              'xl',
            ]}
          >
            <Icon name="angle right" />
            E-mail
          </ListeTable.Column>
          {/* Case "Dernière connexion" correspond a lastLoginAt */}
          <ListeTable.Column
            name="lastLoginAt"
            width={2}
            // Triage du tableau
            sorted={column === 'lastLoginAt' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'lastLoginAt' })}
            // Responsive
            only={[
              'l',
              'xl',
            ]}
          >
            <Icon name="angle right" />
            Dernière connexion
          </ListeTable.Column>
          {/* Case "Informations complémentaires" */}
          <ListeTable.Column
            name="info"
            width={2}
            // Triage du tableau
            sorted={column === 'info' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'info' })}
            // Responsive
            only={[
              'l',
              'xl',
            ]}
          >
            <Icon name="angle right" />
            Informations complémentaires
          </ListeTable.Column>

        </ListeTable.Header>

        {/* Contenu du tableau */}
        {(item) => (
          <ElearningUsersRow item={item} />
        )}
      </ListeTable>
    </div>
  );
};

/** Props venant de FicheCustomerConsumer */
ElearningUsersList.propTypes = {
  // Ensemble des utilisateurs e-learning
  // eslint-disable-next-line
  users: PropTypes.arrayOf(PropTypes.object),
};

/** Types par défault des props */
ElearningUsersList.defaultProps = {
  users: {},
};

export default ElearningUsersList;
