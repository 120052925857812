/**
 * @brief tableau des utilisateurs d'un composant d'un client
 */
import React from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { ListeTable } from '@jvs-group/jvs-mairistem-liste';

const ComponentsUsersRow = (props) => {
  const { item } = props;

  const { userCustomer } = item || {};

  const { user } = userCustomer || {};

  const {
    email, firstName, lastName, lastRole, id,
  } = user || {};

  return (
  // Ligne du tableau
    <ListeTable.Row key={id}>
      {/* Valeur pour "prénom" */}
      <ListeTable.Cell
        name="Name"
        style={_.isEmpty(firstName) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {!_.isEmpty(firstName) ? firstName : 'Non renseigné'}
      </ListeTable.Cell>
      {/* Valeur pour "nom" */}
      <ListeTable.Cell
        name="FirstName"
        style={_.isEmpty(lastName) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {!_.isEmpty(lastName) ? lastName : 'Non renseigné'}
      </ListeTable.Cell>
      {/* Valeur pour "email" */}
      <ListeTable.Cell
        name="Email"
        style={_.isEmpty(email) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {!_.isEmpty(email) ? email : 'Non renseigné'}
      </ListeTable.Cell>
      {/* Valeur pour "fonction" */}
      <ListeTable.Cell
        name="Role"
        style={_.isEmpty(lastRole) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {!_.isEmpty(lastRole) ? lastRole : 'Non renseigné'}
      </ListeTable.Cell>
    </ListeTable.Row>
  );
};

/** Props venant de ComponentsUsersList */
ComponentsUsersRow.propTypes = {
  // Informations d'un utilisateur d'un composant
  // eslint-disable-next-line
    item: PropTypes.object,
};

/** Types par défault des props */
ComponentsUsersRow.defaultProps = {
  item: {},
};

export default ComponentsUsersRow;
