import React, { useMemo } from 'react';
import { RequestContext } from './Context';

const RequestHandler = ({ children }) => {
  const prepareRequest = (callback) => {
    callback();
  };

  const context = useMemo(() => ({
    prepareRequest,
  }), []);

  return (
    <RequestContext.Provider value={context}>
      {children}
    </RequestContext.Provider>
  );
};

export default RequestHandler;
