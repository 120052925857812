/**
 * @brief Fichier regroupant toutes les routes avec l'authentification
 */
import React from 'react';

import {
  Route,
} from 'react-router';
import { BrowserRouter, Redirect } from 'react-router-dom';
import { StoreProvider } from '@jvs-group/jvs-mairistem-store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import _ from 'lodash';
import CustomersContainer from './components/CustomersContainer';
import FicheCustomerConsumer from './components/Fiche/FicheCustomerConsumer';
import { LogContext } from './components/Context';
import Authentication from './components/Authentication';
import RequestHandler from './components/RequestHandler';
import Layout from './components/Layout';
import FormCustomer from './components/Panels/Customer/FormCustomer';
import ReportList from './components/Reports/ReportList';
import { CustomerReport } from './components/Reports';
/* eslint-disable */
const App = () => {
  /** Token entre front et back. */
  const [token, setToken] = React.useState();
  const [tokenSugar, setTokenSugar] = React.useState();
  const [userPrincipalName, setUserPrincipalName] = React.useState();

  /** Déconnecte l'utilisateur. */
  const disconnect = (manuallyDisconnected = true) => {
    setToken('');

    localStorage.removeItem('context');
    sessionStorage.setItem(
      'auth',
      JSON.stringify({
        manuallyDisconnected,
      }),
    );
    window.location = `${process.env.REACT_APP_SSO_URL}/logout`;
  };

  const isAuthenticationValid = React.useCallback((apiResponse) => {
    if (apiResponse.status && apiResponse.status === 401) {
      disconnect(false);
    }

    return true;
  }, []);
  /** Rempli le localStorage avec les données envoyées en paramètre. */
  const initializeLocalStorage = React.useCallback((...params) => {
    // Fonction a appeler une fois
    // quand le back renvoi son token (et d'autres infos si besoin (user ?))
    const serializedContext = JSON.stringify({ ...params });

    localStorage.setItem('context', serializedContext);
    sessionStorage.removeItem('auth');
  }, []);

  /** Récupère le context depuis le localStorage. */
  React.useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV !== 'dev') {
      const context = JSON.parse(localStorage.getItem('context'));

      // Décomposition du context, si il est initialisé
      const { token: tokenContext } = context || {};

      setToken(tokenContext);
      // remove query string 'code' from url after login
      window.history.replaceState({}, document.title, `/${window.location.hash.replace(/^#\/?/, '')}`);
    }
  }, []);

  React.useEffect(() => {
    // Retrieve param 'code' in my url
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (!_.isNil(code)) {
      window.location.search = '';
    }
  }, []);

  /** Récupération des informations du context */
  const logContext = React.useMemo(() => ({
    token,
    setToken,
    tokenSugar,
    setTokenSugar,
    userPrincipalName,
    setUserPrincipalName,
    disconnect,
    initializeLocalStorage,
    isAuthenticationValid,
  }), [
    token,
    setToken,
    tokenSugar,
    setTokenSugar,
    userPrincipalName,
    setUserPrincipalName,
    disconnect,
    initializeLocalStorage,
    isAuthenticationValid,
  ]);

  return (
    <div id="app">
      <LogContext.Provider
        value={logContext}
      >
        {/* Code qui accueil les toasts */}
        <ToastContainer
          draggable={false}
          theme="light"
          newestOnTop
        />
        <BrowserRouter>
          {!_.isNil(token) && !_.isEmpty(token)
            ? (
              <StoreProvider>
                <RequestHandler>
                  <Layout>
                    {/* Chemin vers tous les clients */}
                    <Route
                      component={CustomersContainer}
                      path="/clients"
                      exact
                    />
                    {/* Chemin d'un client */}
                    <Route
                      component={FicheCustomerConsumer}
                      path="/clients/:identifiant"
                      exact
                    />
                    {/* Chemin vers la liste des signalements */}
                    <Route
                      component={ReportList}
                      path="/signalement/client/:identifiant"
                      exact
                    />
                    {/* Chemin vers un signalement */}
                    <Route
                      component={CustomerReport}
                      // ! TODO trouver un autre moyen de passer le collaborateur
                      path="/signalement/client/:identifiant/:formulaire/:collaborator"
                      exact
                    />
                    {/* Chemin pour signaler un problème */}
                    <Route
                      component={FormCustomer}
                      path="/formulaire/client/:identifiant"
                      exact
                    />
                    {/* Autres chemins. Temporaire, le temps d'avoir une page d'accueil. */}
                    <Route exact path="/">
                      <Redirect to="/clients" />
                    </Route>
                  </Layout>
                </RequestHandler>
              </StoreProvider>
            ) : (
              <Route path="/">
                <Authentication />
              </Route>
            )}
        </BrowserRouter>
      </LogContext.Provider>
    </div>
  );
};

export default App;
