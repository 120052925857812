/**
 * @brief tableau des utilisateurs d'un composant d'un client
 */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ListeTable } from '@jvs-group/jvs-mairistem-liste';
import { Icon } from '@jvs-group/jvs-mairistem-composants';
import ComponentsUsersRow from './ComponentsUsersRow';
import { tableSort } from '../../Functions/Functions';

const ComponentsUsersList = (props) => {
  const { users } = props;

  /** Ensemble des états que peut prendre une cellule en-tête d'un tableau */
  const [state, dispatch] = React.useReducer(tableSort, {
    column: null,
    data: users,
    direction: null,
  });
    // Décomposition de state
  const { column, data, direction } = state;

  /** Changement de la valeur data avec les nouveaux composants */
  React.useEffect(() => {
    dispatch({ type: 'CHANGE_CUSTOMER', data: users });
  }, [users]);

  return (
    <div style={!_.isEmpty(data) ? { height: `${data.length * 45 + 47}px`, maxHeight: '800px' } : { height: '300px', maxHeight: '800px' }}>
      <ListeTable
        count={_.size(data)}
                // Données directement dans le tableau
        items={data}
        color="blue"
        selection="click"
        style={{ overflowY: 'auto' }}
      >
        {/* En-tête du tableau */}
        <ListeTable.Header>
          {/* Cellule "Nom de l'utilisateur" */}
          <ListeTable.Column
            name="Name"
            width={4}
                        // Triage du tableau
            sorted={column === 'Name' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'Name' })}
          >
            <Icon name="angle right" />
            Nom
          </ListeTable.Column>
          {/* Cellule "Prénom de l'utilisateur" */}
          <ListeTable.Column
            name="FirstName"
            width={4}
                        // Triage du tableau
            sorted={column === 'FirstName' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'FirstName' })}
          >
            <Icon name="angle right" />
            Prénom
          </ListeTable.Column>
          {/* Cellule "Email de l'utilisateur" */}
          <ListeTable.Column
            name="Email"
            width={6}
                        // Triage du tableau
            sorted={column === 'Email' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'Email' })}
          >
            <Icon name="angle right" />
            Email
          </ListeTable.Column>
          {/* Cellule "Rôle de l'utilisateur" */}
          <ListeTable.Column
            name="Role"
            width={4}
                        // Triage du tableau
            sorted={column === 'Role' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'Role' })}
          >
            <Icon name="angle right" />
            Rôle
          </ListeTable.Column>
        </ListeTable.Header>

        {/* Contenu du tableau */}
        {(item) => (
          <ComponentsUsersRow item={item} />
        )}

      </ListeTable>
    </div>
  );
};

/** Props venant de ComponentsList */
ComponentsUsersList.propTypes = {
  // Informations d'un composant
  // eslint-disable-next-line
    users: PropTypes.arrayOf(PropTypes.object),
};

/** Types par défault des props */
ComponentsUsersList.defaultProps = {
  users: [],
};

export default ComponentsUsersList;
