/**
 * @brief Ligne de l'historique de consultation des clients
 */
import React from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';

import { ListeTable } from '@jvs-group/jvs-mairistem-liste';

const HistoryRow = (props) => {
  // Décomposition des props
  const {
    item,
  } = props;

  // Décomposition de item
  const {
    // L'identifiant du client
    identifiant,
    // Le nom du client
    name,
    // IdKNOTE du client
    idKnoteC,
  } = item;

  return (
    // Ligne du tableau
    <ListeTable.Row key={identifiant}>
      {/* Valeur pour "Nom du client" */}
      <ListeTable.Cell
        name="name"
        style={{ whiteSpace: 'pre-wrap' }}
      >
        {name}
      </ListeTable.Cell>
      {/* Valeur pour "Identifiant KNOTE" */}
      <ListeTable.Cell
        name="idKnote"
        disabled={_.isEmpty(idKnoteC)}
        verticalAlign={_.isEmpty(idKnoteC) ? 'middle' : 'top'}
      >
        {!_.isEmpty(idKnoteC)
          ? idKnoteC
          : (
            <div style={{
              transform: 'rotate(-20deg)',
              textAlign: 'center',
              letterSpacing: 1,
            }}
            >
              <b>
                Prospect
              </b>
            </div>
          )}
      </ListeTable.Cell>
    </ListeTable.Row>
  );
};

/** Props venant de HistoryList */
HistoryRow.propTypes = {
  // Informations du client
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
};

/** Types par défault des props */
HistoryRow.defaultProps = {
  item: {},
};

export default HistoryRow;
