/**
 * @brief Liste des rendez-vous d'un client
 */
import React from 'react';
import _ from 'lodash';

import {
  Icon,
} from '@jvs-group/jvs-mairistem-composants';

import PropTypes from 'prop-types';

import { ListeTable } from '@jvs-group/jvs-mairistem-liste';

import MeetingsRow from './MeetingsRow';

import { tableSort } from '../../Functions/Functions';

const MeetingsList = (props) => {
  // Décomposition des props
  const {
    meetings,
  } = props;

  /** Ensemble des états que peut prendre une cellule en-tête d'un tableau */
  const [state, dispatch] = React.useReducer(tableSort, {
    column: 'name',
    data: meetings,
    direction: 'ascending',
  });

  // Décomposition de state
  const { column, data, direction } = state;

  /** Changement de la valeur data avec les nouveaux contacts */
  React.useEffect(() => {
    dispatch({ type: 'CHANGE_CUSTOMER', data: meetings });
  }, [meetings]);

  return (
    // TODO: Modifier pour que ce ne soit pas en brut
    <div style={!_.isEmpty(data) ? { height: `${data.length * 60 + 47}px`, maxHeight: '800px' } : { height: '300px', maxHeight: '800px' }}>
      {/* Tableau des rendez-vous du client */}
      <ListeTable
        count={_.size(data)}
        items={data}
        color="blue"
        selection="click"
      >
        <ListeTable.Header>
          {/* Case "Sujet" */}
          <ListeTable.Column
            name="name"
            width={4}
            // Triage du tableau
            sorted={column === 'name' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'name' })}
          >
            <Icon name="file alternate" />
            Sujet
          </ListeTable.Column>
          {/* Case "Statut" */}
          <ListeTable.Column
            name="status"
            width={1}
            // Triage du tableau
            sorted={column === 'status' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'status' })}
            // Responsive
            only={[
              'm',
              'l',
              'xl',
            ]}
          >
            <Icon name="cogs" />
            Statut
          </ListeTable.Column>
          {/* Case "Type de visite" */}
          <ListeTable.Column
            name="typeVisiteC"
            width={2}
            // Triage du tableau
            sorted={column === 'typeVisiteC' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'typeVisiteC' })}
            // Responsive
            only={[
              'm',
              'l',
              'xl',
            ]}
          >
            <Icon name="target" />
            Type de visite
          </ListeTable.Column>
          {/* Case "Date de début" */}
          <ListeTable.Column
            name="dateStart"
            width={2}
            // Triage du tableau
            sorted={column === 'dateStart' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'dateStart' })}
            // Responsive
            only={[
              'l',
              'xl',
            ]}
          >
            <Icon name="calendar plus" />
            Date de début
          </ListeTable.Column>
          {/* Case "Date de fin" */}
          <ListeTable.Column
            name="dateEnd"
            width={2}
            // Triage du tableau
            sorted={column === 'dateEnd' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'dateEnd' })}
            // Responsive
            only={[
              'l',
              'xl',
            ]}
          >
            <Icon name="calendar minus" />
            Date de fin
          </ListeTable.Column>
          {/* Case "Assigné à" */}
          <ListeTable.Column
            name="assignedUserName"
            width={2}
            // Triage du tableau
            sorted={column === 'assignedUserName' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'assignedUserName' })}
            // Responsive
            only={[
              'l',
              'xl',
            ]}
          >
            <Icon name="user" />
            Assigné à
          </ListeTable.Column>
          {/* Case "Métier" */}
          <ListeTable.Column
            name="metierC"
            width={2}
            // Triage du tableau
            sorted={column === 'metierC' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'metierC' })}
            // Responsive
            only={[
              'xl',
            ]}
          >
            <Icon name="briefcase" />
            Métier
          </ListeTable.Column>
        </ListeTable.Header>
        {/* Contenu du tableau */}
        {(item) => (
          <MeetingsRow item={item} />
        )}
      </ListeTable>
    </div>
  );
};

/** Props venant de FicheCustomerConsumer */
MeetingsList.propTypes = {
  // Ensemble des rendez-vous du client
  // eslint-disable-next-line
  meetings: PropTypes.arrayOf(PropTypes.object),
};

/** Types par défault des props */
MeetingsList.defaultProps = {
  meetings: [],
};

export default MeetingsList;
