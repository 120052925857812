/**
 * @brief Conteneur des rendez-vous d'un client
 */
/* eslint-disable */
import React from 'react';

import { request } from '@jvs-group/jvs-mairistem-store';

import {
  Button,
} from '@jvs-group/jvs-mairistem-composants';

import PropTypes from 'prop-types';

const PanelSharePoint = (props) => {
  // Décomposition des props
  const {
    idKnote,
    customerName,
  } = props;
  /** Gestion de l'affichage du bouton selon le customerName */
  const button = React.useMemo(() => (
    <Button
      content="Consulter les dossiers du client"
      className={_.isUndefined(customerName) ? 'disabled' : ''}
      href={`https://jvsfr.sharepoint.com/sites/DOSSIERSCLIENTS/Shared%20Documents/General/${customerName}`}
      target="_blank"

      primary
      fluid
    />
  ), [customerName]);

  return (
    <>
      {button}
    </>
  );

};

/** Props venant de FicheCustomerConsumer */
PanelSharePoint.propTypes = {
  // IdKnote du client
  idKnote: PropTypes.string,
};

/** Types par défault des props */
PanelSharePoint.defaultProps = {
  idKnote: '',
};

export default PanelSharePoint;
