import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  Button, Grid, Icon, Modal,
} from '@jvs-group/jvs-mairistem-composants';
import { ListeTable } from '@jvs-group/jvs-mairistem-liste';

import { handleErrorData } from '../Functions/Functions';
import { formatDate } from '../Functions/FormattedDate';

const LeadsRow = (props) => {
  // Décomposition des props
  const {
    item,
  } = props;

  const {
    // L'identifiant du lead
    identifiant,
    fullName,
    metierC,
    logicielC,
    etapeC,
    dateEntered,
    assignedUserName,
  } = item;

  /** Etat de la Modal */
  const [open, setOpen] = React.useState(false);

  return (
    <ListeTable.Row key={identifiant}>
      <ListeTable.Cell
        name="fullName"
        style={handleErrorData(fullName) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {fullName === -1 ? 'Non renseigné' : fullName}
        {/* Apparition de la popup si la taille de la fenêtre est <= à 825px */}
        {window.innerWidth <= 825 ? (
          <Modal
            trigger={<Icon name="address card" style={{ float: 'right' }} />}
            dimmer="blurring"
            colorHeader="blue"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
          >
            {/* Nom du leads */}
            <Modal.Header>
              <Icon name="angle right" size="small" />
              {!handleErrorData(fullName) ? fullName : 'Non renseigné'}
              {/* {contactInactifC ? ' (Inactif)' : null} */}
            </Modal.Header>
            <Modal.Content>
              <Grid style={{ padding: '0 20px' }}>
                {/* Métier du leads */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="certificate" />
                  <p style={{ paddingRight: '3px' }}>
                    Métier :
                  </p>
                  <b>
                    {metierC === '-1' ? 'Non renseigné' : _.reduce(metierC, (a, b) => `${a}, ${b}`)}
                  </b>
                </Grid.Row>
                {/* Produits et services du leads */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="certificate" />
                  <p style={{ paddingRight: '3px' }}>
                    Produits et services :
                  </p>
                  <b>
                    {logicielC === '-1' ? 'Non renseigné' : _.reduce(logicielC, (a, b) => `${a}, ${b}`)}
                  </b>
                </Grid.Row>
                {/* Etape du leads */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="certificate" />
                  <p style={{ paddingRight: '3px' }}>
                    Etape :
                  </p>
                  <b>
                    {etapeC === -1 ? 'Non renseigné' : etapeC}
                  </b>
                </Grid.Row>
                {/* Date de création du leads */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="certificate" />
                  <p style={{ paddingRight: '3px' }}>
                    Date de création :
                  </p>
                  <b>
                    {dateEntered === -1 ? 'Non renseigné' : formatDate(dateEntered)}
                  </b>
                </Grid.Row>
                {/* Assigné à du leads */}
                <Grid.Row style={{ marginBottom: '-15px' }}>
                  <Icon name="certificate" />
                  <p style={{ paddingRight: '3px' }}>
                    Assigné à :
                  </p>
                  <b>
                    {assignedUserName === -1 ? 'Non renseigné' : assignedUserName}
                  </b>
                </Grid.Row>
              </Grid>
            </Modal.Content>
            {/* Bouton pour quitter la Modal */}
            <Modal.Actions>
              <Button
                positive
                content="Fermer"
                icon="close"
                size="tiny"
                onClick={() => setOpen(false)}
              />
            </Modal.Actions>
          </Modal>
        ) : null}
      </ListeTable.Cell>
      <ListeTable.Cell
        name="metierC"
        style={handleErrorData(metierC) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {metierC === -1 ? 'Non renseigné' : _.reduce(metierC, (a, b) => `${a}, ${b}`)}
      </ListeTable.Cell>
      <ListeTable.Cell
        name="logicielC"
        style={handleErrorData(logicielC) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {logicielC === -1 ? 'Non renseigné' : _.reduce(logicielC, (a, b) => `${a}, ${b}`)}
      </ListeTable.Cell>
      <ListeTable.Cell
        name="etapeC"
        style={handleErrorData(etapeC) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {etapeC === -1 ? 'Non renseigné' : etapeC}
      </ListeTable.Cell>
      <ListeTable.Cell
        name="dateEntered"
        style={handleErrorData(dateEntered) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {dateEntered === -1 ? 'Non renseigné' : formatDate(dateEntered)}
      </ListeTable.Cell>
      <ListeTable.Cell
        name="assignedUserName"
        style={handleErrorData(assignedUserName) ? { backgroundColor: '#e3e3e3' } : { whiteSpace: 'pre-wrap', fontWeight: 'bold' }}
      >
        {assignedUserName === -1 ? 'Non renseigné' : assignedUserName}
      </ListeTable.Cell>
    </ListeTable.Row>
  );
};

/** Props venant de EstimatesList */
LeadsRow.propTypes = {
  // Informations d'un devis
  // eslint-disable-next-line
  item: PropTypes.object,
};

/** Types par défault des props */
LeadsRow.defaultProps = {
  item: {},
};

export default LeadsRow;
